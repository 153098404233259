import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import { Button } from 'components';

import * as Styled from './styles';
import { DELETE_MODAL } from './enums';
import { useDeleteReport } from './hooks';

type ModalProps = {
  info: Record<string, any>;
  setOpenModal: (value: Record<string, any>) => void;
  reportDeleted: () => void;
};

export const DeleteReportModal = ({ info, setOpenModal, reportDeleted }: ModalProps): JSX.Element => {
  const hook = useDeleteReport(reportDeleted, setOpenModal);

  return (
    <Styled.WrapperStyled>
      <Styled.TitleStyled>{DELETE_MODAL.TITLE}</Styled.TitleStyled>
      <Styled.ConfirmationStyled>
        {`${DELETE_MODAL.CONFIRMATION} ${info.name} ${DELETE_MODAL.REPORT}`}
      </Styled.ConfirmationStyled>
      <Styled.ButtonWrapperStyled>
        <Button theme="secondary" onClick={hook.hookCloseModal}>
          {DELETE_MODAL.CANCEL}
        </Button>
        <Button loading={hook.hookLoading} onClick={() => hook.hookDeleteReport(info.id)}>
          <FontAwesomeIcon icon={faTrash} />
          &nbsp;
          {DELETE_MODAL.DELETE}
        </Button>
      </Styled.ButtonWrapperStyled>
    </Styled.WrapperStyled>
  );
};
