// Importing React and lodash
import React, { useEffect } from 'react';
import _ from 'lodash';
// Importing FontAwesome icons
import { faFlag as unchecked } from '@fortawesome/free-regular-svg-icons';
import { faFlag as checked, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Importing components
import { Button, Pagination, Table, Modal, Spacer } from 'components';
// Importing Custom Components
import { columns } from 'pages/Merchants/FintelCheck/ChangeMonitoring/ChangeMonitoringDetails/contracts/columns';
import ReviewStatus from 'pages/Merchants/FintelCheck/ChangeMonitoring/components/ReviewStatus';
import { FEEDBACK_MODAL, RULE_REVIEW_STATUSES } from 'pages/Merchants/FintelCheck/Components/RulesSummaryReport/enums';
import { useEvaluationRulesReport } from 'pages/Merchants/FintelCheck/EvaluationRulesReport/hooks/useEvaluationRulesReport';
import { camelCaseToSentenceCase } from 'pages/Merchants/FintelCheck/FintelCheckChangeHistory/contracts';
import { useMonitoringRulesReport } from 'pages/Merchants/FintelCheck/MonitoringRulesReport/hooks/useMonitoringRulesReport';
// Importing styles
import { colors } from 'styles/theme';

// Importing Styled Components
import * as Styled from './styles';
// Importing enums
import {
  MODAL_SECTIONS,
  MODAL_TITLE,
  RULE_STATUS_SETTING_TITLE,
  RULE_STATUS_TITLE,
  CHECK_DATE_TITLE,
  FEEDBACK_TITLE,
  RULE_TEXT_TITLE,
  REPORT_RULE_STATUS,
  RULE_STATUS,
  RULE_TYPES,
} from './enums';
// Importing types
import { TextPhraseType } from './graphql/getMonitoringRuleStatusData';

type MonitoringDetailsModalProps = {
  hook: ReturnType<typeof useMonitoringRulesReport | typeof useEvaluationRulesReport>;
};

const MonitoringDetailsModal = ({ hook }: MonitoringDetailsModalProps): JSX.Element => {
  useEffect(() => {
    if (hook.isDetailedViewModalOpen) {
      hook.getReviewStatusData();
      hook.getTextPhrasesData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hook.isDetailedViewModalOpen]);

  const ruleStatusColor = (ruleStatus: string): JSX.Element => {
    if (!ruleStatus || typeof ruleStatus !== 'string') {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }

    const formattedRuleStatus = _.startCase(ruleStatus.toLowerCase());

    switch (ruleStatus) {
      case RULE_STATUS.Pass:
        return <Styled.CheckResultsTextValueGreen>{formattedRuleStatus}</Styled.CheckResultsTextValueGreen>;
      case RULE_STATUS.Fail:
        return <Styled.CheckResultsTextValueRed>{formattedRuleStatus}</Styled.CheckResultsTextValueRed>;
      default:
        return <Styled.CheckResultsTextValueBold>{formattedRuleStatus}</Styled.CheckResultsTextValueBold>;
    }
  };

  return (
    <>
      <Styled.DetailedViewModal
        isOpen={hook.isDetailedViewModalOpen}
        closeModalFunction={() =>
          hook.isDetailedViewModalOpen && !hook.isFeedbackModalOpen && hook.closeDetailedViewModal()
        }
      >
        <Styled.DetailedViewContent>
          <Styled.DetailedViewHeader>
            <>
              <Styled.FlexColumn>
                <Styled.DetailedViewTitle>
                  {hook.detailedViewModalInfo?.merchant
                    ? hook.detailedViewModalInfo?.merchant?.split('-')[1]
                    : MODAL_TITLE}
                </Styled.DetailedViewTitle>
                <Spacer size="medium" direction="vertical" />
                <Styled.DetailedViewSectionSubTitle>
                  You are viewing this Check’s latest Rule Status and Review Status History
                </Styled.DetailedViewSectionSubTitle>
                <Spacer size="medium" direction="vertical" />
              </Styled.FlexColumn>
              <Styled.DetailedViewCloseIcon icon={faTimes} onClick={() => hook.closeDetailedViewModal()} />
            </>
          </Styled.DetailedViewHeader>

          <Spacer size="small" direction="vertical" />

          <Styled.FlexRow>
            <Styled.RuleDetailsLabel>Rule Applied:</Styled.RuleDetailsLabel>
            <Styled.RuleDetailsLabel>{hook.detailedViewModalInfo?.ruleName}</Styled.RuleDetailsLabel>
          </Styled.FlexRow>

          <Spacer size="small" direction="vertical" />

          <Styled.FlexRow style={{ alignItems: 'baseline' }}>
            <Styled.RuleDetailsLabel style={{ width: '6.5rem' }}>URL Checked: </Styled.RuleDetailsLabel>
            <Styled.DetailedViewUrlButton href={hook.detailedViewModalInfo?.urlSearch} target="_blank" rel="noreferrer">
              {hook.detailedViewModalInfo?.urlSearch}
            </Styled.DetailedViewUrlButton>
          </Styled.FlexRow>

          <Spacer size="small" direction="vertical" />

          {/* Check Results Report */}
          <Styled.ContentWrapper>
            <Styled.DetailedViewSectionTitle>{MODAL_SECTIONS.CHECK_RESULTS}</Styled.DetailedViewSectionTitle>
            <Spacer size="small" direction="vertical" />

            <Styled.CheckResultsWrapper>
              <Styled.FlexRow>
                <Styled.CheckResultsTextLabel>{REPORT_RULE_STATUS}</Styled.CheckResultsTextLabel>
                {hook.detailedViewModalInfo?.ruleStatus && ruleStatusColor(hook.detailedViewModalInfo?.ruleStatus)}
              </Styled.FlexRow>
              <Spacer size="small" direction="vertical" />

              <Styled.FlexRow>
                <Styled.CheckResultsTextLabel>{CHECK_DATE_TITLE}</Styled.CheckResultsTextLabel>
                <Styled.CheckResultsTextValueBold>
                  {hook.detailedViewModalInfo?.createDatetime}
                </Styled.CheckResultsTextValueBold>
              </Styled.FlexRow>
            </Styled.CheckResultsWrapper>
          </Styled.ContentWrapper>

          <Spacer size="small" direction="vertical" />

          {/* Rule Eligiblity */}
          {(hook.detailedViewModalInfo?.ruleType !== RULE_TYPES.MONITORING || !hook.detailedViewModalInfo.ruleType) && (
            <Styled.ContentWrapper>
              <Styled.DetailedViewSectionTitle>{MODAL_SECTIONS.RULE_ELIGIBILITY}</Styled.DetailedViewSectionTitle>
              <Styled.CheckResultsWrapper>
                <Spacer size="medium" direction="vertical" />
                <Styled.DetailedViewSectionSubTitle>
                  These are the minimum requirements for a URL to be eligible for the rule to be applied.
                </Styled.DetailedViewSectionSubTitle>
                <Spacer size="medium" direction="vertical" />
                {/* First Eligibility Requirement */}
                <Styled.FlexRow>
                  <Styled.TextPhrasesExtraLargeColumn>
                    <Styled.FlexRow>
                      <Styled.CheckResultsTextLabel>
                        Eligibility requirement 1 (Brand Name):
                      </Styled.CheckResultsTextLabel>
                      {hook.detailedViewModalInfo?.brandName}
                    </Styled.FlexRow>
                  </Styled.TextPhrasesExtraLargeColumn>
                  <Styled.TextPhrasesMediumColumn>
                    <Styled.CheckResultsTextValueBold>
                      {hook.detailedViewModalInfo?.eligibility?.find((el) => el.type.toLowerCase() === 'merchant name')
                        ?.status
                        ? 'Found'
                        : 'Not Found'}
                    </Styled.CheckResultsTextValueBold>
                  </Styled.TextPhrasesMediumColumn>
                </Styled.FlexRow>
                {/* Eligibility Requirements */}
                {hook.detailedViewModalInfo?.eligibility?.map(
                  (item: { type: string; value: string; status: boolean }, index) => {
                    if (item.type.toLowerCase() === 'merchant name') return null;
                    return (
                      <div key={`${item.type}-${item.value}`}>
                        <Spacer size="medium" direction="vertical" />

                        {/* Product Rule Eligibility Requirement */}
                        {hook.detailedViewModalInfo?.ruleType === RULE_TYPES.EVALUATION__PRODUCT ? (
                          <Styled.FlexRow>
                            <Styled.TextPhrasesExtraLargeColumn>
                              <Styled.FlexRow>
                                <Styled.CheckResultsTextLabel>
                                  {`Eligibility requirement ${index + 1} (${_.startCase(item.type.toLowerCase())}):`}
                                </Styled.CheckResultsTextLabel>
                                {item.value}
                              </Styled.FlexRow>
                            </Styled.TextPhrasesExtraLargeColumn>

                            <Styled.TextPhrasesMediumColumn>
                              <Styled.CheckResultsTextValueBold>
                                {item.status ? 'Found' : 'Not Found'}
                              </Styled.CheckResultsTextValueBold>
                            </Styled.TextPhrasesMediumColumn>
                          </Styled.FlexRow>
                        ) : (
                          <>
                            {/* Text Rule Eligibility Requirement */}
                            {index === 1 && (
                              <>
                                <Styled.DetailedViewSectionSubTitle>
                                  At least one of the following phrases must appear on the page.
                                </Styled.DetailedViewSectionSubTitle>
                                <Spacer size="medium" direction="vertical" />
                              </>
                            )}

                            <Styled.FlexRow style={{ alignItems: 'baseline' }}>
                              <Styled.TextPhrasesExtraLargeColumn>
                                <Styled.CheckResultsTextValue>* {item.value}</Styled.CheckResultsTextValue>
                              </Styled.TextPhrasesExtraLargeColumn>
                              <Styled.TextPhrasesMediumColumn>
                                <Styled.CheckResultsTextValueBold>
                                  {item.status ? 'Found' : 'Not Found'}
                                </Styled.CheckResultsTextValueBold>
                              </Styled.TextPhrasesMediumColumn>
                            </Styled.FlexRow>
                          </>
                        )}
                      </div>
                    );
                  }
                )}
              </Styled.CheckResultsWrapper>
            </Styled.ContentWrapper>
          )}

          <Spacer size="small" direction="vertical" />

          {/* Rule Criteria */}
          {hook.detailedViewModalInfo?.ruleStatus &&
          ![RULE_STATUS.NotApplicable, RULE_STATUS.Incomplete].includes(hook.detailedViewModalInfo?.ruleStatus) ? (
            <Styled.ContentWrapper>
              <Styled.DetailedViewSectionTitle>{MODAL_SECTIONS.RULE_CRITERIA}</Styled.DetailedViewSectionTitle>
              <Spacer size="small" direction="vertical" />

              {/* Product Rule Criteria */}
              {hook.detailedViewModalInfo?.ruleType === RULE_TYPES.EVALUATION__PRODUCT ? (
                <Styled.TextPhrasesWrapper>
                  <Styled.TextPhrasesColumnWrapper>
                    <Styled.TextPhrasesSmallColumn>
                      <Styled.TextPhrasesColumnLabel>{FEEDBACK_TITLE}</Styled.TextPhrasesColumnLabel>
                    </Styled.TextPhrasesSmallColumn>
                    <Styled.TextPhrasesMediumColumnFlex>
                      <Styled.TextPhrasesColumnLabel>Status</Styled.TextPhrasesColumnLabel>
                    </Styled.TextPhrasesMediumColumnFlex>
                    <Styled.TextPhrasesLargeColumnFlex>
                      <Styled.TextPhrasesColumnLabel>Rule Requirement</Styled.TextPhrasesColumnLabel>
                    </Styled.TextPhrasesLargeColumnFlex>
                    <Styled.TextPhrasesMediumColumnFlex>
                      <Styled.TextPhrasesColumnLabel>Required</Styled.TextPhrasesColumnLabel>
                    </Styled.TextPhrasesMediumColumnFlex>
                    <Styled.TextPhrasesExtraLargeColumnFlex>
                      <Styled.TextPhrasesColumnLabel>Expected Match</Styled.TextPhrasesColumnLabel>
                    </Styled.TextPhrasesExtraLargeColumnFlex>
                  </Styled.TextPhrasesColumnWrapper>
                  {/* Text Phrases Data */}
                  {hook.textPhrasesData &&
                    hook.textPhrasesData.map((data: TextPhraseType) => (
                      <Styled.TextPhrasesValuesWrapper key={`${data.fieldName}-${data.textPhrase}`}>
                        <Styled.FeedbackButtonWrapper>
                          <Button onClick={() => hook.handleOnFeedbackModalOpen(data)} type="reset" theme="text-only">
                            <FontAwesomeIcon icon={data.hasFeedback ? checked : unchecked} color={colors.color1} />
                          </Button>
                        </Styled.FeedbackButtonWrapper>
                        <Styled.TextPhrasesMediumColumnFlex>
                          <Styled.TextPhrasesValues>{data.status}</Styled.TextPhrasesValues>
                        </Styled.TextPhrasesMediumColumnFlex>
                        <Styled.TextPhrasesLargeColumnFlex>
                          <Styled.TextPhrasesValues>{camelCaseToSentenceCase(data.fieldName)}</Styled.TextPhrasesValues>
                        </Styled.TextPhrasesLargeColumnFlex>
                        <Styled.TextPhrasesMediumColumnFlex>
                          <Styled.TextPhrasesValues>{data.required === true ? 'Yes' : 'No'}</Styled.TextPhrasesValues>
                        </Styled.TextPhrasesMediumColumnFlex>
                        <Styled.TextPhrasesExtraLargeColumnFlex>
                          <Styled.TextPhrasesValues>{data.textPhrase}</Styled.TextPhrasesValues>
                        </Styled.TextPhrasesExtraLargeColumnFlex>
                      </Styled.TextPhrasesValuesWrapper>
                    ))}
                </Styled.TextPhrasesWrapper>
              ) : (
                <>
                  {/* Rule Status Setting */}
                  {hook.ruleSettingsData && (
                    <Styled.CheckResultsWrapper>
                      <Styled.FlexRow>
                        <Styled.CheckResultsTextLabel>{RULE_STATUS_SETTING_TITLE}</Styled.CheckResultsTextLabel>
                        <Styled.CheckResultsTextValueBold>{hook.ruleSettingsData}</Styled.CheckResultsTextValueBold>
                      </Styled.FlexRow>
                      <Spacer size="medium" direction="vertical" />
                    </Styled.CheckResultsWrapper>
                  )}
                  {/* Text Rule Criteria */}
                  <Styled.TextPhrasesWrapper>
                    <Styled.TextPhrasesColumnWrapper>
                      <Styled.TextPhrasesSmallColumn>
                        <Styled.TextPhrasesColumnLabel>{FEEDBACK_TITLE}</Styled.TextPhrasesColumnLabel>
                      </Styled.TextPhrasesSmallColumn>
                      <Styled.TextPhrasesMediumColumn>
                        <Styled.TextPhrasesColumnLabel>{RULE_STATUS_TITLE}</Styled.TextPhrasesColumnLabel>
                      </Styled.TextPhrasesMediumColumn>
                      <Styled.TextPhrasesLargeColumn>
                        <Styled.TextPhrasesColumnLabel>{RULE_TEXT_TITLE}</Styled.TextPhrasesColumnLabel>
                      </Styled.TextPhrasesLargeColumn>
                    </Styled.TextPhrasesColumnWrapper>
                    {/* Text Phrases Data */}
                    {hook.textPhrasesData &&
                      hook.textPhrasesData.map((data: TextPhraseType) => (
                        <Styled.TextPhrasesValuesWrapper key={`${data.fieldName}-${data.textPhrase}`}>
                          <Styled.FeedbackButtonWrapper>
                            <Button onClick={() => hook.handleOnFeedbackModalOpen(data)} type="reset" theme="text-only">
                              <FontAwesomeIcon icon={data.hasFeedback ? checked : unchecked} color={colors.color1} />
                            </Button>
                          </Styled.FeedbackButtonWrapper>
                          <Styled.TextPhrasesMediumColumn>
                            <Styled.TextPhrasesValues>{data.status}</Styled.TextPhrasesValues>
                          </Styled.TextPhrasesMediumColumn>
                          <Styled.TextPhrasesLargeColumn style={{ flex: '10 0 0' }}>
                            <Styled.TextPhrasesValues>{data.textPhrase}</Styled.TextPhrasesValues>
                          </Styled.TextPhrasesLargeColumn>
                        </Styled.TextPhrasesValuesWrapper>
                      ))}
                  </Styled.TextPhrasesWrapper>
                </>
              )}
            </Styled.ContentWrapper>
          ) : null}

          <Spacer size="small" direction="vertical" />

          {/* Review Status Report */}
          <Styled.ContentWrapper>
            {hook.reviewStatusTableData && hook.reviewStatusTableData.length > 0 && (
              <>
                <Styled.DetailedViewSectionTitle>{MODAL_SECTIONS.REVIEW_STATUS}</Styled.DetailedViewSectionTitle>
                <Spacer size="small" direction="vertical" />

                <Table
                  columns={columns()}
                  data={hook.reviewStatusTableData || []}
                  isLoading={hook.getMonitoringReviewStatusDataLoading}
                  loadWithData={hook.reviewStatusTableData ? hook.reviewStatusTableData.length > 0 : false}
                />
                <Pagination
                  currentPage={hook.currentReviewStatusPage}
                  total={hook.totalReviewStatusPages}
                  onPageChange={hook.handleOnChangeCurrentPage}
                />
              </>
            )}

            <Spacer size="small" direction="vertical" />

            <ReviewStatus
              selectedReviewStatus={hook.selectedReviewStatus}
              setReviewStatusNote={hook.setReviewStatusNote}
              reviewStatusNote={hook.reviewStatusNote}
              shouldDisableSetReviewStatusButton={hook.shouldDisableSetReviewStatusButton}
              setReviewStatusLoading={hook.setReviewStatusLoading}
              setSelectedReviewStatus={hook.setSelectedReviewStatus}
              dropdownOptions={RULE_REVIEW_STATUSES.filter(
                (status) => status.value !== 'All Open Statuses' && status.value !== 'First Check'
              )}
              onClickAction={() =>
                hook.handleOnSetReviewStatus(hook.selectedReviewStatus?.value || '', hook.detailedViewModalInfo?.id)
              }
            />
          </Styled.ContentWrapper>
        </Styled.DetailedViewContent>
      </Styled.DetailedViewModal>

      <Modal
        isOpen={hook.isFeedbackModalOpen}
        closeModalFunction={() => {
          hook.resetFeedbackModalValues();
        }}
      >
        <Styled.FeedbackModalContent>
          <Styled.FeedbackModalTitle>{FEEDBACK_MODAL.TITLE}</Styled.FeedbackModalTitle>

          <Styled.FeedbackModalText>{FEEDBACK_MODAL.DESCRIPTION}</Styled.FeedbackModalText>

          <Styled.FeedbackModalRadioWrapper>
            <Styled.FeedbackModalRadio
              name={FEEDBACK_MODAL.YES}
              value={FEEDBACK_MODAL.YES}
              label={FEEDBACK_MODAL.YES}
              checked={hook.isFeedbackRuleCorrect === true}
              onChange={() => hook.setIsFeedbackRuleCorrect(true)}
            />
            <Styled.FeedbackModalRadio
              name={FEEDBACK_MODAL.NO}
              value={FEEDBACK_MODAL.NO}
              label={FEEDBACK_MODAL.NO}
              checked={hook.isFeedbackRuleCorrect === false}
              onChange={() => hook.setIsFeedbackRuleCorrect(false)}
            />
          </Styled.FeedbackModalRadioWrapper>
          <Styled.SubmitFeedbackButtonWrapper>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                hook.resetFeedbackModalValues();
              }}
              theme="secondary"
              width="5rem"
              disabled={hook.setRuleFeedbackLoading}
            >
              {FEEDBACK_MODAL.CANCEL}
            </Button>
            <Button
              onClick={() => hook.handleFeedbackSubmit()}
              theme="primary"
              width="5rem"
              disabled={hook.isFeedbackRuleCorrect === undefined}
              loading={hook.setRuleFeedbackLoading}
            >
              {FEEDBACK_MODAL.SUBMIT}
            </Button>
          </Styled.SubmitFeedbackButtonWrapper>
        </Styled.FeedbackModalContent>
      </Modal>
    </>
  );
};

export default MonitoringDetailsModal;
