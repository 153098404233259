import React from 'react';
// General App Imports
import { SnapshotFrom } from 'xstate';

import { Spacer } from 'components';
// Custom Hooks
import { useAddMonitoringRule } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddMonitoringRule/hooks';
// Custom Components
import { machine } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/machine';

import AddCustomTextComponent from '../../AddCustomTextComponent';
import RuleStatusSettingsComponent from '../../RuleStatusSettingsComponent';
// Custom Styled Components
import { RULE_MANAGER } from '../../../enums';
import { MONITORING_RULE_CRITERIA } from '../enums';
import * as Styled from '../../styles';

type MonitoringRuleCriteriaProps = {
  stateMachine: SnapshotFrom<typeof machine>;
  hook: ReturnType<typeof useAddMonitoringRule>;
};

export const MonitoringRuleCriteria = ({ stateMachine, hook }: MonitoringRuleCriteriaProps): JSX.Element => {
  return (
    <Styled.PageWrapper>
      <Spacer direction="vertical" size="medium" />

      <Styled.RuleStatusWrapper>
        <Styled.RuleStatusLabel>
          {RULE_MANAGER.EDIT_RULES.RULE_STATUS}
          <Styled.RequiredStyled>*</Styled.RequiredStyled>
        </Styled.RuleStatusLabel>
        <Styled.RuleStatusDescription>
          {RULE_MANAGER.EDIT_RULES.RULE_STATUS_SETTINGS_DESCRIPTION}
        </Styled.RuleStatusDescription>
        <RuleStatusSettingsComponent
          ruleStatusSettings={stateMachine.context.ruleStatusSettings}
          setRuleStatusSettings={hook.setRuleStatusSettings}
          errors={hook.stepTwoErrors}
        />
      </Styled.RuleStatusWrapper>

      <Spacer direction="vertical" size="medium" />

      <AddCustomTextComponent
        textList={hook.customTextList}
        inputLabelText={MONITORING_RULE_CRITERIA.CUSTOM_TEXT.LABEL}
        inputTooltipText={MONITORING_RULE_CRITERIA.CUSTOM_TEXT.TOOLTIP}
        inputPlaceholderText="Text value to find"
        errors={hook.stepTwoErrors}
        onBlur={hook.onBlurCheck}
        onChange={hook.updateCustomTextValue}
        removeTextHandler={hook.removeCustomTextById}
        addTextHandler={hook.addNewCustomText}
        isAddButtonEnabled={hook.canAddMoreCustomText}
        addButtonText={MONITORING_RULE_CRITERIA.ADD_CUSTOM_TEXT}
        customInputWidth="60vw"
        customInfoText="Enter one or more Custom Text Values (words or phrases) for the rule to evaluate on a page(s)."
      />
    </Styled.PageWrapper>
  );
};
