import React, { useState } from 'react';

import { AppWrapper, ErrorBanner, PageTitle, Tabs, Toggle } from 'components';
import { useModal } from 'hooks';
import { DefaultPropsType } from 'types';
import { path } from 'utils';
import FintelCheckUrlsList from 'pages/Merchants/FintelCheck/FintelCheckPublisherSearch/Tabs/Publishers';
import FintelCheckTrackingUrlsList from 'pages/Merchants/FintelCheck/FintelCheckPublisherSearch/Tabs/TrackingUrls';
import { usePublisherSearch } from 'pages/Merchants/FintelCheck/FintelCheckPublisherSearch/Tabs/Publishers/hooks';
import { PUBLISHER_SEARCH } from 'pages/Merchants/FintelCheck/FintelCheckPublisherSearch/enums';
import { ConfirmationModal } from 'components/Modal/ConfirmationModal';

import * as Styled from './styles';

type FintelCheckPublisherSearchProps = DefaultPropsType;

const FintelCheckPublisherSearch = ({ permissionsCodeList = [] }: FintelCheckPublisherSearchProps): JSX.Element => {
  const [, setModal] = useModal(false);
  const { hookIsReadOnlyList, ...hook } = usePublisherSearch(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;
  const [activeTab, setActiveTab] = useState('Tracking Urls');

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <ErrorBanner isOpen={hook.monitoringErrors.length > 0} message={hook.monitoringErrors} />

      <Styled.HeaderStyled>
        <PageTitle isLoading={hook.hookLoading} loadingText="Loading Publishers">
          {path.fintelCheckPublisherSearch.name}
        </PageTitle>

        <Styled.HeaderButtonAreaStyled>
          {activeTab === 'Tracking Urls' && (
            <>
              <Styled.ToggleLabelStyled>Enable Tracking Urls</Styled.ToggleLabelStyled>
              <Toggle
                checked={hook.trackingUrlMonitoringToggle}
                onChange={() => hook.setIsConfirmationModalOpen(true)}
                disabled={isReadOnly || hook.hookLoading}
              />
            </>
          )}
          {activeTab === 'Publishers' && (
            <Styled.ButtonStyled onClick={setModal} disabled={hook.hookDisableButton || isReadOnly}>
              {PUBLISHER_SEARCH.BUTTONS.UPDATE}
            </Styled.ButtonStyled>
          )}
        </Styled.HeaderButtonAreaStyled>
      </Styled.HeaderStyled>

      <Tabs
        tabNames={['Tracking Urls', 'Publishers']}
        elements={[
          <FintelCheckTrackingUrlsList isReadOnly={isReadOnly} key="Tracking Urls" />,
          <FintelCheckUrlsList key="Publishers" />,
        ]}
        onTabChange={(tabName) => setActiveTab(tabName)}
      />

      <ConfirmationModal
        isOpen={hook.isConfirmationModalOpen}
        onConfirmText="Save"
        title="Save Changes"
        desc={`Changes to settings will immediately impact the
          monitoring & reporting on URLs. Are you sure you want to save changes?`}
        onConfirm={() => hook.setTrackingUrlMonitoringToggle(!hook.trackingUrlMonitoringToggle)}
        onClose={() => hook.setIsConfirmationModalOpen(false)}
        onCloseText="Cancel"
        isLoading={hook.hookLoading}
      />
    </AppWrapper>
  );
};

export default FintelCheckPublisherSearch;
