import React from 'react';

import { ChangeMonitoringType } from 'pages/Merchants/FintelCheck/ChangeMonitoring/graphql/queries';
import { dateFormatter } from 'utils';
import { Checkbox } from 'components';

import { CellWrapper } from '../components';
import * as Styled from '../components/CellWrapper/styles';
import { VIEW_CHANGES_BUTTON, NO_CHANGES_BUTTON } from '../enum';

export const Header = (checked: boolean, onChange: (state: boolean) => void, isReadOnly: boolean) => (
  <Checkbox checked={checked} onChange={onChange} disabled={isReadOnly} />
);

export const columns = (
  viewChangesHandler?: (url: string, row: object) => void,
  onSelectCheckbox?: (checked: boolean, selectedItem: ChangeMonitoringType, isCheckAll: boolean) => void
): TableColumn[] => [
  {
    dataField: 'checked',
    text: '',
    width: '60px',
    isCheckBox: true,
    headerFormatter: (val, headerCheckBoxHandler: (state: boolean) => void) =>
      Header(val, headerCheckBoxHandler, false),
    formatter: (val, row) => (
      <CellWrapper>
        <Checkbox
          key={val}
          checked={val}
          onChange={(checked) => onSelectCheckbox && onSelectCheckbox(checked, row, false)}
        />
      </CellWrapper>
    ),
  },
  {
    dataField: 'statusChanges',
    text: 'Review Status',
    formatter: (cell: any) => <CellWrapper>{cell || 'New Finding'}</CellWrapper>,
    width: '10%',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
  },
  {
    dataField: 'reviewDate',
    text: 'Review Date',
    formatter: (cell: any) => <CellWrapper>{dateFormatter(new Date(cell))}</CellWrapper>,
    width: '10%',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
  },
  {
    dataField: 'reviewer',
    text: 'Reviewer',
    formatter: (cell: any) => <CellWrapper>{cell}</CellWrapper>,
    width: '10%',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
  },
  {
    dataField: 'runDate',
    text: 'Change Date',
    formatter: (cell: any) => <CellWrapper>{dateFormatter(new Date(cell))}</CellWrapper>,
    width: '12%',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
  },
  {
    dataField: 'url',
    text: 'Page URL',
    formatter: (cell: any) => (
      <CellWrapper>
        <Styled.UrlLinkStyled target="_blank" href={cell}>
          {cell}
        </Styled.UrlLinkStyled>
      </CellWrapper>
    ),
    width: '18%',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
  },
  {
    dataField: 'pageDifferences',
    text: 'Page Differences',
    formatter: (cell: any, row: any) => (
      <CellWrapper>
        <Styled.InternalUrlLinkStyled
          target="_blank"
          onClick={() => viewChangesHandler && viewChangesHandler(cell, row)}
        >
          {cell === 'diff' ? VIEW_CHANGES_BUTTON : NO_CHANGES_BUTTON}
        </Styled.InternalUrlLinkStyled>
      </CellWrapper>
    ),
    width: '12%',
  },
  {
    dataField: 'changeSummary',
    text: 'Change Summary',
    formatter: (cell: any) => (
      <CellWrapper>{cell ? cell.slice(0, 255).concat('...') : 'No summary available'}</CellWrapper>
    ),
    width: '48%',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
  },
  {
    dataField: 'crawlStatus',
    text: 'Crawl Status',
    formatter: (cell: any) => <CellWrapper>{cell}</CellWrapper>,
    width: '10%',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
  },
];
