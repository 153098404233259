import _ from 'lodash';
import React from 'react';
import { AnyEventObject, SnapshotFrom } from 'xstate';

import { Button, ErrorBanner } from 'components';
import { ConfirmationModal } from 'components/Modal/ConfirmationModal';
import { useEditEvaluationRule } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/EditEvaluationRule/hooks/useEditEvaluationRule';
import { MonitoringSummary } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/MonitoringSummary.component';
import { ProductSummary } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/ProductSummary.component';
import { TextSummary } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/TextSummary.component';
import { RULE_MANAGER } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/enums';
import { machine } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/machine';
import * as Styled from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/styles';
import { dateFormatter } from 'utils';

type EditEvaluationRuleProps = {
  stateMachine: SnapshotFrom<typeof machine>;
  send: (event: AnyEventObject) => void;
  refreshRuleManager: () => void;
  handleDelete: ({ id }: { id: number }) => void;
  isReadOnly: boolean;
};

export const EditEvaluationRule = ({
  stateMachine,
  send,
  refreshRuleManager,
  handleDelete,
  isReadOnly,
}: EditEvaluationRuleProps): JSX.Element => {
  const hook = useEditEvaluationRule(stateMachine, send, refreshRuleManager);
  return (
    <Styled.PageWrapper>
      <ErrorBanner isOpen={!!hook.errorMessage} message={hook.errorMessage} />

      <Styled.PageTitleStyled>{stateMachine.context.titleContent}</Styled.PageTitleStyled>

      <Styled.RuleDetailsWrapper>
        <Styled.EditInputStyled
          label="Rule Name"
          value={stateMachine.context.ruleName || ''}
          type="text"
          required
          disabled
        />
        <Styled.EditInputStyled
          label="Created on"
          value={stateMachine.context.startDate ? dateFormatter(stateMachine.context.startDate) : undefined}
          theme="secondary"
          type="text"
          readonly
          disabled
        />
        <Styled.EditSelectStyled
          label="Rule Group"
          options={hook.checkRuleGroupsList}
          selected={_.find(hook.checkRuleGroupsList, { value: hook.stateMachine.context.ruleGroup })}
          isDisabled={isReadOnly}
          onChange={hook.changeSelectedRuleGroup}
          tooltip="Select a group that you would like this rule to be added to. This is optional."
        />
        <Styled.EditSelectStyled
          label="Rule Status"
          options={hook.statusOptions}
          isDisabled={isReadOnly}
          selected={_.find(hook.statusOptions, { value: hook.stateMachine.context.status })}
          onChange={hook.changeSelectedRuleStatus}
        />
      </Styled.RuleDetailsWrapper>

      {stateMachine.matches({ EditRule: 'EmptyRule' }) && <div />}
      {stateMachine.matches({ EditRule: 'MonitoringRule' }) && <MonitoringSummary stateMachine={stateMachine} />}
      {stateMachine.matches({ EditRule: 'ProductRule' }) && <ProductSummary stateMachine={stateMachine} />}
      {stateMachine.matches({ EditRule: 'TextRule' }) && <TextSummary stateMachine={stateMachine} />}

      <Styled.RuleButtonsWrapper>
        <Button theme="secondary" width="140px" onClick={() => send({ type: 'EditRule.cancel' })}>
          Cancel
        </Button>
        {!stateMachine.matches({ AddEvaluationRule: 'NameGroupType' }) && (
          <Button
            theme="tertiary"
            width="140px"
            disabled={isReadOnly}
            onClick={() => {
              if (stateMachine.context.id) {
                handleDelete({ id: stateMachine.context.id });
              }
            }}
          >
            Delete Rule
          </Button>
        )}
        <Button
          theme="primary"
          disabled={isReadOnly}
          onClick={() => hook.setIsConfirmationModalOpen(true)}
          width="140px"
        >
          {RULE_MANAGER.EDIT_RULES.SAVE_BUTTON}
        </Button>
      </Styled.RuleButtonsWrapper>

      <ConfirmationModal
        isOpen={hook.isConfirmationModalOpen}
        onClose={() => hook.setIsConfirmationModalOpen(false)}
        onConfirm={() => hook.updateEvaluationRule()}
        isLoading={hook.editRuleByIdLoading}
        onCloseText={RULE_MANAGER.EDIT_RULES.CANCEL_BUTTON}
        onConfirmText={RULE_MANAGER.EDIT_RULES.SAVE_BUTTON}
        title={RULE_MANAGER.EDIT_RULES.SAVE_BUTTON}
        desc={RULE_MANAGER.EDIT_RULES.RULE_UPDATED_WARNING}
        width="500px"
      />
    </Styled.PageWrapper>
  );
};
