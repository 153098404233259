import _ from 'lodash';
import { setup, assign, and } from 'xstate';

import { CustomTextType } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddCustomTextComponent';
import {
  BrandNameEnum,
  EligibilityEnum,
} from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddEvaluationRule/enums';
import { RuleStatusSettingsType } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/RuleStatusSettingsComponent/enums';
import { RULE_TYPES } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/enums';
import {
  SelectedProductCriteriaType,
  AddEvaluationRuleProductType,
  humanizeProductFeed,
} from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/types';

const initialContext = {
  id: null,
  applicableProducts: '',
  ruleName: '',
  ruleGroup: '',
  ruleType: '',
  ruleNameUnique: false,
  brandNameType: BrandNameEnum.existing,
  brandName: '',
  product: undefined,
  productCategory: '',
  productEligibility: '',
  productEligibilityValue: '',
  productName: '',
  productCriteria: [],
  selectedProduct: undefined,
  textEligibility: [],
  textCriteria: [],
  hasTextErrors: false,
  ruleStatusSettings: {
    fieldsRequired: null,
    fieldState: null,
    ruleState: null,
  },
  startDate: new Date(),
  status: 'Active',
  titleContent: '',
};

export const machineSetup = setup({
  types: {
    context: {} as {
      id: number | null;
      applicableProducts: string;
      brandName: string;
      brandNameType: string | null;
      hasTextErrors: boolean;
      product: AddEvaluationRuleProductType | undefined; // TODO: Type this correctly.  When adding, it's AddEvaluationRuleProductType, when editing, it's a Product.
      productCategory: string;
      productCriteria: SelectedProductCriteriaType[];
      productEligibility: string;
      productEligibilityValue: string;
      productName: string;
      ruleGroup: string;
      ruleName: string;
      ruleNameUnique: boolean;
      ruleStatusSettings: RuleStatusSettingsType;
      ruleType: string;
      selectedProduct: SelectOption | undefined;
      startDate: Date;
      status: string;
      textCriteria: CustomTextType[];
      textEligibility: CustomTextType[];
      titleContent: string;
    },
  },
  guards: {
    validRuleName: ({ context }) => context.ruleNameUnique && context.ruleName.length > 0,
    validRuleType: ({ context }, params: { ruleType: string }) => context.ruleType === params.ruleType,
    validProductSelection: ({ context }) => !!context.productCategory && typeof context.product !== 'undefined',
    validBrandName: ({ context }) => context.brandNameType === BrandNameEnum.existing || context.brandName.length > 0,
    validTextEligibility: ({ context }) =>
      context.textEligibility.length > 0
        ? context.textEligibility.every((text) => text.value && text.value.length > 0)
        : true,
    validRuleStatusSettings: ({ context }) =>
      (context.ruleStatusSettings.fieldsRequired?.length ?? 0) > 0 &&
      (context.ruleStatusSettings.fieldState?.length ?? 0) > 0 &&
      (context.ruleStatusSettings.ruleState?.length ?? 0) > 0,
    validTextCriteria: ({ context }) => {
      return (
        context.textCriteria.length > 0 && context.textCriteria.every((text) => text.value && text.value.length > 0)
      );
    },
    validText: ({ context }) => !context.hasTextErrors,
    validProductEligibility: ({ context }) =>
      context.productEligibility.length > 0 && context.productEligibilityValue.length > 0,
    validProductCriteria: ({ context }) => {
      return (
        context.productCriteria.length > 0 && context.productCriteria.every((criteria) => criteria.value.length > 0)
      );
    },
  },
});
export const machine = machineSetup.createMachine({
  id: 'RuleManager',
  initial: 'RuleList',
  context: initialContext,
  states: {
    RuleList: {
      entry: [assign(initialContext)], // Every time we enter this state, reset the context
      on: {
        // This self-transition is used to load a rule into the context
        ':loadRule': {
          actions: [
            assign({
              id: ({ event }) => event.rule.id,
              product: ({ event }) => event.rule.product, // This is a product object
              applicableProducts: ({ event }) => event.rule.applicableProducts,
              ruleName: ({ event }) => event.rule.ruleName,
              ruleGroup: ({ event }) => event.rule.ruleGroup,
              ruleType: ({ event }) => event.rule.ruleType,
              brandNameType: ({ event }) =>
                event.rule.brandName === null ? BrandNameEnum.existing : BrandNameEnum.customText,
              brandName: ({ event }) => event.rule.brandName,
              productCategory: ({ event }) => event.rule.productCategory,
              productEligibility: ({ event }) => {
                if (event.rule.productId && event.rule.eligibility && event.rule.eligibility.length > 0) {
                  return event.rule.eligibility[0].type;
                }
                return '';
              },
              productEligibilityValue: ({ event }) => {
                if (event.rule.productId && event.rule.eligibility && event.rule.eligibility.length > 0) {
                  return event.rule.eligibility[0].value;
                }
                return '';
              },
              productName: ({ event }) => event.rule.productName,
              productCriteria: ({ event }) => {
                if (event.rule.ruleType === RULE_TYPES.EVAL_PRODUCT) {
                  return event.rule.productFeed.map(
                    (item: { productFeed: string; productFeedData: string; required: boolean }) => ({
                      label: item.productFeed,
                      name:
                        item.productFeed === 'legalReferenceItems'
                          ? `Legal Reference: ${item.productFeedData}`
                          : humanizeProductFeed(item.productFeed),
                      value:
                        item.productFeed === 'legalReferenceItems'
                          ? `${item.productFeed}:${item.productFeedData}`
                          : item.productFeed,
                      required: item.required,
                    })
                  );
                }
                return [];
              },
              textEligibility: ({ event }) => {
                if (event.rule.ruleType === RULE_TYPES.EVAL_TEXT) {
                  return event.rule.eligibility.map((item: { type: string; value: string }, index: number) => ({
                    id: index,
                    value: item.value,
                  }));
                }
                return [];
              },
              textCriteria: ({ event }) => {
                if (event.rule.ruleType === RULE_TYPES.EVAL_TEXT || event.rule.ruleType === RULE_TYPES.MONITORING) {
                  return event.rule.fieldChecks.map(
                    (item: { fieldName: string; expectedValue: string }, index: number) => ({
                      id: index,
                      value: item.expectedValue,
                    })
                  );
                }
                return [];
              },
              ruleStatusSettings: ({ event }) => event.rule.ruleStatusSettings ?? initialContext.ruleStatusSettings,
              startDate: ({ event }) => new Date(event.rule.startDate),
              status: ({ event }) => event.rule.status,
            }),
          ],
        },
        EditRule: [
          {
            guard: { type: 'validRuleType', params: { ruleType: RULE_TYPES.MONITORING } },
            target: '#RuleManager.EditRule.MonitoringRule',
          },
          {
            guard: { type: 'validRuleType', params: { ruleType: RULE_TYPES.EVAL_PRODUCT } },
            target: '#RuleManager.EditRule.ProductRule',
          },
          {
            guard: { type: 'validRuleType', params: { ruleType: RULE_TYPES.EVAL_TEXT } },
            target: '#RuleManager.EditRule.TextRule',
          },
        ],
        AddEvaluationRule: '#RuleManager.AddEvaluationRule',
        AddMonitoringRule: '#RuleManager.AddMonitoringRule',
      },
    },
    EditRule: {
      initial: 'EmptyRule',
      exit: [assign(initialContext)], // Initialize context on exit
      states: {
        EmptyRule: {},
        MonitoringRule: {
          entry: [assign({ titleContent: 'Edit Monitoring Rule' })],
          on: {
            ':brandName': {
              actions: assign({
                brandName: ({ event }) => event.brandName,
              }),
            },
            ':ruleGroup': {
              actions: assign({
                ruleGroup: ({ event }) => event.ruleGroup,
              }),
            },
            ':status': {
              actions: assign({
                status: ({ event }) => event.status,
              }),
            },
            'EditRule.delete': { target: '#RuleManager.RuleList' },
            'EditRule.save': { target: '#RuleManager.RuleList' },
            'EditRule.cancel': { target: '#RuleManager.RuleList' },
          },
        },
        ProductRule: {
          entry: [assign({ titleContent: 'Edit Evaluation Product Rule' })],
          on: {
            ':brandName': {
              actions: assign({
                brandName: ({ event }) => event.brandName,
              }),
            },
            ':ruleGroup': {
              actions: assign({
                ruleGroup: ({ event }) => event.ruleGroup,
              }),
            },
            ':status': {
              actions: assign({
                status: ({ event }) => event.status,
              }),
            },
            'EditRule.delete': { target: '#RuleManager.RuleList' },
            'EditRule.save': { target: '#RuleManager.RuleList' },
            'EditRule.cancel': { target: '#RuleManager.RuleList' },
          },
        },
        TextRule: {
          entry: [assign({ titleContent: 'Edit Evaluation Text Rule' })],
          on: {
            ':brandName': {
              actions: assign({
                brandName: ({ event }) => event.brandName,
              }),
            },
            ':ruleGroup': {
              actions: assign({
                ruleGroup: ({ event }) => event.ruleGroup,
              }),
            },
            ':status': {
              actions: assign({
                status: ({ event }) => event.status,
              }),
            },
            'EditRule.delete': { target: '#RuleManager.RuleList' },
            'EditRule.save': { target: '#RuleManager.RuleList' },
            'EditRule.cancel': { target: '#RuleManager.RuleList' },
          },
        },
      },
    },
    AddEvaluationRule: {
      initial: 'NameGroupType',
      entry: [assign(initialContext)], // Ensure context is initialized on entry
      states: {
        NameGroupType: {
          entry: [assign({ titleContent: 'Add Evaluation Rule' })],
          on: {
            ':brandName': {
              actions: assign({
                brandName: ({ event }) => event.brandName,
              }),
            },
            ':brandNameType': {
              actions: assign({
                brandNameType: ({ event }) => event.brandNameType,
              }),
            },
            ':ruleName': {
              actions: assign({
                ruleName: ({ event }) => event.ruleName,
                ruleNameUnique: false,
              }),
            },
            ':ruleNameUnique': {
              actions: assign({
                ruleNameUnique: ({ event }) => event.ruleNameUnique,
              }),
            },
            ':ruleType': {
              actions: assign({
                ruleType: ({ event }) => event.ruleType,
              }),
            },
            ':ruleGroup': {
              actions: assign({
                ruleGroup: ({ event }) => event.ruleGroup,
              }),
            },
            'AddEvaluationRule.next': [
              {
                guard: and([
                  { type: 'validRuleName' },
                  { type: 'validRuleType', params: { ruleType: RULE_TYPES.EVAL_PRODUCT } },
                ]),
                target: 'ProductSelection',
              },
              {
                guard: and([
                  { type: 'validRuleName' },
                  { type: 'validRuleType', params: { ruleType: RULE_TYPES.EVAL_TEXT } },
                ]),
                target: 'BrandNameText',
              },
            ],
            'AddEvaluationRule.cancel': { target: '#RuleManager.RuleList' },
          },
        },
        BrandNameProduct: {
          entry: [assign({ titleContent: 'Eligibility Requirement 1: Brand Name' })],
          on: {
            ':brandName': {
              actions: assign({
                brandName: ({ event }) => event.brandName,
              }),
            },
            ':brandNameType': {
              actions: assign({
                brandNameType: ({ event }) => event.brandNameType,
              }),
            },
            'AddEvaluationRule.previous': { target: 'ProductSelection' },
            'AddEvaluationRule.next': { guard: { type: 'validBrandName' }, target: 'ProductEligibility' },
            'AddEvaluationRule.cancel': { target: '#RuleManager.RuleList' },
          },
        },
        BrandNameText: {
          entry: [assign({ titleContent: 'Eligibility Requirement 1: Brand Name' })],
          on: {
            ':brandName': {
              actions: assign({
                brandName: ({ event }) => event.brandName,
              }),
            },
            ':brandNameType': {
              actions: assign({
                brandNameType: ({ event }) => event.brandNameType,
              }),
            },
            'AddEvaluationRule.previous': { target: 'NameGroupType' },
            'AddEvaluationRule.next': { guard: { type: 'validBrandName' }, target: 'TextEligibility' },
            'AddEvaluationRule.cancel': { target: '#RuleManager.RuleList' },
          },
        },
        ProductSelection: {
          entry: [assign({ titleContent: 'Add Evaluation Rule: Product' })],
          on: {
            ':productCategory': {
              actions: assign({
                applicableProducts: '',
                product: undefined,
                productCategory: ({ event }) => event.productCategory,
                productName: '',
                selectedProduct: undefined,
              }),
            },
            ':product': {
              actions: assign({
                applicableProducts: ({ event }) => event.selectedProduct?.label,
                product: ({ event }) => event.product,
                productCriteria: [],
                productEligibility: EligibilityEnum.PRODUCT_NAME,
                productEligibilityValue: ({ event }) => event.product?.name,
                productName: ({ event }) => event.product?.name,
                selectedProduct: ({ event }) => event.selectedProduct,
              }),
            },
            'AddEvaluationRule.next': { guard: { type: 'validProductSelection' }, target: 'BrandNameProduct' },
            'AddEvaluationRule.previous': { target: 'NameGroupType' },
            'AddEvaluationRule.cancel': { target: '#RuleManager.RuleList' },
          },
        },
        ProductEligibility: {
          entry: [assign({ titleContent: 'Select Eligibility Requirement - 2 of 2' })],
          on: {
            ':productEligibility': {
              actions: assign({
                productEligibility: ({ event }) => event.productEligibility,
              }),
            },
            ':productEligibilityValue': {
              actions: assign({
                productEligibilityValue: ({ event }) => event.productEligibilityValue,
              }),
            },
            'AddEvaluationRule.previous': { target: 'BrandNameProduct' },
            'AddEvaluationRule.next': { guard: { type: 'validProductEligibility' }, target: 'ProductCriteria' },
            'AddEvaluationRule.cancel': { target: '#RuleManager.RuleList' },
          },
        },
        ProductCriteria: {
          entry: [assign({ titleContent: 'Select Value(s) for Rule Evaluation' })],
          on: {
            ':productCriteria': {
              actions: assign({
                productCriteria: ({ event }) => event.productCriteria,
              }),
            },
            'AddEvaluationRule.previous': { target: 'ProductEligibility' },
            'AddEvaluationRule.next': { guard: { type: 'validProductCriteria' }, target: 'ProductSummary' },
            'AddEvaluationRule.cancel': { target: '#RuleManager.RuleList' },
          },
        },
        ProductSummary: {
          entry: [assign({ titleContent: 'New Rule Summary' })],
          on: {
            'AddEvaluationRule.previous': { target: 'ProductCriteria' },
            'AddEvaluationRule.next': { target: '#RuleManager.RuleList' },
            'AddEvaluationRule.cancel': { target: '#RuleManager.RuleList' },
          },
        },
        TextEligibility: {
          entry: [assign({ titleContent: 'Select Eligibility Requirement - 2 of 2 (Optional)' })],
          on: {
            ':textEligibility': {
              actions: assign({
                textEligibility: ({ event }) => event.textEligibility,
              }),
            },
            ':textCriteria': {
              actions: assign({
                textCriteria: ({ event }) => event.textCriteria,
              }),
            },
            ':hasTextErrors': {
              actions: assign({
                hasTextErrors: ({ event }) => event.hasTextErrors,
              }),
            },
            'AddEvaluationRule.previous': { target: 'BrandNameText' },
            'AddEvaluationRule.next': {
              guard: and([{ type: 'validTextEligibility' }, { type: 'validText' }]),
              target: 'TextCriteria',
            },
            'AddEvaluationRule.cancel': { target: '#RuleManager.RuleList' },
          },
        },
        TextCriteria: {
          entry: [assign({ titleContent: 'Enter Custom Text for Evaluation' })],
          on: {
            ':ruleStatusSettings': {
              actions: assign({
                ruleStatusSettings: ({ event }) => event.ruleStatusSettings,
              }),
            },
            ':textCriteria': {
              actions: assign({
                textCriteria: ({ event }) => event.textCriteria,
              }),
            },
            ':hasTextErrors': {
              actions: assign({
                hasTextErrors: ({ event }) => event.hasTextErrors,
              }),
            },
            'AddEvaluationRule.previous': { target: 'TextEligibility' },
            'AddEvaluationRule.next': {
              guard: and([{ type: 'validRuleStatusSettings' }, { type: 'validTextCriteria' }, { type: 'validText' }]),
              target: 'TextSummary',
            },
            'AddEvaluationRule.cancel': { target: '#RuleManager.RuleList' },
          },
        },
        TextSummary: {
          entry: [assign({ titleContent: 'New Rule Summary' })],
          on: {
            'AddEvaluationRule.previous': { target: 'TextCriteria' },
            'AddEvaluationRule.next': { target: '#RuleManager.RuleList' },
            'AddEvaluationRule.cancel': { target: '#RuleManager.RuleList' },
          },
        },
      },
    },
    AddMonitoringRule: {
      initial: 'NameRuleGroup',
      entry: [assign(initialContext)], // Ensure context is initialized on entry
      states: {
        NameRuleGroup: {
          entry: [assign({ titleContent: 'Add Monitoring Rule' })],
          on: {
            ':ruleName': {
              actions: assign({
                ruleName: ({ event }) => event.ruleName,
                ruleNameUnique: false,
              }),
            },
            ':ruleNameUnique': {
              actions: assign({
                ruleNameUnique: ({ event }) => event.ruleNameUnique,
              }),
            },
            ':ruleGroup': {
              actions: assign({
                ruleGroup: ({ event }) => event.ruleGroup,
              }),
            },
            'AddMonitoringRule.next': {
              guard: { type: 'validRuleName' },
              target: 'RuleCriteria',
            },
            'AddMonitoringRule.cancel': { target: '#RuleManager.RuleList' },
          },
        },
        RuleCriteria: {
          entry: [assign({ titleContent: 'Enter Custom Text for Monitoring' })],
          on: {
            ':ruleStatusSettings': {
              actions: assign({
                ruleStatusSettings: ({ event }) => event.ruleStatusSettings,
              }),
            },
            ':textCriteria': {
              actions: assign({
                textCriteria: ({ event }) => event.textCriteria,
              }),
            },
            ':hasTextErrors': {
              actions: assign({
                hasTextErrors: ({ event }) => event.hasTextErrors,
              }),
            },
            'AddMonitoringRule.previous': { target: 'NameRuleGroup' },
            'AddMonitoringRule.next': [
              {
                guard: and([{ type: 'validTextCriteria' }, { type: 'validRuleStatusSettings' }]),
                target: 'RuleSummary',
              },
            ],
            'AddMonitoringRule.cancel': { target: '#RuleManager.RuleList' },
          },
        },
        RuleSummary: {
          entry: [assign({ titleContent: 'New Rule Summary' })],
          on: {
            'AddMonitoringRule.previous': { target: 'RuleCriteria' },
            'AddMonitoringRule.next': { target: '#RuleManager.RuleList' },
            'AddMonitoringRule.cancel': { target: '#RuleManager.RuleList' },
          },
        },
      },
    },
  },
});
