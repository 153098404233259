import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { Button, InputText } from 'components';

import { IndexedObject } from '../AddMonitoringRule/types';

import { INFO } from './enums';
import * as Styled from './styles';

export type CustomTextType = {
  id: number;
  value: string | undefined;
};

type AddCustomTextComponentProps = {
  textList: CustomTextType[];
  inputLabelText?: string;
  inputTooltipText?: string;
  inputPlaceholderText?: string;
  errors: IndexedObject;
  onBlur: () => void;
  onChange: (textIndex: number, value: React.ChangeEvent<HTMLInputElement>) => void;
  removeTextHandler: (textIndex: number) => void;
  addTextHandler: () => void;
  isAddButtonEnabled: boolean;
  addButtonText: string;
  customInputWidth?: string;
  customInfoText?: string;
};

const AddCustomTextComponent = ({
  textList,
  inputLabelText,
  inputTooltipText,
  inputPlaceholderText = '',
  errors,
  onBlur,
  onChange,
  removeTextHandler,
  addTextHandler,
  isAddButtonEnabled,
  addButtonText,
  customInputWidth,
  customInfoText,
}: AddCustomTextComponentProps): JSX.Element => {
  return (
    <>
      <Styled.CustomTextWraper>
        <Styled.InfoWrapper>
          <Styled.InfoRow>{customInfoText ?? INFO.TEXT}</Styled.InfoRow>
        </Styled.InfoWrapper>
        {textList.map((customText) => (
          <Styled.CustomTextRowStyled
            key={customText.id}
            style={customInputWidth ? { gridTemplateColumns: `${customInputWidth} 1fr 40px` } : undefined}
          >
            <InputText
              required
              type="text"
              name={`customTextData${customText.id}`}
              label={inputLabelText}
              tooltip={inputTooltipText}
              placeholder={inputPlaceholderText}
              value={customText.value ?? ''}
              onChange={(value) => onChange(customText.id, value)}
              error={errors[customText.id]}
              onBlur={onBlur}
              maxLength={500}
            />
            <Styled.DeleteRequirementButton
              onClick={() => removeTextHandler(customText.id)}
              style={errors[customText.id] ? { alignSelf: 'center', marginTop: '0.35rem' } : { alignSelf: 'flex-end' }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Styled.DeleteRequirementButton>
          </Styled.CustomTextRowStyled>
        ))}
      </Styled.CustomTextWraper>
      <Styled.AddRuleButtonWrapper>
        <Button theme="tertiary" onClick={addTextHandler} width="auto" disabled={!isAddButtonEnabled}>
          <FontAwesomeIcon icon={faPlus} style={{ paddingRight: '8px' }} />
          {addButtonText}
        </Button>
      </Styled.AddRuleButtonWrapper>
      <Styled.InfoRowBold>{INFO.WARNING}</Styled.InfoRowBold>
    </>
  );
};

export default AddCustomTextComponent;
