import { useState } from 'react';
import { useMutation } from '@apollo/client';

import { useUpload, useUserInfo } from '../../../../../../../hooks';
import { UPLOAD_PRODUCT_CSV } from '../graphql/mutations/uploadProduct';
import { csvGenerator } from '../../../../../../../utils';
import { ERROR_MESSAGE } from '../../../../../../Transactions/AddTransactions/Tabs/BulkUpload/utils';
import { CSV, INVALID_CSV, VALID_CSV } from '../enums';

export const useProductUpload = (changeTabs: any) => {
  const userHook = useUserInfo();
  const upload = useUpload();

  const [loading, setLoading] = useState(false);

  const [file, setFile] = useState<File | undefined>();

  const [errorMessage, setErrorMessage] = useState<string>('');

  const [checked, setChecked] = useState(false);

  // queries & mutations
  const [uploadBulkImport, { loading: uploadBulkImportLoading }] = useMutation(UPLOAD_PRODUCT_CSV);

  const createFileUploadHandler = async () => {
    setErrorMessage('');
    if (!file) {
      setErrorMessage(ERROR_MESSAGE.MISSING_FILE);
    }
    if (!file) return;

    setLoading(true);
    const response = await upload.hookUploadDocument(file, userHook.hookWhoAmI.companyId?.toString());
    if (!response || !response.key) {
      setErrorMessage(`Error: ${response.message || 'Unable to upload file'}`);
      setLoading(false);
      return;
    }
    const { data } = await uploadBulkImport({
      variables: {
        input: {
          programId: userHook.hookWhoAmI.programId?.toString(),
          key: response.key,
        },
      },
      onError(error) {
        setErrorMessage(error.message);
        setLoading(false);
      },
    });
    if (data && data.uploadProductsFile !== undefined) {
      if (
        !data.uploadProductsFile ||
        !data.uploadProductsFile.products ||
        data.uploadProductsFile.products.length === 0
      ) {
        setErrorMessage(INVALID_CSV);
      } else {
        changeTabs(0);
      }
    }
    setLoading(false);
  };

  const handleChecked = () => {
    setChecked(!checked);
  };

  const onUploadHandler = (value: any) => {
    if (value instanceof File) {
      setFile(value);
    } else {
      setErrorMessage(VALID_CSV);
    }
  };

  const CSVHandler = async () => {
    csvGenerator(CSV, 'template.csv');
  };

  const onCancel = () => {
    setFile(undefined);
    setErrorMessage('');
    setLoading(false);
    setChecked(false);
  };

  return {
    hookLoading: uploadBulkImportLoading || loading,
    hookErrorMessage: errorMessage,
    hookFile: file,
    hookSubmit: createFileUploadHandler,
    hookCSVDownload: CSVHandler,
    hookUploadHandler: onUploadHandler,
    hookChecked: checked,
    hookHandleChecked: handleChecked,
    hookHandleCancel: onCancel,
  };
};
