import { faSearch } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

import { Button, Modal, Pagination, RecordsDropdown, Table } from 'components';
import { useModal } from 'hooks';
import { RECORDS_PER_PAGE_OPTIONS_WITH_150 } from 'utils';
import { usePublisherSearch } from 'pages/Merchants/FintelCheck/FintelCheckPublisherSearch/Tabs/Publishers/hooks';
import { columns } from 'pages/Merchants/FintelCheck/FintelCheckPublisherSearch/Tabs/Publishers/contracts';

import { PUBLISHER_SEARCH } from '../../enums';
import UpdateCustomListModal from '../../Modal';
import * as Styled from '../../styles';

type FintelCheckUrlsListProps = {
  permissionsCodeList?: string[];
};

const FintelCheckUrlsList = ({ permissionsCodeList = [] }: FintelCheckUrlsListProps): JSX.Element => {
  const [modal, setModal] = useModal(false);
  const { hookIsReadOnlyList, ...hook } = usePublisherSearch(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;
  return (
    <>
      <Styled.FiltersStyled>
        <Styled.InputTextStyled
          placeholder="Search by Company Name, ID, URL"
          type="text"
          value={hook.hookSearch}
          onChange={hook.hookSetSearch}
          faIcon={faSearch}
        />

        <Styled.ExtendedFiltersStyled>
          <Styled.FilterByLabelStyled>{PUBLISHER_SEARCH.FILTER}</Styled.FilterByLabelStyled>

          <Styled.SelectStyled
            name="category"
            placeholder="By Category"
            selected={hook.hookCategory}
            onChange={hook.hookSetCategory}
            options={hook.hookCategoryOptions}
            isLoading={hook.hookLoading}
          />
          <Styled.SelectStyled
            name="location"
            placeholder="By location"
            selected={hook.hookLocation}
            onChange={hook.hookSetLocation}
            options={hook.hookLocationOptions}
            isLoading={hook.hookLoading}
          />

          <Button theme="text-only" onClick={hook.hookResetFilters}>
            {PUBLISHER_SEARCH.BUTTONS.CLEAR}
          </Button>
        </Styled.ExtendedFiltersStyled>
      </Styled.FiltersStyled>
      <Styled.RecordsDropdownWrapper>
        <RecordsDropdown
          selected={hook.hookRecords}
          onChange={hook.hookSetRecords}
          options={RECORDS_PER_PAGE_OPTIONS_WITH_150}
          currentPage={hook.hookCurrentPage}
          totalRecords={hook.hookTotalRecords}
        />
      </Styled.RecordsDropdownWrapper>

      <Table
        columns={columns(isReadOnly)}
        data={hook.hookData}
        setData={hook.hookSetData}
        headerCheckBox={hook.hookHeader}
        handleHeaderCheckBox={hook.hookSetHeader}
        setModalInfo={hook.hookUpdateList}
        isLoading={hook.hookLoading}
        sortColumn={hook.hookSortColumn}
        onSort={hook.hookHandleSort}
        onRowClick={hook.hookOnRowClick}
      />
      <Pagination total={hook.hookTotalPages} currentPage={hook.hookCurrentPage} onPageChange={hook.hookOnPageChange} />

      <Modal isOpen={modal}>
        <UpdateCustomListModal
          setIsHeader={hook.hookSetIsHeader}
          publisherList={hook.hookPublisherList}
          removePublisher={hook.hookRemovePublisher}
          updateList={hook.hookUpdateCustomList}
          closeFn={setModal}
        />
      </Modal>
    </>
  );
};

export default FintelCheckUrlsList;
