import styled from 'styled-components';

import { colors, fonts } from 'styles/theme';

export const HeaderStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.3rem;
`;

export const ToggleLabelStyled = styled.p`
  ${fonts.lato.H16}
  color: ${colors.color1};
`;

export const HeaderToggleWrapper = styled.div`
  display: flex;
  align-items: center;
`;
