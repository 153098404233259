import { useLazyQuery } from '@apollo/client';
import { useState, useEffect } from 'react';

import { useProgramsApi } from 'api/hooks/useProgramsApi';
import { IProgramTrackingSettings } from 'api/types';
import { findQueryParamsDuplicates, interpolate } from 'helpers';

import { useValidation } from '../../../../../../utils/validation';
import { GET_COUNTRIES } from '../../../../../../hooks/graphql/queries';
import { AGE_OPTIONS } from '../enums';
import { URL_STATUSES, ERROR_TYPES, optionsMaker, subCategories } from '../../../../../../utils';
import { useUserInfo } from '../../../../../../hooks';
import en from '../locales/en.json';

type ImageFile = {
  file?: File;
  alt: string;
  height: number;
  width: number;
};

export const useProductInformation = (
  product: any,
  setIsUpdateDisabled: any,
  updateProductLoading: any,
  geoStatesHandler: (input: SelectOption[]) => void,
  geoStates: any
) => {
  const { hookWhoAmI } = useUserInfo();
  const { targetDemographics } = product;
  const vali = useValidation();
  const [productName, setProductName] = useState(product.name);
  const [minimumIncome, setIncome] = useState(targetDemographics.minimumIncome);
  const [minimumhousehold, setMinimumhousehold] = useState(targetDemographics.minimumHouseHoldIncome);
  const [minimumCredit, setMinimumCredit] = useState(targetDemographics.minimiumCreditScore);
  const [productUrl, setProductUrl] = useState(product.productUrl);
  const [id, setProductId] = useState(product.customizedProductId);
  const [description, setDescription] = useState(product.description);
  const [landingPage, setLandingPage] = useState(product.landingPage);
  const [thirdUrl, setThirdUrl] = useState(product.thirdPartyUrl);
  const [status, setStatus] = useState<SelectOption>({ label: product.status, value: product.status });
  const [gender, setGender] = useState({ label: targetDemographics.gender, value: targetDemographics.gender });
  const [visibility, setVisibility] = useState<SelectOption>(
    targetDemographics?.commissionVisibility !== undefined && targetDemographics?.commissionVisibility !== null
      ? {
          label: targetDemographics.commissionVisibility ? 'Member Only' : 'Hide',
          value: targetDemographics.commissionVisibility,
        }
      : { label: '', value: false }
  );
  const [category, setCategory] = useState({ label: product.productCategory, value: product.productCategory });
  const [displayedSubCategories, setDisplayedCategories] = useState(
    subCategories(product.productCategory).map((cat: any) => ({
      name: cat,
      checked: product.subCategories?.includes(cat),
    }))
  );

  const [targetCountry, setTargetCountry] = useState<SelectOption>({
    label: targetDemographics.geographicalCountry,
    value: targetDemographics.geographicalCountry,
  });
  const [countryOptions, setCountryOptions] = useState<SelectOption[]>([]);
  const [regionOptions, setRegionOptions] = useState<SelectOption[]>([]);
  const [allSubs, setAllSubs] = useState<any>();

  const [ages, setAges] = useState(
    AGE_OPTIONS.map((age: any) => ({
      label: age,
      checked: targetDemographics?.age?.includes(age) || false,
    }))
  );

  const [imageFile, setImageFile] = useState<ImageFile>({
    file: undefined,
    alt: '',
    height: 0,
    width: 0,
  });
  const [productImageUrl, setProductImageUrl] = useState<string>(product.productImageUrl);

  const [firstRender, setFirstRender] = useState(true);

  const [secondRender, setSecondRender] = useState(false);
  const [newProductErrors, setNewProductErrors] = useState<Record<string, string>>({});
  const [productUrlError, setProductUrlError] = useState('');
  const [landingUrlError, setLandingUrlError] = useState('');
  const [thirdPartyUrlError, setThirdPartyUrlError] = useState('');
  const [productUrlStatus, setProductUrlStatus] = useState('');
  const [landingUrlStatus, setLandingUrlStatus] = useState('');
  const [thirdPartyUrlStatus, setThirdPartyUrlStatus] = useState('');
  const [getCountries] = useLazyQuery(GET_COUNTRIES);

  const [programTrackingSettings, setProgramTrackingSettings] = useState<IProgramTrackingSettings | null>(null);

  const { fetchTrackingSettingsByProgramId } = useProgramsApi();

  const values = {
    productName,
    status: status?.label || '',
    productId: id,
    description,
    productCategory: category?.label || '',
    targetGender: gender?.label || '',
    targetCountry: targetCountry?.label || '',
  };

  const fields = {
    productName: ERROR_TYPES.NOT_EMPTY,
    productId: ERROR_TYPES.NOT_EMPTY,
    status: ERROR_TYPES.SELECTION_REQUIRED,
    description: ERROR_TYPES.NOT_EMPTY,
    productCategory: ERROR_TYPES.SELECTION_REQUIRED,
    targetGender: ERROR_TYPES.SELECTION_REQUIRED,
  };

  const isParamsDuplicatesLandingPage = (): boolean => {
    try {
      const queryParamDuplicates = findQueryParamsDuplicates(
        landingPage,
        programTrackingSettings?.urlQueryString || ''
      );

      if (queryParamDuplicates.length > 0) {
        setLandingUrlError(
          interpolate(en.errors.validation.landingPageUrl.queryParamDuplicates, {
            duplicates: queryParamDuplicates.join(', '),
          })
        );
        setLandingUrlStatus(URL_STATUSES.INVALID_WEBSITE.STATUS);
        return true;
      }

      return false;
    } catch (err) {
      console.error(`Error in parsing the URL: ${landingPage}`);
      return false;
    }
  };

  const validateWebsite = () => {
    vali.validateUrlStatus(productUrl, setProductUrlStatus);
    vali.renderUrlCheck(productUrlStatus, setProductUrlError);
    if (landingPage !== '' && landingPage !== null) {
      if (isParamsDuplicatesLandingPage()) return;

      vali.validateUrlStatus(landingPage, setLandingUrlStatus);
      vali.renderUrlCheck(landingUrlStatus, setLandingUrlError, true);
    }
    if (thirdUrl !== '' && thirdUrl !== null) {
      vali.validateUrlStatus(thirdUrl, setThirdPartyUrlStatus);
      vali.renderUrlCheck(thirdPartyUrlStatus, setThirdPartyUrlError, true);
    }
  };

  const onUploadHandler = (file: any) => {
    setIsUpdateDisabled(false);
    if (file instanceof File) {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const image: ImageFile = {
          file,
          alt: 'PRODUCT THUMBNAIL',
          height: img.naturalHeight,
          width: img.naturalWidth,
        };
        setImageFile(image);
        setProductImageUrl(img.src);
      };
    }
  };

  const handleValidation = () => {
    let noErrors = true;
    if (secondRender) {
      validateWebsite();
      if (
        productUrlStatus === URL_STATUSES.INVALID_WEBSITE.STATUS ||
        productUrlStatus === URL_STATUSES.PENDING_WEBSITE.STATUS
      ) {
        noErrors = false;
      }
    }

    if (landingPage !== '' && landingPage !== null) {
      if (
        landingUrlStatus === URL_STATUSES.INVALID_WEBSITE.STATUS ||
        landingUrlStatus === URL_STATUSES.PENDING_WEBSITE.STATUS
      ) {
        noErrors = false;
      }
    }

    if (thirdUrl !== '' && thirdUrl !== null) {
      if (
        thirdPartyUrlStatus === URL_STATUSES.INVALID_WEBSITE.STATUS ||
        thirdPartyUrlStatus === URL_STATUSES.PENDING_WEBSITE.STATUS
      ) {
        noErrors = false;
      }
    }
    return vali.validateAll(values, fields, setNewProductErrors, secondRender) && noErrors;
  };

  const handleAges = (label: any) => {
    setIsUpdateDisabled(false);
    const copy = [...ages];
    const toChange = copy.find((obj) => obj.label === label);
    if (toChange) toChange.checked = !toChange.checked;
    setAges(copy);
  };

  const handleStatus = (value: any) => {
    setIsUpdateDisabled(false);
    setStatus(value);
  };

  const handleCheckSubCategory = (name: any) => {
    setIsUpdateDisabled(false);
    const copy = [...displayedSubCategories];
    const toChange = copy.find((obj) => obj.name === name);
    if (toChange) toChange.checked = !toChange.checked;
    setDisplayedCategories(copy);
  };

  const handleCategory = (value: any) => {
    setIsUpdateDisabled(false);
    setDisplayedCategories(
      subCategories(value.label).map((cat: any) => ({
        name: cat,
        checked: false,
      }))
    );
    setCategory(value);
  };

  const handleGender = (value: any) => {
    setIsUpdateDisabled(false);
    setGender(value);
  };

  const handleCommissions = (value: any) => {
    setIsUpdateDisabled(false);

    setVisibility(value);
  };

  const handleGetCountries = async () => {
    const { data } = await getCountries();
    if (data.getCountries?.countries !== undefined) {
      setCountryOptions(optionsMaker(data.getCountries?.countries.map((c: any) => c.name)));
      const { countries } = data.getCountries;
      setAllSubs(countries);
      if (targetCountry.value) {
        setRegionOptions(
          optionsMaker(
            countries.find((obj: any) => obj.name === targetCountry.label).subdivisions.map((obj: any) => obj.name)
          )
        );
      }
    }
  };

  const handleCountry = (value: SelectOption) => {
    setIsUpdateDisabled(false);
    setTargetCountry(value);
    geoStatesHandler([]);
    setRegionOptions(
      optionsMaker(allSubs.find((obj: any) => obj.name === value.label).subdivisions.map((obj: any) => obj.name))
    );
  };

  const handleProductName = (e: any) => {
    setProductName(e.target.value);
    setIsUpdateDisabled(false);
  };

  const handleProductUrl = (e: any) => {
    setIsUpdateDisabled(false);
    setProductUrl(e.target.value);
  };

  const handleId = (e: any) => {
    setIsUpdateDisabled(false);
    setProductId(e.target.value);
  };

  const handleDescription = (e: any) => {
    setIsUpdateDisabled(false);
    setDescription(e.target.value);
  };

  const handleLandingPage = (e: any) => {
    setIsUpdateDisabled(false);

    setLandingPage(e.target.value);
  };

  const handleThirdParty = (e: any) => {
    setIsUpdateDisabled(false);
    setThirdUrl(e.target.value);
  };

  const handleIncome = (e: any) => {
    setIsUpdateDisabled(false);
    setIncome(e.target.value);
  };

  const handlehousehold = (e: any) => {
    setIsUpdateDisabled(false);
    setMinimumhousehold(e.target.value);
  };

  const handleCredit = (e: any) => {
    setIsUpdateDisabled(false);
    setMinimumCredit(e.target.value);
  };

  const handleUpdateProduct = () => {
    setIsUpdateDisabled(false);
    setSecondRender(true);
    return handleValidation();
  };

  useEffect(() => {
    const fetchTrackingSettings = async (): Promise<void> => {
      try {
        const trackingSettings = await fetchTrackingSettingsByProgramId({ id: hookWhoAmI?.programId ?? '' });
        setProgramTrackingSettings(trackingSettings);
      } catch (error) {
        setProgramTrackingSettings(null);
      }
    };

    fetchTrackingSettings();
    handleGetCountries();
  }, []);

  useEffect(() => {
    handleValidation();
  }, [secondRender]);

  useEffect(() => {
    if (!firstRender) {
      geoStatesHandler([]);
      setFirstRender(false);
    }
    newProductErrors.country = '';
  }, [targetCountry]);

  useEffect(() => {
    if (!productUrl) {
      setProductUrlError('');
    }
    vali.validateUrlStatus(productUrl, setProductUrlStatus);
  }, [productUrl]);

  useEffect(() => {
    if (!landingPage) {
      setLandingUrlError('');
    }
    vali.validateUrlStatus(landingPage, setLandingUrlStatus);
  }, [landingPage]);

  useEffect(() => {
    if (!thirdUrl) {
      setThirdPartyUrlError('');
    }
    vali.validateUrlStatus(thirdUrl, setThirdPartyUrlStatus);
  }, [thirdUrl]);

  return {
    hookProductName: productName,

    hookMininmumIncome: minimumIncome,

    hookMinimumCredit: minimumCredit,

    hookMinimumHouseHold: minimumhousehold,

    hookProductUrl: productUrl,

    hookProductId: id,

    hookDescription: description,

    hookLandingPage: landingPage,

    hookThirdUrl: thirdUrl,

    hookStatus: status,

    hookImageFile: imageFile,

    hookGender: gender,

    hookVisibility: visibility,

    hookCategory: category,

    hookSubCategory: displayedSubCategories,

    hookTargetStates: optionsMaker(geoStates),

    hookTargetCountry: targetCountry,

    hookCountryOptions: countryOptions,

    hookRegionOptions: regionOptions,

    hookAges: ages,

    hookNewProductErrors: newProductErrors,

    hookProductUrlError: productUrlError,

    hookLandingUrlError: landingUrlError,

    hookThirdPartyUrlError: thirdPartyUrlError,

    hookLoading: updateProductLoading,

    hookValidate: handleValidation,

    hookValidateWebsite: validateWebsite,

    hookHandleAges: handleAges,

    hookProductImageUrl: productImageUrl,

    hookHandleUploadImage: onUploadHandler,

    hookHandleTargetState: geoStatesHandler,

    hookHandleStatus: handleStatus,

    hookHandleSubCategory: handleCheckSubCategory,

    hookHandleCategory: handleCategory,

    hookHandleGender: handleGender,

    hookHandleCommissions: handleCommissions,

    hookHandleCountry: handleCountry,

    hookHandleProductName: handleProductName,

    hookHandleProductUrl: handleProductUrl,

    hookHandleId: handleId,

    hookHandleDescription: handleDescription,

    hookHandleLandingPage: handleLandingPage,

    hookHandleThirdParty: handleThirdParty,

    hookHandleIncome: handleIncome,

    hookHandleHousehold: handlehousehold,

    hookHandleCredit: handleCredit,

    hookhandleUpdateProduct: handleUpdateProduct,
  };
};
