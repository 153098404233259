import React from 'react';

import { Modal } from 'components';
import { CHECK_TYPES_OPTIONS, URLS_LIST } from 'pages/Merchants/FintelCheck/FintelCheckPublisherSearch/enums';

import { useTrackingUrls } from '../hooks';

import * as Styled from './styles';

type UrlModalProps = {
  hook: ReturnType<typeof useTrackingUrls>;
};

const AddTrackingUrlModal = ({ hook }: UrlModalProps): JSX.Element => (
  <Modal isOpen={hook.isOpen}>
    <Styled.Wrapper>
      <Styled.TitleStyled>{URLS_LIST.MODAL.TITLE.EDIT}</Styled.TitleStyled>

      <Styled.InputStyled
        type="url"
        label="url"
        required
        value={hook.url}
        placeholder="https://www.fintelconnect.com"
        disabled
        /* set to 2001 to trigger error message -> save button is disabled on error */
        maxLength={2001}
      />
      <Styled.SelectStyled
        label="Check Type"
        placeholder="Select a check type"
        required
        selected={hook.checkType}
        onChange={hook.setCheckType}
        options={CHECK_TYPES_OPTIONS}
        error={hook.checkTypeError}
      />

      <Styled.ButtonsWrapperStyled>
        <Styled.ButtonStyled
          theme="secondary"
          onClick={() => {
            hook.setIsOpen();
            hook.resetModal();
          }}
        >
          {URLS_LIST.MODAL.BUTTON.CANCEL}
        </Styled.ButtonStyled>
        <Styled.ButtonStyled loading={hook.updateTrackingUrlLoading} onClick={hook.handleModalRightButton}>
          {URLS_LIST.MODAL.BUTTON.EDIT}
        </Styled.ButtonStyled>
      </Styled.ButtonsWrapperStyled>
    </Styled.Wrapper>
  </Modal>
);

export default AddTrackingUrlModal;
