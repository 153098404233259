import React from 'react';
// General App Imports
import { AnyEventObject, SnapshotFrom } from 'xstate';

import { Spacer } from 'components';
// Custom Components
import { PageWrapper } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/styles';
import { CustomTextType } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddCustomTextComponent';
import { machine } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/machine';
// Custom Hooks
import { useAddMonitoringRule } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddMonitoringRule/hooks';

// Custom Styled Components
import { RULE_SUMMARY, NEW_RULE_SUMMARY, FIELD_LABELS } from './enums';
import * as Styled from './styles';

interface IRuleDetailsModalProps {
  isNewRule?: boolean;
  closeModalFn: (state: boolean) => void;
  newRuleCreated: () => void;
  stateMachine: SnapshotFrom<typeof machine>;
  send: (event: AnyEventObject) => void;
}

export const MonitoringRuleSummaryModal = ({
  closeModalFn,
  newRuleCreated,
  stateMachine,
  send,
  isNewRule,
}: IRuleDetailsModalProps): JSX.Element => {
  const hook = useAddMonitoringRule(closeModalFn, newRuleCreated, stateMachine, send);
  const ENUM: Partial<typeof NEW_RULE_SUMMARY> = isNewRule ? NEW_RULE_SUMMARY : RULE_SUMMARY;

  const formatRuleStatusSummary = (): string => {
    if (!hook.stateMachine.context.ruleStatusSettings) return '';
    return `If ${hook.stateMachine.context.ruleStatusSettings.fieldsRequired?.toLowerCase()} 
    text phrase(s) are ${hook.stateMachine.context.ruleStatusSettings.fieldState?.toLowerCase()} on this page, 
    this rule should ${hook.stateMachine.context.ruleStatusSettings.ruleState?.toLowerCase()}`;
  };

  return (
    <PageWrapper>
      <Spacer direction="vertical" size="medium" />
      {isNewRule && <Styled.ReviewTextStyled>{ENUM?.REVIEW_NOTICE}</Styled.ReviewTextStyled>}
      <Spacer direction="vertical" size="medium" />
      <Styled.FlexRow>
        <Styled.RowWrapper>
          <Styled.CategoryWrapper>
            <Styled.CategoryHeader>{FIELD_LABELS.RULE_NAME}</Styled.CategoryHeader>
            <Styled.CategoryValue>{hook.stateMachine.context.ruleName}</Styled.CategoryValue>
          </Styled.CategoryWrapper>
        </Styled.RowWrapper>
        <Styled.RowWrapper>
          <Styled.CategoryWrapper>
            <Styled.CategoryHeader>{FIELD_LABELS.RULE_GROUP}</Styled.CategoryHeader>
            <Styled.CategoryValue>{hook.stateMachine.context.ruleGroup}</Styled.CategoryValue>
          </Styled.CategoryWrapper>
        </Styled.RowWrapper>
      </Styled.FlexRow>

      <Spacer direction="vertical" size="medium" />

      <Styled.RuleStatusWrapper>
        <Styled.RowWrapper>
          <Styled.CategoryRuleStatusWrapper>
            <Styled.CategoryHeader>{FIELD_LABELS.RULE_STATUS}</Styled.CategoryHeader>
            <Styled.CategoryValue>{formatRuleStatusSummary()}</Styled.CategoryValue>
          </Styled.CategoryRuleStatusWrapper>
        </Styled.RowWrapper>
      </Styled.RuleStatusWrapper>

      <Spacer direction="vertical" size="medium" />

      <Styled.CustomTextWrapper>
        <Styled.CustomTextListHeader>{FIELD_LABELS.CUSTOM_TEXT_LIST}</Styled.CustomTextListHeader>
        <Styled.CustomTextValuesWrapper>
          {hook.stateMachine.context.textCriteria?.map((item: CustomTextType, index: number) => (
            <Styled.CustomTextValueWrapper key={item.id}>
              <Styled.CustomTextIndexLabel>{index + 1}</Styled.CustomTextIndexLabel>
              <Styled.CustomTextValue>{item.value}</Styled.CustomTextValue>
            </Styled.CustomTextValueWrapper>
          ))}
        </Styled.CustomTextValuesWrapper>
      </Styled.CustomTextWrapper>
    </PageWrapper>
  );
};
