import React from 'react';

import { ErrorBanner, FileUpload } from 'components';
import { ConfirmationModal } from 'components/Modal/ConfirmationModal';
import { FILE_EXTENSIONS } from 'utils';

import { CHECK_TYPES_OPTIONS, URLS_CATEGORIES_OPTIONS } from '../UrlsList/enums';

import { URL_UPLOAD } from './enums';
import { useUrlBulkUpload } from './hooks';
import * as Styled from './styles';

type FintelCheckUrlBulkUploadProps = {
  isReadOnly: boolean;
};

const FintelCheckUrlBulkUpload = ({ isReadOnly }: FintelCheckUrlBulkUploadProps): JSX.Element => {
  const hook = useUrlBulkUpload();

  return (
    <>
      <Styled.DescriptionStyled>{URL_UPLOAD.DESCRIPTION}</Styled.DescriptionStyled>
      <Styled.InstructionStyled>{URL_UPLOAD.INSTRUCTION}</Styled.InstructionStyled>
      <ErrorBanner message={hook.errorMessage} isOpen={!!hook.errorMessage} />
      <FileUpload
        label={URL_UPLOAD.LABEL}
        onChange={hook.fileUploadHandler}
        info="Max file size 4MB"
        // Bug in Windows version of Firefox to see csv files as Excel files. see https://support.mozilla.org/en-US/questions/1401889
        accept={`.csv, .txt, *.csv, *.txt, text/csv, text/plain, 
          ${FILE_EXTENSIONS.MS_EXCEL}, ${FILE_EXTENSIONS.SPREADSHEET}`}
        type="csv"
        required
        disabled={hook.bulkUploadLoading || isReadOnly || hook.urlsToBeUploadedLoading}
        maxFileSize={4000000}
        width="35vw"
      />

      <Styled.SelectWrapperStyled>
        <Styled.SelectStyled
          label="Category"
          placeholder="Select a category"
          required
          selected={hook.category}
          onChange={hook.setCategory}
          options={URLS_CATEGORIES_OPTIONS}
          error={hook.categoryError}
          width="35vw"
        />
        <Styled.SelectStyled
          label="Check Type"
          placeholder="Select a check type"
          required
          selected={hook.checkType}
          onChange={hook.setCheckType}
          options={CHECK_TYPES_OPTIONS}
          error={hook.checkTypeError}
          width="35vw"
        />
      </Styled.SelectWrapperStyled>

      <Styled.ButtonsWrapperStyled>
        <Styled.ButtonStyled
          onClick={hook.toggleConfirmationModal}
          disabled={
            hook.bulkUploadLoading || isReadOnly || hook.urlsToBeUploadedLoading || hook.urlsToBeUploaded.length === 0
          }
          loading={hook.bulkUploadLoading || hook.urlsToBeUploadedLoading}
          type="submit"
          theme="primary"
          width="10vw"
        >
          {URL_UPLOAD.SUBMIT_BUTTON}
        </Styled.ButtonStyled>
      </Styled.ButtonsWrapperStyled>

      {/* Confirmation modal */}
      <ConfirmationModal
        isOpen={hook.isConfirmationModalOpen}
        onClose={hook.toggleConfirmationModal}
        onConfirm={hook.handleSubmitButton}
        isLoading={hook.bulkUploadLoading}
        title={
          URL_UPLOAD.CONFIRMATION_MODAL.TITLE +
          hook.urlsToBeUploaded.length +
          URL_UPLOAD.CONFIRMATION_MODAL.TITLE_SUFFIX
        }
        desc={URL_UPLOAD.CONFIRMATION_MODAL.DESCRIPTION}
        onCloseText={URL_UPLOAD.CONFIRMATION_MODAL.CANCEL}
        onConfirmText={URL_UPLOAD.CONFIRMATION_MODAL.CONFIRM}
      />
    </>
  );
};

export default FintelCheckUrlBulkUpload;
