/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import short from 'short-uuid';

import { useModal, useUserInfo } from 'hooks';
import {
  MANAGEMENT_FEES_CATEGORIES,
  MERCHANT_PREFIX,
  PAYMENT_METHOD_DEPOSIT,
  PDF_TYPES,
  csvGenerator,
  dateFormatter,
  defaultOption,
  path,
  rangeFormat,
  standardDateFormat,
  toUTCHours,
} from 'utils';
import { useValidation } from 'utils/validation';
import { toUTC } from 'utils/formatCommissions';

import {
  DEPOSIT_ORDER_STATUS,
  DEPOSIT_ORDER_TYPE,
  ERROR_MESSAGES,
  ORDER_STATUS,
  SORT_DEFAULT,
  validationDepositfields,
  validationfields,
} from '../contracts';
import {
  CHECK_UNCOMPLETED_ORDERS,
  GET_ACCOUNT_BALANCE_CSV,
  MERCHANT_ACCOUNT_BALANCE_LIST,
  MERCHANT_PROGRAM,
  PAYPAL_ORDERS,
} from '../graphql/queries';
import { DEPOSIT_NOTIFICATION, DEPOSIT_ORDER, UPDATE_DEPOSIT_ORDER } from '../graphql/mutation';
import { Permission } from '../../../../../entities';

export const useMerchantAccountBalance = (permissionsCodeList: string[] = []) => {
  const { hookWhoAmI } = useUserInfo();
  const navigate = useNavigate();
  const validtionHook = useValidation();
  const translator = short();

  const [getProgram] = useLazyQuery(MERCHANT_PROGRAM);
  const [getAccountBalanceList, { loading: getAccountBalanceLoading }] = useLazyQuery(MERCHANT_ACCOUNT_BALANCE_LIST);
  const [getAccountBalancesCSV] = useLazyQuery(GET_ACCOUNT_BALANCE_CSV);
  const [getPaypalOrders, { loading: getPaypalOrdersLoading }] = useLazyQuery(PAYPAL_ORDERS);
  const [getCheckUncompletedOrders] = useLazyQuery(CHECK_UNCOMPLETED_ORDERS);

  const [createNewDeposit, { loading: createNewDepositLoading }] = useMutation(DEPOSIT_NOTIFICATION);
  const [createDepositOrder] = useMutation(DEPOSIT_ORDER);
  const [updateDepositOrder] = useMutation(UPDATE_DEPOSIT_ORDER);

  const [notifyModal, setNotifyModal] = useModal(false);
  const [addModal, setAddModal] = useModal(false);
  const [isCalendarOpen, setIsCalendarOpen] = useModal(false);
  const [notifyCalenderOpen, setNotifyCalenderOpen] = useModal(false);
  const [isPaypalCalendarOpen, setIsPaypalCalendarOpen] = useModal(false);
  const [paypalFailOpen, setPaypalFailOpen] = useModal(false);
  const [paypalSuccessOpen, setPaypalSuccessOpen] = useModal(false);

  const [generalMerchantInfo, setGeneralMerchantInfo] = useState<{ [key: string]: any }>({});

  const [accountStats, setAccountStats] = useState<AccountBalanceStats[]>([]);
  const [monthyAccountBalance, setMonthyAccountBalance] = useState<MerchantMonthyAccountBalance[]>([]);
  const [monthyAccountTotals, setMonthyAccountTotals] = useState();

  const [paypalOrders, setPaypalOrders] = useState<PaypalAccountBalance[]>([]);
  const [paypalStartDate, setPaypalStartDate] = useState(rangeFormat('lastMonth').start);
  const [paypalEndDate, setPaypalEndDate] = useState(new Date());
  const [paypalDateInputValue, setPaypalDateInputValue] = useState('');
  const [paypalStatus, setPaypalStatus] = useState(ORDER_STATUS[0]);
  const [selectedPaypalProductCategory, setSelectedPaypalProductCategory] = useState(defaultOption.productCategory);

  const [selectedProductCategory, setSelectedProductCategory] = useState(defaultOption.productCategory);
  const [activeProductCategories, setActiveProductCategories] = useState<SelectOption[]>([]);
  const [selectedDepositProductCategory, setSelectedDepositProductCategory] = useState<SelectOption>();
  const [depositAmount, setDepositAmount] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');

  const [selectedNotifyProductCategory, setSelectedNotifyProductCategory] = useState<SelectOption>();
  const [paymentAmount, setPaymentAmount] = useState('');
  const [nofityAdditionalInfo, setNofityAdditionalInfo] = useState('');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(PAYMENT_METHOD_DEPOSIT[0]);
  const [paymentEmail, setPaymentEmail] = useState('');
  const [paymentName, setPaymentName] = useState('');
  const [paymentDate, setPaymentDate] = useState(new Date());
  const [paymentInputDate, setPaymentInputDate] = useState('');

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dateInputValue, setDateInputValue] = useState('');
  const [useDate, setUseDate] = useState(false);

  const [numberOfPages, setNumberOfPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [sortColumn, setSortColumn] = useState<TableSortColumn>(SORT_DEFAULT);

  const [errorMesseges, setErrorMessages] = useState<{ [key: string]: string }>({});
  const [isloading, setIsLoading] = useState(true);

  const [currentPagePaypal, setCurrentPagePaypal] = useState(1);
  const [totalPagesPaypal, setTotalPagesPaypal] = useState(1);
  const [sortColumnPaypal, setSortColumnPaypal] = useState<TableSortColumn>();
  const selectedFormat = (value: string): SelectOption => ({
    label: value,
    value,
  });
  const formateStats = (stat: any, salesTaxRate: string) => {
    const newUseTieredRates: string[] = [];
    stat.newUseTieredRate.forEach((item: any, index: number) => {
      if (index === 0) {
        newUseTieredRates.push(
          `$0 to $${Number(item.newAdminTierCommission).toFixed(2)}: ${Number(item.newAdminTierRate).toFixed(2)}%`
        );
      }
      if (index > 0) {
        newUseTieredRates.push(
          `$${(Number(stat.newUseTieredRate[index - 1].newAdminTierCommission) + 0.01).toFixed(2)} to $${Number(item.newAdminTierCommission).toFixed(2)}: ${Number(item.newAdminTierRate).toFixed(2)}%`
        );
      }
      if (stat.newUseTieredRate.length === index + 1) {
        newUseTieredRates.push(
          `Above $${Number(item.newAdminTierCommission).toFixed(2)}: ${Number(item.newAdminAfterTierRate).toFixed(2)}%`
        );
      }
    });
    return stat.activeCategories.map((activeCategory: string) => ({
      productCategory: activeCategory,
      useRateSettings: false,
      adminRate: stat.adminRate,
      minUsageFee: stat.minUsageFee,
      minBalanceRequired: stat.minBalanceRequired,
      salesTaxRate,
      managementFee: stat.managementFee[MANAGEMENT_FEES_CATEGORIES[activeCategory.toUpperCase()]],
      systemUserRate: newUseTieredRates,
    }));
  };

  const getAccountBalance = async () => {
    if (!hookWhoAmI.programId) return;

    try {
      const {
        data: {
          programV2: { accountBalanceStats, accountStat, enableNewStats, salesTaxRate, transactionSettings, merchant },
        },
        error,
      } = await getProgram({
        variables: {
          id: hookWhoAmI.programId,
        },
      });

      if (error) throw error;

      const statData =
        accountStat && enableNewStats
          ? formateStats(accountStat, salesTaxRate)
          : [{ ...accountBalanceStats, salesTaxRate }];
      setAccountStats(statData);
      setNumberOfPages(Math.ceil(statData.length / 10));
      setCurrentPage(1);

      const productCategoriesSelect = accountStat.activeCategories
        ? accountStat.activeCategories.map((cat: string) => selectedFormat(cat))
        : [];
      setActiveProductCategories(productCategoriesSelect);
      setSelectedDepositProductCategory(productCategoriesSelect[0]);

      setGeneralMerchantInfo({
        currency: selectedFormat(transactionSettings.currency),
        enableNewStats,
        companyName: merchant.companyName,
      });
    } catch (error: any) {
      setErrorMessages({ message: error.message });
    }
  };

  const getMonthlyAcountBalance = async (resetPage = false) => {
    try {
      const input: {
        merchantId: string;
        year?: string;
        month?: string;
        productCategory?: string;
        dateStart?: Date;
        dateEnd?: Date;
      } = { merchantId: `${hookWhoAmI?.companyId?.toString()}` };

      if (startDate && useDate) input.dateStart = startDate;
      if (endDate && useDate) input.dateEnd = endDate;
      if (selectedProductCategory.value !== '') input.productCategory = selectedProductCategory.value;
      const {
        data: {
          accountBalancesV2: { accountBalances, accountBalanceTotals, count },
        },
        error,
      } = await getAccountBalanceList({
        variables: {
          input: {
            ...input,
            options: {
              page: resetPage ? 1 : currentPage,
              items: 20,
              order: sortColumn.direction?.toUpperCase(),
            },
            sortBy: sortColumn.column,
          },
        },
        fetchPolicy: 'no-cache',
      });

      if (error) throw error;
      setMonthyAccountBalance(accountBalances);
      setMonthyAccountTotals(accountBalanceTotals);
      setNumberOfPages(Math.ceil(count / 20));
      setIsLoading(false);
    } catch (error: any) {
      setErrorMessages({ message: error.message });
      setIsLoading(false);
    }
  };

  const getPayPalOrders = async (resetPage = false) => {
    const input: { [key: string]: string | Date } = {
      merchantId: `${hookWhoAmI?.companyId?.toString()}`,
      type: 'MERCHANT_DEPOSIT',
      startDate: toUTC(paypalStartDate, 'startDate'),
      endDate: toUTC(paypalEndDate),
    };

    if (selectedPaypalProductCategory.value !== '') input.productCategory = selectedPaypalProductCategory.value;
    if (paypalStatus.value !== 'any') input.status = paypalStatus.value;

    try {
      const {
        data: {
          paypalOrdersV2: { orders },
        },
        error,
      } = await getPaypalOrders({
        variables: {
          input: {
            ...input,
            currentPage: resetPage ? 1 : currentPage,
            limit: 20,
            sortOrder: sortColumnPaypal?.direction === 'asc' ? 1 : -1,
            sortBy: sortColumnPaypal?.column,
          },
        },
        fetchPolicy: 'no-cache',
      });

      if (error) throw error;

      setPaypalOrders(orders);
      setTotalPagesPaypal(Math.ceil(orders.length / 10));
      setCurrentPagePaypal(1);
      setIsLoading(false);
    } catch (err: any) {
      setErrorMessages({ message: err.message });
      setIsLoading(false);
    }
  };

  const applyCalendarHandler = (date: Date, endDateValue = new Date()) => {
    if (endDateValue.getTime() < date.getTime()) {
      setErrorMessages({ calendar: ERROR_MESSAGES.CALENDAR });
      return;
    }
    setStartDate(date);
    setEndDate(endDateValue);
    const startFormatted = date.toDateString();
    const endFormatted = endDateValue.toDateString();
    if (startFormatted === endFormatted) {
      setDateInputValue(startFormatted);
    } else {
      setDateInputValue(`${startFormatted} - ${endFormatted}`);
    }
    setUseDate(true);
    setIsCalendarOpen();
  };

  const applyPaypalCalendarHandler = (date: Date, endDateValue = new Date()) => {
    setPaypalStartDate(date);
    setPaypalEndDate(endDateValue);
    const startFormatted = dateFormatter(date);
    const endFormatted = dateFormatter(endDateValue);
    setPaypalDateInputValue(`${startFormatted} - ${endFormatted}`);
    setIsPaypalCalendarOpen();
  };

  const handleValidation = () => {
    const validationValues = {
      paymentAmount: paymentAmount.replace(/\$/g, ''),
      paymentDate: paymentInputDate,
      paymentEmail,
      paymentMethod: selectedPaymentMethod.value,
      productCategory: selectedNotifyProductCategory?.value || '',
      payersName: paymentName,
    };
    return {
      valid: validtionHook.validateAll(validationValues, validationfields, setErrorMessages, true),
      values: validationValues,
    };
  };

  const handleDepositValidation = () => {
    const validationValues = {
      amount: depositAmount.replace(/\$/g, ''),
      productCategory: selectedDepositProductCategory?.value || '',
      currency: generalMerchantInfo.currency.value,
    };

    return {
      valid: validtionHook.validateAll(validationValues, validationDepositfields, setErrorMessages, true),
      values: { ...validationValues },
    };
  };

  const notifyDepositHandler = async () => {
    const valid = handleValidation();

    if (valid.valid) {
      try {
        const { errors } = await createNewDeposit({
          variables: {
            input: {
              merchantId: hookWhoAmI?.companyId?.toString(),
              ...valid.values,
              additionalInfo: nofityAdditionalInfo,
              payersName: paymentName,
              currency: generalMerchantInfo.currency.value,
            },
          },
          fetchPolicy: 'no-cache',
        });

        if (errors) throw new Error(errors.map((e: any) => e.message).join(', '));
        setNotifyModal();
        setSelectedPaymentMethod(PAYMENT_METHOD_DEPOSIT[0]);
        setPaymentInputDate('');
        setSelectedNotifyProductCategory({ value: '', label: '' });
        setPaymentEmail('');
        setPaymentAmount('');
        setNofityAdditionalInfo('');
        setPaymentName('');
      } catch (error: any) {
        setErrorMessages({ notifyGeneral: error.message });
      }
    }
  };

  const addDepositHandler = async () => {
    const valid = handleDepositValidation();
    if (valid.valid) {
      try {
        const { data, errors } = await createDepositOrder({
          variables: {
            input: {
              merchantId: hookWhoAmI?.companyId?.toString(),
              ...valid.values,
              status: DEPOSIT_ORDER_STATUS,
              type: DEPOSIT_ORDER_TYPE,
              notes: additionalInfo,
            },
          },
        });

        if (errors) throw new Error(errors.map((e: any) => e.message).join(', '));

        return {
          custom_id: data.createPayPalOrder.id,
          invoice_id: `${generalMerchantInfo.companyName}-${standardDateFormat(new Date())}-${translator.generate()}`,
        };
      } catch (error: any) {
        setErrorMessages({ paypal: error.message });
      }
    }
    return null;
  };

  const payPalFailedHandler = async () => {
    setAddModal();
    setPaypalFailOpen();
  };

  const payPalSuccessHandler = async (transaction: any) => {
    const res = transaction?.status;
    const paypalOrderId = transaction?.purchase_units[0]?.custom_id || null;
    if (!paypalOrderId || res !== 'COMPLETED') {
      payPalFailedHandler();
      return;
    }
    try {
      const { errors } = await updateDepositOrder({
        variables: {
          input: {
            id: paypalOrderId,
          },
        },
      });

      if (errors) throw new Error(errors.map((e: any) => e.message).join(', '));
      setAddModal();
      setPaypalSuccessOpen();
    } catch (error: any) {
      setErrorMessages({ message: error.message });
    }
  };

  const retryPaypalDepositHandler = () => {
    setAddModal();
    setPaypalFailOpen();
  };

  const navigationHandler = (row: any) => {
    navigate(`${MERCHANT_PREFIX}${path.merchantBalanceManagementDetails.href}`, {
      state: {
        merchant: hookWhoAmI.companyName,
        merchantId: hookWhoAmI.companyId?.toString(),
        productCategory: row.productCategory,
        accountBalancesId: row.id,
        finalized: row.finalized,
        month: Number(row.month),
        year: Number(row.year),
      },
    });
  };

  const statementNavigationHandler = (row: any) => {
    navigate(`${MERCHANT_PREFIX}${path.merchantInvoiceStatement.href}`, {
      state: {
        accountBalancesId: row.id,
        month: row?.month?.toString(),
        year: row?.year?.toString(),
        merchantId: `${hookWhoAmI?.companyId?.toString()}`,
        productCategory: row.productCategory,
        pdfType: PDF_TYPES.MERCHANT,
      },
    });
  };

  const applyNoficationCalenderHandler = (date: Date) => {
    setPaymentDate(date);
    setPaymentInputDate(dateFormatter(date));
    const copy = { ...errorMesseges };
    copy.paymentDate = '';
    setErrorMessages(copy);
    setNotifyCalenderOpen();
  };

  const csvDownHandler = async () => {
    try {
      const input: { [key: string]: Date | string | boolean } = {
        merchantId: `${hookWhoAmI?.companyId?.toString()}`,
        enableNewStats: generalMerchantInfo.enableNewStats,
      };

      if (startDate && useDate) input.dateStart = startDate;
      if (endDate && useDate) input.dateEnd = endDate;
      if (selectedProductCategory.value !== '') input.productCategory = selectedProductCategory.value;
      const {
        data: { accountBalanceCSV },
        error,
      } = await getAccountBalancesCSV({
        variables: {
          input: {
            ...input,
            options: {
              order: sortColumn.direction?.toUpperCase(),
            },
            sortBy: sortColumn.column,
          },
        },
        fetchPolicy: 'no-cache',
      });
      if (error) throw new Error(error.message);
      if (accountBalanceCSV?.csv) {
        csvGenerator(accountBalanceCSV.csv, 'account-balance-report');
      }
    } catch (error: any) {
      setErrorMessages({ message: error.message });
    }
  };

  const clearFilterHandler = () => {
    setStartDate(new Date());
    setUseDate(false);
    setEndDate(new Date());
    setSelectedProductCategory(defaultOption.productCategory);
    setDateInputValue('');
  };

  const clearPaypalFilterHandler = () => {
    setSelectedPaypalProductCategory(defaultOption.productCategory);
    const dateRange = rangeFormat('last30Days');
    setPaypalEndDate(dateRange.end);
    setPaypalStartDate(dateRange.start);
    setPaypalDateInputValue(`${dateFormatter(dateRange.start)} - ${dateFormatter(dateRange.end)}`);
    setPaypalStatus(ORDER_STATUS[0]);
  };

  const formatDate = (newDate: Date): string => {
    const month =
      (newDate.getMonth() + 1).toString().length !== 2 ? `0${newDate.getMonth() + 1}` : `${newDate.getMonth() + 1}`;
    const tempDate = newDate.getDate().toString().length !== 2 ? `0${newDate.getDate()}` : `${newDate.getDate()}`;
    const formattedDate = `${newDate.getFullYear()}-${month}-${tempDate}`;
    return formattedDate;
  };

  const setupPaypalTabHandler = () => {
    getPayPalOrders();
    setSelectedPaypalProductCategory(defaultOption.productCategory);
    const dateRange = rangeFormat('last30Days');
    setPaypalEndDate(toUTCHours(dateRange.end, 'end'));
    setPaypalStartDate(toUTCHours(dateRange.start, 'beginning'));
    setPaypalDateInputValue(
      `${dateFormatter(new Date(formatDate(toUTCHours(dateRange.start, 'beginning'))))} - ${dateFormatter(new Date(formatDate(toUTCHours(dateRange.end, 'end'))))}`
    );
    setPaypalStatus(ORDER_STATUS[0]);
  };

  const setCurrentPageHandler = (value: number) => setCurrentPage(value);

  const setSortColumnHandler = (dataField: string, direction: 'asc' | 'desc' | undefined) => {
    if (sortColumn.direction === null) {
      setSortColumn({ column: dataField, direction });
    } else {
      setSortColumn({ column: dataField, direction: sortColumn.direction === 'asc' ? 'desc' : 'asc' });
    }
  };

  const setSortColumnHandlerPaypal = (dataField: string, direction: 'asc' | 'desc' | undefined) => {
    if (sortColumnPaypal?.direction === null) {
      setSortColumnPaypal({ column: dataField, direction });
    } else {
      setSortColumnPaypal({ column: dataField, direction: sortColumnPaypal?.direction === 'asc' ? 'desc' : 'asc' });
    }
  };

  const setNotifyModalHandler = () => setNotifyModal();
  const setAddModalHandler = async () => {
    if (!addModal) {
      const {
        data: { checkUncompletedOrders },
      } = await getCheckUncompletedOrders({
        variables: {
          merchantsId: `${hookWhoAmI?.companyId?.toString()}`,
        },
        fetchPolicy: 'no-cache',
      });
      if (checkUncompletedOrders) {
        setErrorMessages({ message: ERROR_MESSAGES.NEW_DEPOSIT });
        return;
      }
    }
    setAddModal();
  };
  const setIsCalendarOpenHandler = () => setIsCalendarOpen();
  const setNotifyCalenderOpenHandler = () => setNotifyCalenderOpen();
  const setIsPaypalCalendarOpenHandler = () => setIsPaypalCalendarOpen();
  const setPaypalFailOpenHandler = () => setPaypalFailOpen();
  const setPaypalSuccessOpenHandler = () => setPaypalSuccessOpen();

  const setDepositAmountHandler = (value: React.ChangeEvent<HTMLInputElement>) => {
    let nonAlphabeticValue = value.target.value.replace(/[a-zA-Z]/g, '');
    if (!/\$/.test(nonAlphabeticValue)) nonAlphabeticValue = `$${nonAlphabeticValue}`;
    const copy = { ...errorMesseges };
    copy.amount = '';
    setErrorMessages(copy);
    setDepositAmount(nonAlphabeticValue);
  };
  const setAdditionalInfoHandler = (value: React.ChangeEvent<HTMLInputElement>) =>
    setAdditionalInfo(value.target.value);

  const setSelectedDepositProductCategoryHandler = (value: SelectOption) => {
    setSelectedDepositProductCategory(value);
  };
  const setPaymentAmountHandler = (value: React.ChangeEvent<HTMLInputElement>) => {
    let nonAlphabeticValue = value.target.value.replace(/[a-zA-Z]/g, '');
    if (!/\$/.test(nonAlphabeticValue)) nonAlphabeticValue = `$${nonAlphabeticValue}`;
    const copy = { ...errorMesseges };
    copy.paymentAmount = '';
    setErrorMessages(copy);
    setPaymentAmount(nonAlphabeticValue);
  };
  const setNofityAdditionalInfoHandler = (value: React.ChangeEvent<HTMLInputElement>) =>
    setNofityAdditionalInfo(value.target.value);

  const setSelectedNotifyProductCategoryHandler = (value: SelectOption) => {
    setSelectedNotifyProductCategory(value);
    const copy = { ...errorMesseges };
    copy.productCategory = '';
    setErrorMessages(copy);
  };
  const setSelectedPaymentMethodHandler = (value: SelectOption) => setSelectedPaymentMethod(value);

  const setPaymentEmailHandler = (value: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentEmail(value.target.value);
    const copy = { ...errorMesseges };
    copy.paymentEmail = '';
    setErrorMessages(copy);
  };
  const setPaymentNameHandler = (value: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentName(value.target.value);
    const copy = { ...errorMesseges };
    copy.payersName = '';
    setErrorMessages(copy);
  };

  const setSelectedProductCategoryHandler = (value: SelectOption) => setSelectedProductCategory(value);
  const setSelectedPaypalProductCategoryHandler = (value: SelectOption) => setSelectedPaypalProductCategory(value);
  const setPaypalStatusHandler = (value: SelectOption) => setPaypalStatus(value);

  const handlePageChangePaypal = (value: any) => {
    setCurrentPagePaypal(value);
  };

  useEffect(() => {
    getAccountBalance();
    getMonthlyAcountBalance();

    const dateRange = rangeFormat('last30Days');
    setPaypalEndDate(dateRange.end);
    setPaypalStartDate(dateRange.start);
  }, []);

  useEffect(() => {
    if (isloading) return;
    setIsLoading(true);
    getMonthlyAcountBalance(true);
  }, [selectedProductCategory, startDate, JSON.stringify(sortColumn), endDate]);

  useEffect(() => {
    getMonthlyAcountBalance(false);
  }, [currentPage]);

  useEffect(() => {
    if (isloading) return;
    setIsLoading(true);
    getPayPalOrders(true);
  }, [selectedPaypalProductCategory, paypalStatus, paypalDateInputValue, sortColumnPaypal, currentPagePaypal]);

  return {
    hookGeneralMerchantInfo: generalMerchantInfo,

    hookAccountStats: accountStats,
    hookMonthyAccountBalance: monthyAccountBalance,

    hookSelectedProductCategory: selectedProductCategory,
    hookSetSelectedProductCategory: setSelectedProductCategoryHandler,

    hookActiveProductCategories: activeProductCategories,
    hookSelectedDepositProductCategory: selectedDepositProductCategory,
    hookSetSelectedDepositProductCategory: setSelectedDepositProductCategoryHandler,

    hookSelectedNotifyProductCategory: selectedNotifyProductCategory,
    hooksetSelectedNotifyProductCategory: setSelectedNotifyProductCategoryHandler,

    hookPaymentAmount: paymentAmount,
    hooksetPaymentAmount: setPaymentAmountHandler,

    hookNofityAdditionalInfo: nofityAdditionalInfo,
    hookSetNofityAdditionalInfo: setNofityAdditionalInfoHandler,

    hookDepositAmount: depositAmount,
    hookSetDepositAmount: setDepositAmountHandler,

    hookSelectedPaymentMethod: selectedPaymentMethod,
    hookSetSelectedPaymentMethod: setSelectedPaymentMethodHandler,

    hookPaymentName: paymentName,
    hookSetPaymentName: setPaymentNameHandler,

    hookPaymentEmail: paymentEmail,
    hookSetPaymentEmail: setPaymentEmailHandler,

    hookAdditionalInfo: additionalInfo,
    hookSetAdditionalInfo: setAdditionalInfoHandler,

    hookNotifyModal: notifyModal,
    hookSetNotifyModal: setNotifyModalHandler,

    hookAddModal: addModal,
    hookSetAddModal: setAddModalHandler,

    hookNotifyCalenderOpen: notifyCalenderOpen,
    hookSetNotifyCalenderOpen: setNotifyCalenderOpenHandler,
    hookPaymentDate: paymentDate,
    hookPaymentInputDate: paymentInputDate,
    hookApplyNoficationCalender: applyNoficationCalenderHandler,

    hookIsCalendarOpen: isCalendarOpen,
    hookSetIsCalendarOpen: setIsCalendarOpenHandler,
    hookStartDate: startDate,
    hookEndDate: endDate,
    hookDateInputValue: dateInputValue,
    hookApplyCalendarHandler: applyCalendarHandler,
    hookClearFilter: clearFilterHandler,

    hookMonthyAccountTotals: monthyAccountTotals,
    hookNotifyDeposit: notifyDepositHandler,
    hookAddDeposit: addDepositHandler,

    hookErrors: errorMesseges,
    hookNavigationHandler: navigationHandler,
    hookStatementNavigationHandler: statementNavigationHandler,

    hookGetPayPalOrders: setupPaypalTabHandler,
    hookPaypalOrders: paypalOrders,

    hookPaypalStartDate: paypalStartDate,
    hookPaypalEndDate: paypalEndDate,
    hookPaypalDateInputValue: paypalDateInputValue,
    hookApplyPaypalCalendar: applyPaypalCalendarHandler,
    hookIsPaypalCalendarOpen: isPaypalCalendarOpen,
    hookSetIsPaypalCalendarOpen: setIsPaypalCalendarOpenHandler,
    hookPaypalStatus: paypalStatus,
    hookSetPaypalStatus: setPaypalStatusHandler,
    hookSelectedPaypalProductCategory: selectedPaypalProductCategory,
    hookSetSelectedPaypalProductCategory: setSelectedPaypalProductCategoryHandler,
    hookClearPaypalFilter: clearPaypalFilterHandler,

    hookCsvDownHandler: csvDownHandler,

    hookNumberOfPages: numberOfPages,
    hookCurrentPage: currentPage,
    hookSetCurrentPage: setCurrentPageHandler,
    hookSortColumn: sortColumn,
    hookSetSortColumn: setSortColumnHandler,
    hookIsLoading: isloading || getAccountBalanceLoading || getPaypalOrdersLoading,

    hookPaypalFailOpen: paypalFailOpen,
    hookSetPaypalFailOpen: setPaypalFailOpenHandler,
    hookRetryPaypalDepositHandler: retryPaypalDepositHandler,
    hookPayPalSuccessHandler: payPalSuccessHandler,
    hookPayPalFailedHandler: payPalFailedHandler,

    hookPaypalSuccessOpen: paypalSuccessOpen,
    hookSetPaypalSuccessOpen: setPaypalSuccessOpenHandler,

    hookCurrentPagePaypal: currentPagePaypal,
    hookTotalPagePaypal: totalPagesPaypal,
    hookOnChangePagePaypal: handlePageChangePaypal,

    hookSortColumnPaypal: sortColumnPaypal,
    hookHandleSortColumnPaypal: setSortColumnHandlerPaypal,

    hookNotifyAdminLoading: createNewDepositLoading,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
