import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ERROR_TYPES, URL_STATUSES, optionsMaker, path, MERCHANT_PREFIX } from 'utils';
import { useProgramsApi } from 'api/hooks/useProgramsApi';
import { IProgramTrackingSettings } from 'api/types';
import { findQueryParamsDuplicates, interpolate } from 'helpers';

import { useUpload, useUserInfo } from '../../../../../hooks';
import { useValidation } from '../../../../../utils/validation';
import { GET_COUNTRIES } from '../../../../../hooks/graphql/queries';
import { AGE_OPTIONS, STATUS_OPTIONS } from '../../ProductCatalog/EditProduct/enums';
import { CREATE_PRODUCT } from '../graphql/queries/createProduct';
import { Permission } from '../../../../../entities';
import { LIST_OPTIONS } from '../../ProductCatalog/graphql/ queries/listoptions';
import en from '../locales/en.json';

type Option = {
  label: string;
  value: string;
};

type ImageFile = {
  file?: File;
  height: number;
  width: number;
};

export const useNewProduct = (permissionsCodeList: string[] = []) => {
  const { hookWhoAmI } = useUserInfo();
  const navigate = useNavigate();
  const upload = useUpload();
  const vali = useValidation();

  const [productName, setProductName] = useState('');
  const [description, setDescription] = useState('');
  const [id, setId] = useState('');

  const [status, setStatus] = useState<Option>(STATUS_OPTIONS[0]);

  const [productCategory, setProductCategory] = useState<Option>();

  const [imageFile, setImageFile] = useState<ImageFile>({
    file: undefined,
    height: 0,
    width: 0,
  });

  const [productUrl, setProductUrl] = useState('');
  const [landingPageUrl, setLandingPageUrl] = useState('');
  const [thirdPartyUrl, setThirdPartyUrl] = useState('');

  const [gender, setGender] = useState<Option>();

  const [visibility, setVisibility] = useState({ label: 'Members Only', value: true });

  const [country, setCountry] = useState<Option>();
  const [countryOptions, setCountryOptions] = useState<Option[]>([]);
  const [region, setRegion] = useState<Option[]>([]);
  const [regionOptions, setRegionOptions] = useState<Option[]>([]);
  const [allSubs, setAllSubs] = useState<any>();

  const [personalIncome, setPersonalIncome] = useState('');
  const [householdIncome, setHouseholdIncome] = useState('');
  const [creditScore, setCreditScore] = useState('');

  const [notifyPublisher, setNotifyPublisher] = useState(false);

  const [secondRender, setSecondRender] = useState(false);
  const [newProductErrors, setNewProductErrors] = useState<{ [key: string]: string }>({});

  const [productUrlError, setProductUrlError] = useState('');
  const [landingUrlError, setLandingUrlError] = useState('');
  const [thirdPartyUrlError, setThirdPartyUrlError] = useState('');
  const [productUrlStatus, setProductUrlStatus] = useState('');
  const [landingUrlStatus, setLandingUrlStatus] = useState('');
  const [thirdPartyUrlStatus, setThirdPartyUrlStatus] = useState('');
  const [getCountries, { loading: getCountriesLoading }] = useLazyQuery(GET_COUNTRIES);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [ages, setAges] = useState(
    AGE_OPTIONS.map((age: any) => ({
      label: age,
      checked: false,
    }))
  );
  const [productList, setProductIDList] = useState<any>([]);
  const [getProductOptionsQuery, { loading: getProductOptionsLoading }] = useLazyQuery(LIST_OPTIONS);

  const [programTrackingSettings, setProgramTrackingSettings] = useState<IProgramTrackingSettings | null>(null);

  const { fetchTrackingSettingsByProgramId } = useProgramsApi();

  const getProductOptions = async () => {
    const selectedUser = hookWhoAmI.programId;

    const { data } = await getProductOptionsQuery({
      variables: {
        programId: selectedUser,
      },
      fetchPolicy: 'no-cache',
    });
    if (data?.products) {
      setProductIDList(data.products.products.map((program: any) => program.customizedProductId));
    } else {
      setErrorMessage('Failed to fetch products');
    }
  };

  const [createProduct, { loading: createProductLoading }] = useMutation(CREATE_PRODUCT);

  const returnImageurl = async () => {
    let productImageUrl;
    if (imageFile.file !== undefined) productImageUrl = await upload.hookUploadImageFile(imageFile.file);
    return productImageUrl?.url;
  };

  const handleCancel = () => {
    navigate(`${MERCHANT_PREFIX}${path.productCatalog.href}`);
  };

  const noNull = (value: any) => {
    if (value === undefined || value === null) return undefined;
    return value;
  };

  const handleCreateProduct = async () => {
    const { data } = await createProduct({
      variables: {
        input: {
          productImageUrl: noNull(await returnImageurl()),
          programId: noNull(hookWhoAmI.programId),
          customizedProductId: noNull(id),
          name: noNull(productName),
          description: noNull(description),
          status: noNull(status?.label),
          productUrl: noNull(productUrl),
          landingPage: noNull(landingPageUrl),
          thirdPartyUrl: noNull(thirdPartyUrl),
          targetDemographics: {
            gender: noNull(gender?.label),
            geographicalCountry: noNull(country?.label || ''),
            geographicalStates: noNull(region.map((obj) => obj.label)),
            age: noNull(ages.filter((obj) => obj.checked).map((obj) => obj.label)),
            commissionVisibility: noNull(visibility?.value),
            minimumIncome: noNull(personalIncome),
            minimumHouseHoldIncome: noNull(householdIncome),
            minimiumCreditScore: noNull(creditScore),
          },
          productCategory: noNull(productCategory?.label === 'All Categories' ? undefined : productCategory?.value),
          shouldNotify: notifyPublisher,
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (data.updateProduct === null) {
      setErrorMessage('Failed to Update Product');
      setIsError(true);
    }
  };

  const handleAges = (label: any) => {
    const copy = [...ages];
    const toChange = copy.find((obj) => obj.label === label);
    if (toChange) toChange.checked = !toChange.checked;
    setAges(copy);
  };

  const handleGetCountries = async () => {
    const { data } = await getCountries();
    if (data.getCountries?.countries !== undefined) {
      setCountryOptions(optionsMaker(data.getCountries?.countries.map((c: any) => c.name)));
      setAllSubs(data.getCountries.countries);
    } else {
      setIsError(true);
      setErrorMessage('Failed to fetch countries');
    }
  };

  const setCountryHandler = (value: SelectOption) => {
    setRegion([]);
    setIsError(false);
    setCountry(value);
    setRegionOptions(
      optionsMaker(allSubs.find((obj: any) => obj.name === value.label).subdivisions.map((obj: any) => obj.name))
    );
  };

  const setProductNameHandler = (e: any) => {
    setProductName(e?.target ? e.target.value : e);
  };

  const setDescriptionHandler = (e: any) => {
    setDescription(e?.target ? e.target.value : e);
  };

  const setIdHandler = (e: any) => {
    const copy = { ...newProductErrors };
    copy.productId = '';
    setNewProductErrors(copy);
    setId(e?.target ? e.target.value : e);
  };

  const setStatusHandler = (value: Option) => {
    setStatus(value);
  };

  const setProductCategoryHandler = (value: Option) => {
    setProductCategory(value);
  };

  const onUploadHandler = (file: any) => {
    if (file instanceof File) {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const image: ImageFile = {
          file,
          height: img.naturalHeight,
          width: img.naturalWidth,
        };
        setImageFile(image);
      };
    }
  };

  const setProductUrlHandler = (e: any) => {
    setProductUrl(e?.target ? e.target.value : e);
  };

  const setLandingPageUrlHandler = (e: any) => {
    setLandingPageUrl(e?.target ? e.target.value : e);
  };

  const setThirdPartyUrlHandler = (e: any) => {
    setThirdPartyUrl(e?.target ? e.target.value : e);
  };

  const setVisibilityHandler = (value: any) => {
    setVisibility({
      label: value.label,
      value: value.value,
    });
  };

  const setRegionHandler = (value: Option[]) => {
    setRegion(value);
  };

  const setPersonalIncomeHandler = (e: any) => {
    setPersonalIncome(e?.target ? e.target.value : e);
  };

  const setHouseholdIncomeHandler = (e: any) => {
    setHouseholdIncome(e?.target ? e.target.value : e);
  };

  const setCreditScoreHandler = (e: any) => {
    setCreditScore(e?.target ? e.target.value : e);
  };

  const setNotifyPublisherHandler = () => {
    setNotifyPublisher(!notifyPublisher);
  };

  const values: { [key: string]: string } = {
    productName,
    status: status?.label || '',
    productId: id,
    description,
    productCategory: productCategory?.label || '',
    targetGender: gender?.label || '',
    targetCountry: country?.label || '',
  };

  const fields = {
    productName: ERROR_TYPES.NOT_EMPTY,
    productId: ERROR_TYPES.NOT_EMPTY,
    status: ERROR_TYPES.SELECTION_REQUIRED,
    description: ERROR_TYPES.NOT_EMPTY,
    productCategory: ERROR_TYPES.SELECTION_REQUIRED,
    targetGender: ERROR_TYPES.SELECTION_REQUIRED,
    targetCountry: ERROR_TYPES.SELECTION_REQUIRED,
  };

  const isParamsDuplicatesLandingPage = (): boolean => {
    try {
      const queryParamDuplicates = findQueryParamsDuplicates(
        landingPageUrl,
        programTrackingSettings?.urlQueryString || ''
      );

      if (queryParamDuplicates.length > 0) {
        setLandingUrlError(
          interpolate(en.errors.validation.landingPageUrl.queryParamDuplicates, {
            duplicates: queryParamDuplicates.join(', '),
          })
        );
        setLandingUrlStatus(URL_STATUSES.INVALID_WEBSITE.STATUS);
        return true;
      }

      return false;
    } catch (err) {
      console.error(`Error in parsing the URL: ${landingPageUrl}`);
      return false;
    }
  };

  const validateWebsite = () => {
    vali.validateUrlStatus(productUrl, setProductUrlStatus);
    vali.renderUrlCheck(productUrlStatus, setProductUrlError);
    if (landingPageUrl !== '' && landingPageUrl !== null) {
      if (isParamsDuplicatesLandingPage()) return;

      vali.validateUrlStatus(landingPageUrl, setLandingUrlStatus);
      vali.renderUrlCheck(landingUrlStatus, setLandingUrlError, true);
    }
    if (thirdPartyUrl !== '' && thirdPartyUrl !== null) {
      vali.validateUrlStatus(thirdPartyUrl, setThirdPartyUrlStatus);
      vali.renderUrlCheck(thirdPartyUrlStatus, setThirdPartyUrlError, true);
    }
  };

  const handleValidation = () => {
    let noErrors = true;
    if (secondRender) {
      validateWebsite();
      if (
        productUrlStatus !== URL_STATUSES.ACTIVE_WEBSITE.STATUS &&
        productUrlStatus !== URL_STATUSES.UNSAFE_WEBSITE.STATUS &&
        productUrlStatus !== URL_STATUSES.INACTIVE_WEBSITE.STATUS
      ) {
        noErrors = false;
      }
    }
    if (landingPageUrl !== '' && landingPageUrl !== null) {
      if (
        landingUrlStatus !== URL_STATUSES.ACTIVE_WEBSITE.STATUS &&
        landingUrlStatus !== URL_STATUSES.UNSAFE_WEBSITE.STATUS &&
        landingUrlStatus !== URL_STATUSES.INACTIVE_WEBSITE.STATUS
      ) {
        noErrors = false;
      }
    }
    if (thirdPartyUrl !== '' && thirdPartyUrl !== null) {
      if (
        thirdPartyUrlStatus !== URL_STATUSES.ACTIVE_WEBSITE.STATUS &&
        thirdPartyUrlStatus !== URL_STATUSES.UNSAFE_WEBSITE.STATUS &&
        thirdPartyUrlStatus !== URL_STATUSES.INACTIVE_WEBSITE.STATUS
      ) {
        noErrors = false;
      }
    }
    return vali.validateAll(values, fields, setNewProductErrors, secondRender) && noErrors;
  };

  const onSubmit = async () => {
    setSecondRender(true);
    if (handleValidation() && !productList.includes(id)) {
      await handleCreateProduct();
      handleCancel();
    } else {
      setIsError(true);
      setErrorMessage('Please Double Check the Value in the Fields');
      if (productList.includes(id)) {
        const copy = { ...newProductErrors };
        copy.productId = 'Same ID has already been used';
        setNewProductErrors(copy);
      }
    }
  };

  useEffect(() => {
    const fetchTrackingSettings = async (): Promise<void> => {
      try {
        const trackingSettings = await fetchTrackingSettingsByProgramId({ id: hookWhoAmI?.programId ?? '' });
        setProgramTrackingSettings(trackingSettings);
      } catch (error) {
        setProgramTrackingSettings(null);
      }
    };

    getProductOptions();
    fetchTrackingSettings();
  }, []);

  useEffect(() => {
    setIsError(false);
  }, [gender, productCategory, landingPageUrl, description, id, productName, productUrl]);

  useEffect(() => {
    handleGetCountries();
  }, []);

  useEffect(() => {
    handleValidation();
  }, [secondRender]);

  useEffect(() => {
    handleValidation();
  }, [status, productCategory, gender, visibility, country, region]);

  useEffect(() => {
    if (!productUrl) {
      setProductUrlError('');
    }
    vali.validateUrlStatus(productUrl, setProductUrlStatus);
  }, [productUrl]);

  useEffect(() => {
    if (!landingPageUrl) {
      setLandingUrlError('');
    }
    vali.validateUrlStatus(landingPageUrl, setLandingUrlStatus);
  }, [landingPageUrl]);

  useEffect(() => {
    if (!thirdPartyUrl) {
      setThirdPartyUrlError('');
    }
    vali.validateUrlStatus(thirdPartyUrl, setThirdPartyUrlStatus);
  }, [thirdPartyUrl]);

  return {
    hookCancel: handleCancel,

    hookProductName: productName,
    hookSetProductName: setProductNameHandler,

    hookDescription: description,
    hookSetDescription: setDescriptionHandler,

    hookAges: ages,
    hookHandleAges: handleAges,

    hookId: id,
    hookSetId: setIdHandler,

    hookStatus: status,
    hookSetStatus: setStatusHandler,

    hookProductCategory: productCategory,
    hookSetProductCategory: setProductCategoryHandler,

    hookUploadImage: onUploadHandler,

    hookProductUrl: productUrl,
    hookLandingUrl: landingPageUrl,
    hookThirdPartyUrl: thirdPartyUrl,
    hookSetProductUrl: setProductUrlHandler,
    hookSetLandingPageUrl: setLandingPageUrlHandler,
    hookSetThirdPartyUrl: setThirdPartyUrlHandler,

    hookCountry: country,
    hookRegion: region,
    hookCountryOptions: countryOptions,
    hookRegionOptions: regionOptions,
    hookSetCountry: setCountryHandler,
    hookSetRegion: setRegionHandler,

    hookGender: gender,
    hookSetGender: setGender,

    hookVisibility: visibility,
    hookSetVisibility: setVisibilityHandler,

    hookPersonalIncome: personalIncome,
    hookHouseholdIncome: householdIncome,
    hookCreditScore: creditScore,
    hookSetPersonalIncome: setPersonalIncomeHandler,
    hookSetHouseholdIncome: setHouseholdIncomeHandler,
    hookSetCreditScore: setCreditScoreHandler,

    hookNotifyPublisher: notifyPublisher,
    hookSetNotifyPublisher: setNotifyPublisherHandler,

    hookNewProductErrors: newProductErrors,
    hookValidate: handleValidation,
    hookProductUrlError: productUrlError,
    hookLandingUrlError: landingUrlError,
    hookThirdPartyUrlError: thirdPartyUrlError,
    hookValidateWebsite: validateWebsite,
    hookOnSubmit: onSubmit,

    hookErrorMessage: errorMessage,
    hookIsError: isError,

    hookGetProductListLoading: getProductOptionsLoading,

    hookLoading: createProductLoading || getCountriesLoading,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
