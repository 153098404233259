// General App Imports
import { useLazyQuery, useMutation } from '@apollo/client';
import _, { size } from 'lodash';
import { useEffect, useState } from 'react';
import { AnyEventObject, SnapshotFrom } from 'xstate';

import { useToast, useUserInfo } from 'hooks';
// GraphQL Queries
import { CHECK_UNIQUE_RULE_NAME } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/graphql/queries/checkUniqueName';
import { LIST_AVAILABLE_RULE_GROUPS } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/graphql/queries/listAvailableRuleGroups';
// Custom Components
import { machine } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/machine';
import { CustomTextType } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddCustomTextComponent';
import { IndexedObject as IndexedObjectType } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/types';
import { TOAST_ERR_MESSAGES_NO_PAGE, useDebounce } from 'utils';

import { CREATE_FINTEL_MONITORING_CHECK_RULE } from '../graphql/mutations';
// Custom Enums
import { RuleStatusSettingsType } from '../../RuleStatusSettingsComponent/enums';
import { ERROR_MESSAGES, SUCCESS_MESSAGES, defaultRuleGroupOption } from '../enums';
// Custom Types
import { CustomTextListType, IndexedObject, CheckRuleGroup, CreateCheckRuleInputType } from '../types';

interface IUseAddMonitoringRuleType {
  // Overall Values
  cancelOpen: boolean;
  cancelButtonHandler: (state: boolean) => void;
  backButtonHandler: () => void;
  errorMessage: string;

  ruleName: string;
  setRuleName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  ruleNameError: string;

  // Check Rule Group
  checkRuleGroupsList: SelectOption[];
  setSelectedCheckRuleGroup: (newSelectedCheckRuleGroup: SelectOption) => void;
  setSelectedCheckRuleGroupHandler: (newSelectedCheckRuleGroup: SelectOption) => void;
  selectedCheckRuleGroup: SelectOption | undefined;
  customTextList: CustomTextListType[];
  setCustomTextList: (newCustomTextList: CustomTextListType[]) => void;
  addNewCustomText: () => void;
  updateCustomTextValue: (
    id: number,
    value: React.ChangeEvent<HTMLInputElement> & { nativeEvent: { inputType?: string } }
  ) => void;

  // Navigate
  validateUniqueRuleName: () => Promise<void>;
  checkUniqueNameLoading: boolean;

  // Navigate
  canAddMoreCustomText: boolean;

  removeCustomTextById: (id: number) => void;
  stepTwoErrors: IndexedObject;
  hookValidateInputFields: (newCustomTextList: CustomTextType[], sameValuesIds: number[][]) => boolean;
  onBlurCheck: () => void;

  // Step Four/Summary
  createRuleError: string;
  createRule: () => Promise<void>;
  createRuleLoading: boolean;
  // Leave Rule
  exitAddRuleModalHandler: () => void;

  // Rule status settings
  ruleStatusSettings: RuleStatusSettingsType;
  setRuleStatusSettings: (newRuleStatusSettings: RuleStatusSettingsType) => void;

  stateMachine: SnapshotFrom<typeof machine>;
}

export const useAddMonitoringRule = (
  setModalState: (state: boolean) => void,
  newRuleCreated: () => void,
  stateMachine: SnapshotFrom<typeof machine>,
  send: (event: AnyEventObject) => void
): IUseAddMonitoringRuleType => {
  // Global Constants
  const { hookWhoAmI } = useUserInfo();
  const { hookShowToast } = useToast();
  // Overall Values
  const [currentModal, setCurrentModal] = useState<number>(1);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [ruleName, setRuleName] = useState<string>('');
  const [ruleNameError, setRuleNameError] = useState<string>('');

  // Check Rule
  const [checkRuleGroupsList, setCheckRuleGroupsList] = useState<SelectOption[]>([]);
  const [selectedCheckRuleGroup, setSelectedCheckRuleGroup] = useState<SelectOption>();
  const [customTextList, setCustomTextList] = useState<CustomTextListType[]>([]);

  const [ruleStatusSettingsErrors, setRuleStatusSettingsErrors] = useState<IndexedObject>({});
  const [onBlurOn, setOnBlurOn] = useState<boolean>(false);
  const [canAddMoreCustomText, setCanAddMoreCustomText] = useState<boolean>(true);
  const [textCriteriaErrors, setTextCriteriaErrors] = useState<IndexedObjectType<string>>({});

  const [createRuleError, setCreateRuleError] = useState<string>('');
  // Cancel Modal State
  const [cancelOpen, setCancelOpen] = useState<boolean>(false);

  // Rule status settings
  const [ruleStatusSettings, setRuleStatusSettings] = useState<RuleStatusSettingsType>({
    fieldsRequired: null,
    fieldState: null,
    ruleState: null,
  });

  // Queries & Mutations
  const [checkUniqueName, { loading: checkUniqueNameLoading }] = useLazyQuery(CHECK_UNIQUE_RULE_NAME, {
    fetchPolicy: 'no-cache',
  });

  const [listAvailableGroupRules] = useLazyQuery(LIST_AVAILABLE_RULE_GROUPS);

  const [createMonitoringCheckRule, { loading: createNewCheckRuleLoading }] = useMutation(
    CREATE_FINTEL_MONITORING_CHECK_RULE
  );

  const debouncedRuleName = useDebounce(ruleName, 800);

  /*
   * Overall Handlers
   */
  const setCurrentModalHandler = (modalNum: number): void => {
    setCurrentModal(modalNum);
  };

  const getRuleGroupsValues = async (): Promise<void> => {
    setErrorMessage('');
    const { data, error } = await listAvailableGroupRules({
      variables: {
        input: {
          merchantId: hookWhoAmI?.companyId?.toString(),
        },
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessage(err.message);
      },
    });
    if (error) {
      setErrorMessage(error.message);
    }
    if (data && data.listAvailableRuleGroups.checkRuleGroups) {
      let checkRuleGroupOptions = [defaultRuleGroupOption];
      checkRuleGroupOptions = checkRuleGroupOptions.concat(
        data.listAvailableRuleGroups.checkRuleGroups.map((checkRuleGroup: CheckRuleGroup) => ({
          label: checkRuleGroup.groupName,
          value: checkRuleGroup.groupName,
        }))
      );
      setCheckRuleGroupsList(checkRuleGroupOptions);
    }
  };

  const cancelButtonHandler = (state: boolean): void => {
    setCancelOpen(state);
  };

  const backButtonHandler = (): void => {
    setCurrentModalHandler(currentModal - 1);
  };

  /*
   * Cancel Modal Handlers and Logic
   */
  const exitAddRuleModalHandler = (): void => {
    cancelButtonHandler(false);
    setModalState(false);
    setCurrentModal(1);
    setCanAddMoreCustomText(true);
    send({ type: 'AddMonitoringRule.cancel' });
  };

  /*
   * Step One Handlers & Logic
   */
  const setRuleNameHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setRuleNameError('');
    setRuleName(e.target.value);
    send({ type: ':ruleName', ruleName: e.target.value });
  };

  const setSelectedCheckRuleGroupHandler = (newSelectedCheckRuleGroup: SelectOption): void => {
    setSelectedCheckRuleGroup(newSelectedCheckRuleGroup);
    send({ type: ':ruleGroup', ruleGroup: newSelectedCheckRuleGroup.value });
  };

  const validateUniqueRuleName = async (): Promise<void> => {
    const { data } = await checkUniqueName({
      variables: {
        input: {
          merchantId: hookWhoAmI?.companyId?.toString(),
          ruleName,
        },
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessage(TOAST_ERR_MESSAGES_NO_PAGE(err.message));
      },
    });
    if (data && data.checkUniqueRuleName.unique === true) {
      setRuleNameError('');
      send({ type: ':ruleNameUnique', ruleNameUnique: true });
    } else {
      setRuleNameError(ERROR_MESSAGES.RULE_NAME_TAKEN);
      setCurrentModal(1);
      send({ type: ':ruleNameUnique', ruleNameUnique: true });
    }
  };

  const validateInputFields = (newCustomTextList: CustomTextListType[], sameValuesIds: number[][]): boolean => {
    const errors: IndexedObject = {};
    const targetCustomTextList = size(newCustomTextList) !== 0 ? newCustomTextList : customTextList;
    targetCustomTextList.forEach((r) => {
      if (!r.value) {
        errors[r.id] = ERROR_MESSAGES.MISSING_VALUE;
      }
    });
    setTextCriteriaErrors(errors);

    if (size(sameValuesIds) > 0) {
      sameValuesIds[0].forEach((r) => {
        errors[r] = ERROR_MESSAGES.UNIQUE_VALUE;
      });
      setTextCriteriaErrors(errors);
    }

    if (Object.keys(errors).length > 0) return false;
    return true;
  };

  const addNewCustomText = (): void => {
    if (customTextList.length >= 4) {
      setCanAddMoreCustomText(false);
    }
    const newCustomTextList: CustomTextListType = {
      id: customTextList ? customTextList.reduce((highest, curr) => (highest > curr.id ? highest : curr.id), 0) + 1 : 1,
      value: undefined,
    };

    setCustomTextList([...customTextList, newCustomTextList]);
    if (!validateInputFields([], [])) {
      setOnBlurOn(true);
    }
  };

  const removeCustomTextById = (id: number): void => {
    const newCustomTextListLocal = customTextList.filter((customText) => customText.id !== id);
    setCustomTextList(newCustomTextListLocal);
    setCanAddMoreCustomText(true);

    if (newCustomTextListLocal.length >= 5) {
      setCanAddMoreCustomText(false);
    } else {
      setCanAddMoreCustomText(true);
    }
    validateInputFields(newCustomTextListLocal, []);
  };

  /**
   * Validate the custom text values input fields
   * @param {CustomTextType[]} newCustomTextList - The new custom text list
   * @param {boolean} skipEmptyArrayCheck - flag to determine if the empty array check should be skipped
   * @returns {boolean} - The validation result
   */
  const validateTextValuesInputFields = (newCustomTextList: CustomTextType[], skipEmptyArrayCheck = false): boolean => {
    const errors: IndexedObjectType<string> = {};

    const targetCustomTextList =
      _.size(newCustomTextList) !== 0 || skipEmptyArrayCheck ? newCustomTextList : customTextList;
    targetCustomTextList.forEach((r) => {
      if (!r.value) {
        errors[r.id] = ERROR_MESSAGES.MISSING_VALUE;
      }
    });

    const uniqueValues = _.uniqBy(targetCustomTextList, 'value');
    if (uniqueValues.length !== targetCustomTextList.length) {
      targetCustomTextList.forEach((r) => {
        if (uniqueValues.filter((u) => u.value === r.value && u.id !== r.id).length) {
          errors[r.id] = errors[r.id] || ERROR_MESSAGES.UNIQUE_VALUE;
        }
      });
    }
    setTextCriteriaErrors(errors);

    const hasTextErrors = Object.keys(errors).length > 0;
    send({ type: ':hasTextErrors', hasTextErrors });

    return !hasTextErrors;
  };

  /**
   * Update the custom text value
   * @param id - id of the custom text to be updated
   * @param value - the new value of the custom text
   */
  const updateCustomTextValue = (
    id: number,
    value: React.ChangeEvent<HTMLInputElement> & { nativeEvent: { inputType?: string } }
  ): void => {
    const errors: IndexedObjectType<string> = {};

    const { inputType } = value.nativeEvent;
    const regex = /^[\w\d.,/`?;:*&#_$%()+@'" -]+$/;
    const regval = regex.test(value.target.value);
    if (!regval && inputType !== 'deleteContentBackward') {
      errors[id] = ERROR_MESSAGES.INVALID_CHAR;
      setTextCriteriaErrors(errors);
      return;
    }

    setTextCriteriaErrors({});

    const newCustomTextList = customTextList.map((customText): CustomTextType => {
      if (customText.id === id) {
        return {
          ...customText,
          value: value.target.value,
        };
      }
      return customText;
    });

    validateTextValuesInputFields(newCustomTextList);

    setCustomTextList(newCustomTextList);
  };

  const onBlurCheck = (): void => {
    if (onBlurOn) validateInputFields([], []);
  };

  const validateRuleStatusSettings = (compareWith: string | null): boolean => {
    const errors: IndexedObject = {};

    // Validates Rule Status values
    if (ruleStatusSettings.fieldsRequired === compareWith) {
      errors.fieldsRequired = ERROR_MESSAGES.MISSING_VALUE;
    }
    if (ruleStatusSettings.fieldState === compareWith) {
      errors.fieldState = ERROR_MESSAGES.MISSING_VALUE;
    }
    if (ruleStatusSettings.ruleState === compareWith) {
      errors.ruleState = ERROR_MESSAGES.MISSING_VALUE;
    }
    setRuleStatusSettingsErrors(errors);

    if (errors.fieldsRequired || errors.fieldState || errors.ruleState) {
      return false;
    }
    return true;
  };

  /*
   * Step 4 (New Rule Summary) Handlers & Logic
   */
  const createRuleHandler = async (): Promise<void> => {
    setCreateRuleError('');
    const textList = Array.isArray(stateMachine.context.textCriteria)
      ? stateMachine.context.textCriteria.map((customText) => customText.value || '')
      : [];
    const newRuleInput: CreateCheckRuleInputType = {
      merchantId: hookWhoAmI?.companyId?.toString() || '',
      customTextList: textList,
      ruleName: stateMachine.context.ruleName,
      ruleGroup: stateMachine.context.ruleGroup,
      ruleStatusSettings: stateMachine.context.ruleStatusSettings,
    };

    const { errors } = await createMonitoringCheckRule({
      variables: {
        input: newRuleInput,
      },
      onError(err) {
        setCreateRuleError(TOAST_ERR_MESSAGES_NO_PAGE(err.message));
      },
    });
    if (errors) {
      setCreateRuleError(TOAST_ERR_MESSAGES_NO_PAGE(errors[0].message));
    } else {
      hookShowToast(SUCCESS_MESSAGES.CREATED_RULE_TOAST);
      exitAddRuleModalHandler();

      // Clear Cache after update as it is no longer valid.
      newRuleCreated();
    }
  };

  const handleSetRuleStatusSettings = (newRuleStatusSettings: RuleStatusSettingsType): void => {
    setRuleStatusSettings(newRuleStatusSettings);
    if (stateMachine.matches({ AddMonitoringRule: 'RuleCriteria' })) {
      send({ type: ':ruleStatusSettings', ruleStatusSettings: newRuleStatusSettings });
    }
  };

  useEffect(() => {
    if (stateMachine.matches({ AddMonitoringRule: 'NameRuleGroup' })) {
      if (debouncedRuleName) {
        validateUniqueRuleName();
      }
    }
  }, [debouncedRuleName]);

  /**
   * Validate the rule status settings
   */
  useEffect(() => {
    validateRuleStatusSettings('');
  }, [stateMachine.context.ruleStatusSettings]);

  /**
   * State Machine validation for Text Criteria custom text
   */
  useEffect(() => {
    if (stateMachine.matches({ AddMonitoringRule: 'RuleCriteria' })) {
      send({ type: ':textCriteria', textCriteria: customTextList });
    }
  }, [customTextList]);

  /**
   * State Machine errors for Text Criteria custom text
   */
  useEffect(() => {
    if (stateMachine.matches({ AddMonitoringRule: 'RuleCriteria' })) {
      send({ type: ':hasTextErrors', hasTextErrors: Object.keys(textCriteriaErrors).length > 0 });
    }
  }, [textCriteriaErrors]);

  /**
   * Get the values for Step One dropdowns
   */
  useEffect(() => {
    getRuleGroupsValues();
  }, []);

  return {
    // Overall Values
    cancelOpen,
    cancelButtonHandler,
    backButtonHandler,
    errorMessage,

    ruleName,
    setRuleName: setRuleNameHandler,
    ruleNameError,

    // Check Rule Group
    checkRuleGroupsList,
    setSelectedCheckRuleGroup,
    setSelectedCheckRuleGroupHandler,
    selectedCheckRuleGroup,
    customTextList,
    setCustomTextList,
    addNewCustomText,
    updateCustomTextValue,

    // Navigate
    validateUniqueRuleName,
    checkUniqueNameLoading,

    // Navigate
    canAddMoreCustomText,

    removeCustomTextById,
    stepTwoErrors: ruleStatusSettingsErrors,
    hookValidateInputFields: validateInputFields,
    onBlurCheck,

    // Step Four/Summary
    createRuleError,
    createRule: createRuleHandler,
    createRuleLoading: createNewCheckRuleLoading,
    // Leave Rule
    exitAddRuleModalHandler,

    // Rule status settings
    ruleStatusSettings,
    setRuleStatusSettings: handleSetRuleStatusSettings,

    stateMachine,
  };
};
