import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { PRODUCT_URL_ERROR, FLOAT_ERROR, path, MERCHANT_PREFIX } from '../../../../../../utils';
import { UPDATE_PRODUCT } from '../../graphql/mutations/updateProduct';
import { useUpload } from '../../../../../../hooks';
import { Permission } from '../../../../../../entities';
import { toUTC } from '../../../../../../utils/formatCommissions';

import { useProductFeed } from './useProductFeed';
import { useProductInformation } from './useProductInformation';
import { useCommissions } from './useCommision';

export const useEditProduct = (permissionsCodeList: string[] = []) => {
  const location = useLocation();
  const navigate = useNavigate();
  const product = location.state;
  const [checked, setIsChecked] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorDisplay, setErrorDisplay] = useState(PRODUCT_URL_ERROR);
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(true);
  const [updateProduct, { loading: updateProductLoading }] = useMutation(UPDATE_PRODUCT);
  const [geoStatesGlobal, setGeoStatesGlobal] = useState(product.targetDemographics.geographicalStates);

  const handleCancel = () => {
    setIsUpdateDisabled(false);
    navigate(-1);
  };

  const handleSetIsChecked = () => {
    setIsUpdateDisabled(false);
    setIsChecked(!checked);
  };

  const handleIsUpdateDisabled = (value: boolean) => {
    setIsUpdateDisabled(value);
    setIsError(false);
  };

  const geoStatesHandlerProductInformation = (input: SelectOption[]) => {
    setIsUpdateDisabled(false);
    setGeoStatesGlobal(input.map((obj: any) => obj.label));
  };

  const geoStatesHandlerProductFeed = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setGeoStatesGlobal(event.target.value.split(','));
    setIsUpdateDisabled(false);
  };

  const productFeed = useProductFeed(
    product.productFeedSection,
    handleIsUpdateDisabled,
    product.productCategory,
    geoStatesGlobal,
    updateProductLoading,
    geoStatesHandlerProductFeed
  );
  const productInformation = useProductInformation(
    product,
    handleIsUpdateDisabled,
    updateProductLoading,
    geoStatesHandlerProductInformation,
    geoStatesGlobal
  );
  const commissionsHook = useCommissions(product?.commissions);
  const useUploadHook = useUpload();

  const noNull = (value: any) => {
    if (value === null) return '';
    return value;
  };

  const handleEditProduct = async () => {
    let productImageUrl = { name: '', key: '', url: '' };
    if (productInformation.hookImageFile.file !== undefined)
      productImageUrl = await useUploadHook.hookUploadImageFile(productInformation.hookImageFile.file);
    const geoStates = productInformation.hookTargetStates.map((obj: any) => obj.label);

    const validUntil = toUTC(productFeed.hookValidValue);
    const { data } = await updateProduct({
      variables: {
        input: {
          id: noNull(product?.id),
          productImageUrl: noNull(productImageUrl.url || product.productImageUrl || ''),
          programId: noNull(product?.program?.id),
          customizedProductId: noNull(productInformation.hookProductId),
          name: noNull(productInformation.hookProductName),
          description: noNull(productInformation.hookDescription),
          status: noNull(productInformation.hookStatus.label),
          productUrl: noNull(productInformation.hookProductUrl),
          landingPage: noNull(productInformation.hookLandingPage),
          thirdPartyUrl: noNull(productInformation.hookThirdUrl),
          targetDemographics: {
            gender: noNull(productInformation.hookGender.label),
            geographicalCountry: noNull(productInformation.hookTargetCountry.label || ''),
            geographicalStates: geoStates.length === 1 && geoStates[0] === '' ? null : geoStates,
            age: noNull(productInformation.hookAges.filter((obj) => obj.checked).map((obj) => obj.label)),
            commissionVisibility: noNull(productInformation.hookVisibility.value),
            minimumIncome: noNull(productInformation.hookMininmumIncome),
            minimumHouseHoldIncome: noNull(productInformation.hookMinimumHouseHold),
            minimiumCreditScore: noNull(productInformation.hookMinimumCredit),
          },
          productFeedSection: {
            validUntil,
            cardNetwork: productFeed.hookCardNetwork.filter((obj: any) => obj.checked).map((obj: any) => obj.label),
            userTypes: noNull(productFeed.hookUserTypes.filter((obj: any) => obj.checked).map((obj: any) => obj.label)),
            annualFee: noNull(productFeed.hookAnnualFee),
            monthlyFee: noNull(productFeed.hookMonthlyFee),
            signupBonus: noNull(productFeed.hookSignUp),
            supplementaryCardFee: noNull(productFeed.hookCardFee),
            balanceTransferIntroRate: noNull(productFeed.hookIntroRate),
            balanceTransferRegular: noNull(productFeed.hookBalanceRegular),
            interestRate: noNull(productFeed.hookInterestRate),
            rewardsEarningRate: noNull(productFeed.hookRewardsValue),
            annualInterestRate: noNull(productFeed.hookAnnualRate),
            balanceTransferIntroFee: noNull(productFeed.hookBalanceIntro),
            balanceTransferFee: noNull(productFeed.hookBalanceTransferFee),
            cashAdvanceFee: noNull(productFeed.hookCash),
            foreignCurrencyConversion: noNull(productFeed.hookFCC),
            dishonouredPaymentFee: noNull(productFeed.hookDishonoured),
            gracePeriod: noNull(productFeed.hookGrace),
            marketingItems: noNull(productFeed.hookMarketingItems),
            legalReferenceItems: noNull(productFeed.hookLegalReferenceItems),
            legalReference: noNull(productFeed.hookLegalValue),
            promoInterestRate: noNull(productFeed.hookPromo),
            transactionFeeATM: noNull(productFeed.hookTransAtm),
            transactionFeeETR: noNull(productFeed.hookTransET),
            transactionFeeInternal: noNull(productFeed.hookTransInternal),
            freeTransactionVolume: noNull(productFeed.hookFree),
            closingBalanceToWaiveMonthlyFee: noNull(productFeed.hookClosingBalance),
            noMinimumBalance: productFeed.hookMinBalanceChecked,
            term: noNull(productFeed.hookTerm),
            bankingProductInsurance: noNull(productFeed.hookInsurance),
            redeemable: noNull(productFeed.hookRedeemable.label),
            insurer: noNull(productFeed.hookInsurer),
            minimumAccountBalance:
              product.productCategory === 'Banking'
                ? noNull(product.minimumAccountBalance)
                : noNull(productFeed.hookMinBalance),
            managementFee: noNull(productFeed.hookManFees),
            tradeCost: noNull(productFeed.hookTrade),
            numberOfFreeMonthlyTrade: noNull(productFeed.hookMonthlyTrades),
            mer: noNull(productFeed.hookMer),
            coverageAmount: noNull(productFeed.hookCoverage),
            guaranteedPremiums: noNull(productFeed.hookGuaranteed),
            exclusionItems: noNull(productFeed.hookExclusion),
            productUsageGuidelines: noNull(productFeed.hookUsageValue),
            productWelcomeOffers: noNull(productFeed.hookWelcomeOffer),
            minimumOpenDeposit: noNull(productFeed.hookMinimumOpen),
            interestPaid: noNull(productFeed.hookInterestPaid?.label),
            interestPaidDays: noNull(productFeed.hookInterestPaidDays),
            interestCalculated: noNull(productFeed.hookInterestCalculated?.label),
            interestCalculatedDays: noNull(productFeed.hookInterestCalculatedDays),
            apyTiers: noNull(productFeed.hookApyDetails),
            apyRate: noNull(productFeed.hookApy),
            mortgageType: noNull(productFeed.hookMortgageType?.label),
            openMortgageType: noNull(productFeed.hookOpen?.label),
            convertibleMortgageType: noNull(productFeed.hookConvertible?.label),
            preapprovalMortgageType: noNull(productFeed.hookPre?.label),
            mortgageRateType: noNull(productFeed.hookRateType?.label),
            mortgageTypeDiscountedRate: noNull(product.mortgageTypeDiscountedRate),
            mortgageAprRate: noNull(productFeed.hookApr),
            mortgageTermYear: noNull(productFeed.hookMortgageTerm),
            monthlyFeesDetails: noNull(productFeed.hookMonthlyDetails),
            balanceTransferRegularDetails: noNull(productFeed.hookBalanceDetails),
            interestRateDetails: noNull(productFeed.hookInterestRateDetails),
            AnnualInterestRateDetails: noNull(productFeed.hookAnnualDetails),
            bankingMinimumBalanceRate:
              product.productCategory === 'Banking'
                ? noNull(productFeed.hookMinBalance)
                : noNull(product.bankingMinimumBalanceRate),
          },
          productCategory: noNull(productInformation.hookCategory.label),
          subCategories: noNull(
            productInformation.hookSubCategory.filter((obj: any) => obj.checked).map((obj) => obj.name)
          ),
          shouldNotify: checked,
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (data.updateProduct === null) {
      setErrorDisplay('Failed to Update Product');
    }
  };

  const updatehandler = async () => {
    const prodFeed = productFeed.hookHandleUpdateProduct();
    const prodInfo = productInformation.hookhandleUpdateProduct();
    if (prodFeed && prodInfo) {
      await handleEditProduct();
      navigate(`${MERCHANT_PREFIX}${path.productCatalog.href}`);
    } else if (!prodInfo) {
      setErrorDisplay(PRODUCT_URL_ERROR);
      setIsError(true);
    } else {
      setErrorDisplay(FLOAT_ERROR);
      setIsError(true);
    }
  };

  return {
    hookIsUpdateDisabled: isUpdateDisabled,

    hookProductInformation: productInformation,

    hookProductFeed: productFeed,

    hookUpdateDisabled: isUpdateDisabled,

    hookIsChecked: checked,

    hookIsError: isError,

    hookErrorDisplay: errorDisplay,

    hookProductCategory: product.productCategory,

    hookProductId: product.productId,

    hookUpdateLoading: updateProductLoading,

    hookCommissionshook: commissionsHook,

    hookUpdateHandler: updatehandler,

    hookHandleCancel: handleCancel,

    hookhandleSetIsChecked: handleSetIsChecked,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
