import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useProgramsApi } from 'api/hooks/useProgramsApi';
import { IProgramTrackingSettings } from 'api/types';
import { findQueryParamsDuplicates, interpolate } from 'helpers';

import { useToast, useUserInfo } from '../../../../../hooks';
import {
  ERROR_TYPES,
  formatterForOptions,
  MERCHANT_PREFIX,
  path,
  URL_STATUSES,
  SUCCESS_MESSAGES,
} from '../../../../../utils';
import { useValidation } from '../../../../../utils/validation';
import { CREATE_CAMPAIGN } from '../graphql/mutations';
import { CAMPAIGN_OPTION } from '../graphql/queries';
import { Permission } from '../../../../../entities';
import en from '../locales/en.json';

type Option = {
  label: string;
  value: string;
};

type CheckBoxOption = {
  label: string;
  value: string;
  checked: boolean;
};

export const useCreateCampaign = (permissionsCodeList: string[] = []) => {
  const { hookShowToast } = useToast();
  const { hookWhoAmI } = useUserInfo();
  const navigate = useNavigate();
  const location = useLocation();
  const vali = useValidation();

  const [campaignName, setCampaignName] = useState('');
  const [description, setDescription] = useState('');

  const [status, setStatus] = useState<Option>();
  const [statusOptions, setStatusOptions] = useState<Option[]>([]);

  const [startDate, setStartDate] = useState<Date>();
  const [startCalendarOpen, setStartCalendarOpen] = useState(false);

  const [endDate, setEndDate] = useState<Date>();
  const [endCalendarOpen, setEndCalendarOpen] = useState(false);

  const [tags, setTags] = useState<Option[]>([]);
  const [tagsOptions, setTagOptions] = useState<Option[]>([]);

  const [products, setProducts] = useState<Option>();
  const [productsOptions, setProductsOptions] = useState<Option[]>([]);

  const [defaultLandingPage, setDefaultLandingPage] = useState('');
  const [urlPlaceholder, setUrlPlaceholder] = useState('');

  const [language, setLanguage] = useState<Option>({ label: 'English', value: 'English' });
  const [languageOptions, setLanguageOptions] = useState<Option[]>([]);

  const [thirdPartyTracking, setThirdPartyTracking] = useState('');

  const [publisherGroups, setPublisherGroups] = useState<string[]>([]);
  const [publisherGroupOptions, setPublisherGroupOptions] = useState<CheckBoxOption[]>([]);
  const [publisherGroupBackup, setPublisherGroupBackup] = useState<CheckBoxOption[]>([]);

  const [secondRender, setSecondRender] = useState(false);
  const [createCampaignErrors, setCreateCampaignErrors] = useState<{ [key: string]: string }>({});
  const [landingUrlError, setLandingUrlError] = useState('');
  const [thirdPartyUrlError, setThirdPartyUrlError] = useState('');
  const [landingUrlStatus, setLandingUrlStatus] = useState('');
  const [thirdPartyUrlStatus, setThirdPartyUrlStatus] = useState('');
  const [validated, setValidated] = useState(true);

  const [programTrackingSettings, setProgramTrackingSettings] = useState<IProgramTrackingSettings | null>(null);

  const { fetchTrackingSettingsByProgramId } = useProgramsApi();

  const [createCampaign, { loading: createCampaignLoading }] = useMutation(CREATE_CAMPAIGN);

  const [getOptions, { loading: getOptionsLoading }] = useLazyQuery(CAMPAIGN_OPTION);

  const setCampaignNameHandler = (e: any) => {
    setCampaignName(e?.target ? e.target.value : e);
  };

  const setDescriptionHandler = (e: any) => {
    setDescription(e?.target ? e.target.value : e);
  };

  const setStatusHandler = (value: Option) => {
    setStatus(value);
  };

  const onApplyStartCalendarHandler = (start: Date) => {
    setStartDate(start);
    setStartCalendarOpen(false);
  };

  const onCancelStartCalendarHandler = () => {
    setStartCalendarOpen(false);
    setStartDate(startDate || new Date());
  };

  const onOpenStartCalendarHandler = () => {
    setStartCalendarOpen(true);
  };

  const onApplyEndCalendarHandler = (end: Date) => {
    setEndDate(end);
    setEndCalendarOpen(false);
  };

  const onCancelEndCalendarHandler = () => {
    setEndCalendarOpen(false);
    setEndDate(undefined);
  };

  const onOpenEndCalendarHandler = () => {
    setEndCalendarOpen(true);
  };

  const setTagsHandler = (value: Option[]) => {
    setTags(value);
  };

  const setProductHandler = (value: Option) => {
    setProducts(value);
  };

  const setDefaultLandingPageHandler = (e: any) => {
    setDefaultLandingPage(e?.target ? e.target.value : e);
  };

  const setLanguageHandler = (value: Option) => {
    setLanguage(value);
  };

  const setThirdPartyTrackingHandler = (e: any) => {
    setThirdPartyTracking(e?.target ? e.target.value : e);
  };

  const setPublisherGroupCheckedHandler = (value: CheckBoxOption) => {
    const newPublisherGroupOptions = publisherGroupOptions.map((option) => {
      if (option.value === value.value) {
        return {
          ...option,
          checked: !option.checked,
        };
      }
      return option;
    });

    const selectedPublisherGroups = newPublisherGroupOptions
      .filter((option) => option.checked)
      .map((option) => option.value);
    setPublisherGroups(selectedPublisherGroups);
    setPublisherGroupOptions(newPublisherGroupOptions);
  };

  const clearForm = () => {
    setCampaignName('');
    setDescription('');
    setStatus(statusOptions[0]);
    setStartDate(undefined);
    setEndDate(undefined);
    setTags([]);
    setProducts(undefined);
    setDefaultLandingPage('');
    setLanguage({ label: 'English', value: 'English' });
    setThirdPartyTracking('');
    setPublisherGroups([]);
    setPublisherGroupOptions(publisherGroupBackup);
    setSecondRender(false);
  };

  const getOptionsHandler = async () => {
    const { data } = await getOptions({
      variables: {
        input: {
          merchantId: hookWhoAmI.companyId?.toString() || location.state.companyId.toString(),
          programId: hookWhoAmI.programId || location.state.programId,
        },
      },
      fetchPolicy: 'no-cache',
    });
    setLanguageOptions(formatterForOptions(data.getCreateCampaignOptions.languages, 'language', 'language'));
    setProductsOptions(formatterForOptions(data.getCreateCampaignOptions.products, 'name', 'id'));
    setStatusOptions(formatterForOptions(data.getCreateCampaignOptions.campaignStatusTypes, 'type', 'type'));
    setTagOptions(formatterForOptions(data.getCreateCampaignOptions?.tags, 'name', 'id'));
    setUrlPlaceholder(data.getCreateCampaignOptions.merchant.companyUrl);
    const groupsFormatted = data.getCreateCampaignOptions?.publisherGroups.map((item: any) => ({
      label: item.name,
      value: item.id,
      checked: false,
    }));
    setPublisherGroupBackup(groupsFormatted);
    setPublisherGroupOptions(groupsFormatted);
  };

  const values: { [key: string]: string } = {
    campaignName,
    status: status?.label || '',
    description,
    language: language?.label || '',
    startDate: startDate?.toString() || '',
    endDate: endDate?.toString() || '',
  };

  const fields = {
    campaignName: ERROR_TYPES.NOT_EMPTY,
    status: ERROR_TYPES.SELECTION_REQUIRED,
    description: ERROR_TYPES.NOT_EMPTY,
    language: ERROR_TYPES.SELECTION_REQUIRED,
    startDate: ERROR_TYPES.SELECTION_REQUIRED,
    endDate: ERROR_TYPES.END_DATE,
  };

  const isParamsDuplicatesLandingPage = (): boolean => {
    try {
      const queryParamDuplicates = findQueryParamsDuplicates(
        defaultLandingPage,
        programTrackingSettings?.urlQueryString || ''
      );

      if (queryParamDuplicates.length > 0) {
        setLandingUrlError(
          interpolate(en.errors.validation.landingPageUrl.queryParamDuplicates, {
            duplicates: queryParamDuplicates.join(', '),
          })
        );
        setLandingUrlStatus(URL_STATUSES.INVALID_WEBSITE.STATUS);
        return true;
      }

      return false;
    } catch (err) {
      console.error(`Error in parsing the URL: ${defaultLandingPage}`);
      return false;
    }
  };

  const validateWebsite = () => {
    if (defaultLandingPage !== '' && defaultLandingPage !== null) {
      if (isParamsDuplicatesLandingPage()) return;

      vali.validateUrlStatus(defaultLandingPage, setLandingUrlStatus);
      vali.renderUrlCheck(landingUrlStatus, setLandingUrlError, true);
    }
    if (thirdPartyTracking !== '' && thirdPartyTracking !== null) {
      vali.validateUrlStatus(thirdPartyTracking, setThirdPartyUrlStatus);
      vali.renderUrlCheck(thirdPartyUrlStatus, setThirdPartyUrlError, true);
    }
  };

  const handleValidation = () => {
    let noErrors = true;

    if (defaultLandingPage !== '' && defaultLandingPage !== null) {
      validateWebsite();
      if (
        landingUrlStatus !== URL_STATUSES.ACTIVE_WEBSITE.STATUS &&
        landingUrlStatus !== URL_STATUSES.UNSAFE_WEBSITE.STATUS &&
        landingUrlStatus !== URL_STATUSES.INACTIVE_WEBSITE.STATUS
      ) {
        noErrors = false;
      }
    }
    if (thirdPartyTracking !== '' && thirdPartyTracking !== null) {
      validateWebsite();
      if (
        thirdPartyUrlStatus !== URL_STATUSES.ACTIVE_WEBSITE.STATUS &&
        thirdPartyUrlStatus !== URL_STATUSES.UNSAFE_WEBSITE.STATUS &&
        thirdPartyUrlStatus !== URL_STATUSES.INACTIVE_WEBSITE.STATUS
      ) {
        noErrors = false;
      }
    }
    return vali.validateAll(values, fields, setCreateCampaignErrors, secondRender) && noErrors;
  };

  const createAdCampaignHandler = async () => {
    setSecondRender(true);
    const validate = handleValidation();
    setValidated(validate);
    if (!validate) return;

    const formattedTags = tags.map((tag) => tag?.label);
    const { data, errors } = await createCampaign({
      variables: {
        input: {
          name: campaignName,
          description,
          startDate: startDate?.toUTCString(),
          endDate: endDate?.toUTCString() || undefined,
          defaultLandingPage: defaultLandingPage || undefined,
          thirdPartyUrl: thirdPartyTracking || undefined,
          language: language?.value,
          productId: products?.value || undefined,
          status: status?.value,
          programId: hookWhoAmI.programId,
          publisherGroupIds: publisherGroups || undefined,
          tags: formattedTags || undefined,
        },
      },
    });

    if (errors) {
      hookShowToast('Error creating campaign');
      return;
    }

    if (location?.state?.adId && data) {
      navigate(`${hookWhoAmI.companyId ? MERCHANT_PREFIX : ''}${path.editAd.href}`, {
        state: {
          adId: location.state.adId,
          campaignName: data.createNewCampaign.name,
          campaignId: data.createNewCampaign.id,
        },
      });
    } else {
      navigate(`${MERCHANT_PREFIX}${path.campaignManagement.href}`);
    }

    if (data) {
      hookShowToast(SUCCESS_MESSAGES.CAMPAIGN_CREATE(data?.createNewCampaign.name));
      clearForm();
    }
  };

  useEffect(() => {
    const fetchTrackingSettings = async (): Promise<void> => {
      try {
        const trackingSettings = await fetchTrackingSettingsByProgramId({
          id: hookWhoAmI?.programId || location.state.programId || '',
        });
        setProgramTrackingSettings(trackingSettings);
      } catch (error) {
        setProgramTrackingSettings(null);
      }
    };

    getOptionsHandler();
    fetchTrackingSettings();
  }, []);

  useEffect(() => {
    if (defaultLandingPage === '') {
      setLandingUrlError('');
    }
    vali.validateUrlStatus(defaultLandingPage, setLandingUrlStatus);
  }, [defaultLandingPage]);

  useEffect(() => {
    if (thirdPartyTracking === '') {
      setThirdPartyUrlError('');
    }
    vali.validateUrlStatus(thirdPartyTracking, setThirdPartyUrlStatus);
  }, [thirdPartyTracking]);

  useEffect(() => {
    if (secondRender) {
      handleValidation();
    }
  }, [secondRender, startDate, endDate, status, language]);

  return {
    hookWhoAmI,
    hookCampaignName: campaignName,
    hookSetCampaignName: setCampaignNameHandler,

    hookDescription: description,
    hookSetDescription: setDescriptionHandler,

    hookStatus: status,
    hookStatusOptions: statusOptions,
    hookSetStatus: setStatusHandler,

    hookStartDate: startDate,
    hookStartCalendarOpen: startCalendarOpen,
    hookOnOpenStartCalendar: onOpenStartCalendarHandler,
    hookOnApplyStartCalendar: onApplyStartCalendarHandler,
    hookOnCancelStartCalendar: onCancelStartCalendarHandler,

    hookEndDate: endDate,
    hookEndCalendarOpen: endCalendarOpen,
    hookOnOpenEndCalendar: onOpenEndCalendarHandler,
    hookOnApplyEndCalendar: onApplyEndCalendarHandler,
    hookOnCancelEndCalendar: onCancelEndCalendarHandler,

    hookTags: tags,
    hookTagsOptions: tagsOptions,
    hookSetTags: setTagsHandler,

    hookProducts: products,
    hookProductsOptions: productsOptions,
    hookSetProducts: setProductHandler,

    hookDefaultLandingPageUrl: defaultLandingPage,
    hookUrlPlaceholder: urlPlaceholder,
    hookSetDefaultLandingPageUrl: setDefaultLandingPageHandler,

    hookLanguage: language,
    hookLanguageOptions: languageOptions,
    hookSetLanguage: setLanguageHandler,

    hookThirdPartyTrackingUrl: thirdPartyTracking,
    hookSetThirdPartyTrackingUrl: setThirdPartyTrackingHandler,

    hookPublisherGroups: publisherGroups,
    hookSetPublisherGroup: setPublisherGroups,

    hookPublisherGroupOptions: publisherGroupOptions,
    hookSetPublisherGroupOptions: setPublisherGroupOptions,
    hookSetPublisherGroupHandler: setPublisherGroupCheckedHandler,

    hookPublisherGroupBackup: publisherGroupBackup,

    hookCreateCampaignErrors: createCampaignErrors,
    hookLandingUrlError: landingUrlError,
    hookThirdPartyUrlError: thirdPartyUrlError,
    hookValidated: validated,
    hookSetSecondRender: setSecondRender,
    hookSetValidated: setValidated,
    hookHandleValidation: handleValidation,
    hookValidateWebsite: validateWebsite,

    hookLoading: getOptionsLoading || createCampaignLoading,
    hookSubmitCampaign: createAdCampaignHandler,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
