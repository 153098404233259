import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useToast, useUserInfo } from 'hooks';
import { MERCHANT_PREFIX, path, URL_STATUSES } from 'utils';
import { useValidation } from 'utils/validation';

import { CHECK_TYPES_OPTIONS, URLS_CATEGORIES_OPTIONS, URLS_LIST } from '../../UrlsList/enums';
import { URL_UPLOAD } from '../enums';
import { CREATE_BULK_MANUAL_URLS } from '../graphql/mutations/createBulkManualUrls';

export const useUrlBulkUpload = () => {
  const validator = useValidation();
  const navigate = useNavigate();
  const { hookWhoAmI } = useUserInfo();
  const { hookShowToast } = useToast();
  const [urlsToBeUploaded, setUrlsToBeUploaded] = useState<string[]>([]);
  const [urlsToBeUploadedLoading, setUrlsToBeUploadedLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [category, setCategory] = useState<SelectOption>(URLS_CATEGORIES_OPTIONS[0]);
  const [categoryError, setCategoryError] = useState<string>('');
  const [checkType, setCheckType] = useState<SelectOption>(CHECK_TYPES_OPTIONS[0]);
  const [checkTypeError, setCheckTypeError] = useState<string>('');

  // confirmation modal
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);

  // Mutation
  const [createBulkManualUrls, { loading: createBulkManualUrlsLoading }] = useMutation(CREATE_BULK_MANUAL_URLS);

  const validateUrl = async (url: string): Promise<string | undefined> => {
    const urlStatus = await validator.validateUrlStatus(url);
    return urlStatus !== URL_STATUSES.INVALID_WEBSITE.STATUS ? url : undefined;
  };

  /**
   * Handles the file upload
   * Reads the file and validates the urls
   * Sets the urls to be uploaded
   * @param {File | FileList} file - File to be uploaded
   */
  const fileUploadHandler = async (file: File | FileList): Promise<void> => {
    setUrlsToBeUploadedLoading(true);
    if (file && file instanceof File) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const text = event?.target?.result;
        const urlsToBeValidated =
          typeof text === 'string'
            ? text
                .replaceAll('"', '')
                .split('\n')
                .map((line) => line.trim())
            : [];

        // remove lines that are only whitespace
        const filteredUrls = urlsToBeValidated.filter((line: string) => line.trim() !== '');

        const promiseArray: Promise<string | undefined>[] = [];
        filteredUrls.forEach((line: string) =>
          promiseArray.push(validateUrl(line.endsWith('\r') ? line.slice(0, -1) : line))
        );
        const promiseResults = await Promise.all(promiseArray);
        if (promiseResults.length > 0) {
          // Remove duplicates and undefined values
          setUrlsToBeUploaded(
            promiseResults.filter((e, i, a) => a.indexOf(e) === i).filter((e): e is string => e !== undefined)
          );
          setUrlsToBeUploadedLoading(false);
        }
      };
      reader.readAsText(file);
    }
  };

  /**
   *  Checks if category is valid (not empty)
   * @returns {boolean} - Returns true if category is valid, false otherwise
   */
  const isCategoryValid = (): boolean => {
    if (!category || category.value === '') {
      setCategoryError(URLS_LIST.ERROR.MISSING_CATEGORY);
      return false;
    }
    setCategoryError('');
    return true;
  };

  /**
   * Checks if check type is valid (not empty)
   * @returns {boolean} - Returns true if check type is valid, false otherwise
   */
  const isCheckTypeValid = (): boolean => {
    if (!checkType || checkType.value === '') {
      setCheckTypeError(URLS_LIST.ERROR.MISSING_CHECK_TYPE);
      return false;
    }
    setCheckTypeError('');
    return true;
  };

  const resetStates = (): void => {
    setCategory(URLS_CATEGORIES_OPTIONS[0]);
    setCheckType(CHECK_TYPES_OPTIONS[0]);
    setUrlsToBeUploaded([]);
  };
  /**
   * Handles the submit button click
   * @returns {Promise<void>} - Returns a promise
   */
  const handleSubmitButton = async (): Promise<void> => {
    setErrorMessage('');
    if (urlsToBeUploaded.length === 0) {
      setErrorMessage(URL_UPLOAD.TOAST.INVALID);
      return;
    }
    if (!isCategoryValid() || !isCheckTypeValid()) return;

    const { data, errors } = await createBulkManualUrls({
      variables: {
        input: {
          urls: urlsToBeUploaded,
          category: category.value,
          checkType: checkType.value,
          merchantId: Number(hookWhoAmI?.companyId),
        },
      },
    });

    if (errors && errors.length > 0) {
      setErrorMessage(errors[0].message);
      setIsConfirmationModalOpen(false);
      resetStates();
      return;
    }

    if (data?.createBulkManualUrls) {
      hookShowToast(URL_UPLOAD.TOAST.URLS_CREATED);
      setIsConfirmationModalOpen(false);
      resetStates();
      navigate(`${MERCHANT_PREFIX}${path.fintelCheckManualUrlsList.href}`);
    }
  };

  return {
    fileUploadHandler,
    bulkUploadLoading: createBulkManualUrlsLoading,
    category,
    setCategory,
    categoryError,

    checkType,
    setCheckType,
    checkTypeError,

    handleSubmitButton,

    isConfirmationModalOpen,
    toggleConfirmationModal: () => setIsConfirmationModalOpen(!isConfirmationModalOpen),

    urlsToBeUploaded,
    urlsToBeUploadedLoading,
    errorMessage,
  };
};
