import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUserInfo } from 'hooks';
import { columns, evalColumns } from 'pages/Merchants/FintelCheck/Components/RulesSummaryReport/contracts/';
import { processDataToCSVString } from 'pages/Merchants/FintelCheck/FintelCheckDetails/utils/processDataForCSV';
import { useFintelCheckContext } from 'pages/Merchants/FintelCheck/hooks';
import { dateFormatter, csvGenerator, path } from 'utils';

import {
  CHECK_RULE_GROUP_DEFAULT,
  CHECK_RULES_DEFAULT,
  PUBLISHERS_DEFAULT,
  PRODUCT_CATEGORY_DEFAULT,
  PRODUCT_DEFAULT,
  MERCHANTS_DEFAULT,
  RULE_REVIEW_STATUSES,
  RULE_STATUSES,
  RULE_TYPE_OPTIONS,
} from '../enums';
import { GET_RULE_REPORT_FILTER_OPTIONS, GET_FINTEL_CHECK_REPORT_RESULTS, RuleReportType } from '../graphql/queries';

export const useSummaryReport = (
  viewHistoryReport: (row: RuleReportType) => void,
  selectedReviewStatus: SelectOption | undefined,
  handleOnScreenshotClick: (val: any) => void,
  handlerSetDetailedViewModalInfo: (row: RuleReportType) => void,
  isMonitoringReport: boolean,
  isAdmin: boolean
) => {
  const { contextState, contextDispatchHandler } = useFintelCheckContext();
  const { hookWhoAmI } = useUserInfo();
  const navigate = useNavigate();

  const reportName = isMonitoringReport ? 'Monitoring Report' : 'Evaluation Rules Report';

  // Filters
  const [ruleStatus, setRuleStatus] = useState<SelectOption>(RULE_STATUSES[0]);
  const [reviewStatus, setReviewStatus] = useState<SelectOption>(RULE_REVIEW_STATUSES[0]);
  const [checkRule, setCheckRule] = useState<SelectOption>(CHECK_RULES_DEFAULT);
  const [ruleType, setRuleType] = useState<SelectOption>(RULE_TYPE_OPTIONS[0]);
  const [ruleTypeOptions] = useState<SelectOption[]>(RULE_TYPE_OPTIONS);
  const [checkRuleOptions, setCheckRuleOptions] = useState<SelectOption[]>([CHECK_RULES_DEFAULT]);
  const [ruleGroup, setRuleGroup] = useState<SelectOption>(CHECK_RULE_GROUP_DEFAULT[0]);
  const [ruleGroupOptions, setRuleGroupOptions] = useState<SelectOption[]>([CHECK_RULE_GROUP_DEFAULT[0]]);
  const [publisher, setPublisher] = useState<SelectOption>(PUBLISHERS_DEFAULT[0]);
  const [publisherOptions, setPublisherOptions] = useState<SelectOption[]>([PUBLISHERS_DEFAULT[0]]);
  const [productCategory, setProductCategory] = useState<SelectOption>(PRODUCT_CATEGORY_DEFAULT[0]);
  const [productCategoryOptions, setProductCategoryOptions] = useState<SelectOption[]>([PRODUCT_CATEGORY_DEFAULT[0]]);
  const [product, setProduct] = useState<SelectOption>(PRODUCT_DEFAULT[0]);
  const [productOptions, setProductOptions] = useState<SelectOption[]>([PRODUCT_DEFAULT[0]]);
  const [merchant, setMerchant] = useState<SelectOption>(MERCHANTS_DEFAULT[0]);
  const [merchantOptions, setMerchantOptions] = useState<SelectOption[]>([MERCHANTS_DEFAULT[0]]);
  const [urlSearch, setUrlSearch] = useState<string>('');
  const [getRuleReportFilters, { loading: getFilterOptionsLoading }] = useLazyQuery(GET_RULE_REPORT_FILTER_OPTIONS);

  const [records, setRecords] = useState({ label: '10', value: '10' });

  const [selectedCheckboxItem, setSelectedCheckboxItem] = useState<{
    checked: boolean;
    selected?: RuleReportType;
  }>();
  const [errorMessage, setErrorMessage] = useState('');

  // Data
  const [tableData, setTableData] = useState<any[]>();
  const [dataGenerated, setDataGenerated] = useState(false);
  const [generateClicked, setGenerateClicked] = useState(false);
  const [downloadClicked, setDownloadClicked] = useState(false);
  const [getFintelCheckReport, { loading: getFintelChecksLoading }] = useLazyQuery(GET_FINTEL_CHECK_REPORT_RESULTS);

  // Table & Pagination
  const [tableColumns, setTableColumns] = useState<TableColumn[]>(columns(viewHistoryReport));
  const [evalTableColumns, setEvalTableColumns] = useState<TableColumn[]>(evalColumns(viewHistoryReport));

  const [tableKey, setTableKey] = useState<number>(123456); // Used to force table to rerender from start

  const [sortColumn, setSortColumn] = useState<TableSortColumn>({ column: 'createDatetime', direction: 'desc' });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [refetchTable, setRefetchTable] = useState<boolean>(false);

  const getReport = async (limit: number, page?: null | number) => {
    setErrorMessage('');
    const input = {
      merchantId: isAdmin
        ? merchant.value === MERCHANTS_DEFAULT[0].value
          ? '0'
          : merchant.value
        : (hookWhoAmI.companyId ?? '0'),
      sortBy: sortColumn.column,
      sortDirection: sortColumn.direction ?? 'desc',
      limit,
      currentPage: page ?? currentPage,
      reviewStatus: reviewStatus.value,
      ruleStatus: ruleStatus.value === RULE_STATUSES[0].value ? null : ruleStatus.value,
      ruleGroup: ruleGroup.value === CHECK_RULE_GROUP_DEFAULT[0].value ? null : ruleGroup.value,
      ruleName: checkRule.value === CHECK_RULES_DEFAULT.value ? null : checkRule.value,
      publisher: publisher.value === PUBLISHERS_DEFAULT[0].value ? null : publisher.value,
      productCategory: productCategory.value === PRODUCT_CATEGORY_DEFAULT[0].value ? null : productCategory.value,
      product: product.value === PRODUCT_DEFAULT[0].value ? null : product.value,
      urlSearch: urlSearch === '' ? null : urlSearch,
      ruleType: ruleType.value === RULE_TYPE_OPTIONS[0].value ? null : ruleType.value,
      isMonitoringReport,
      isAdmin,
      userId: hookWhoAmI.id,
    };

    const { data, error } = await getFintelCheckReport({ variables: { input }, fetchPolicy: 'no-cache' });
    if (error) {
      setErrorMessage(error.message);
      return null;
    }
    return data;
  };

  // TODO: Remove unused variables
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleOnSelectCheckbox = (checked: boolean, selected?: any, isCheckAll = false): void => {
    setSelectedCheckboxItem({ checked, selected });
  };

  const getMonitoringReportHandler = async (): Promise<void> => {
    const reportData = await getReport(Number(records.value));
    setTableKey((last) => last + 1);
    if (reportData?.fintelCheckReport?.reportResults) {
      const formattedData = reportData?.fintelCheckReport?.reportResults.map((item: RuleReportType) => ({
        id: item.id,
        reviewStatus: item.reviewStatus,
        previousReviewStatus: item.previousReviewStatus,
        reviewDate: item.reviewDate ? dateFormatter(new Date(item.reviewDate), '/') : null,
        ruleStatus: item.ruleStatus,
        pageChecked: item.createDatetime ? dateFormatter(new Date(item.createDatetime), '/') : null,
        urlSearch: item.pageUrl,
        ruleName: item.ruleName,
        ruleGroup: item.ruleGroup,
        merchant: item.merchant,
        createDatetime: item.createDatetime ? dateFormatter(new Date(item.createDatetime), '/') : null,
        checked: false,
        publisher: item.publisher,
        productCategory: item.productCategory,
        productName: item.productName,
        brandName: item.brandName,
        eligibility: item.eligibility,
        ruleType: item.ruleType,
        ruleCriteria: item.ruleCriteria,
      }));

      setTableColumns(
        columns(
          viewHistoryReport,
          reportData?.fintelCheckReport?.totalCounts,
          handleOnSelectCheckbox,
          handleOnScreenshotClick,
          handlerSetDetailedViewModalInfo,
          undefined,
          isAdmin
        )
      );

      setEvalTableColumns(
        evalColumns(
          viewHistoryReport,
          reportData?.fintelCheckReport?.totalCounts,
          handleOnSelectCheckbox,
          handleOnScreenshotClick,
          handlerSetDetailedViewModalInfo,
          isAdmin
        )
      );
      setTableData(formattedData);
      setTotalPages(reportData?.fintelCheckReport?.totalPages);
      setTotalRecords(reportData?.fintelCheckReport?.count);
      setDataGenerated(true);
    } else {
      setTableColumns(columns(viewHistoryReport, undefined, undefined, undefined, undefined, undefined, isAdmin));
      setEvalTableColumns(evalColumns(viewHistoryReport, undefined, undefined, undefined, undefined, isAdmin));
      setTableData([]);
      setCurrentPage(1);
      setTotalPages(0);
      setTotalRecords(0);
    }
  };

  const fetchFilterOptions = async (merchantId: string): Promise<void> => {
    const { data, error } = await getRuleReportFilters({
      variables: {
        input: {
          programId: hookWhoAmI.programId,
          merchantId: isAdmin ? (merchantId === 'All Merchants' ? '0' : merchantId) : hookWhoAmI.companyId?.toString(),
          isMonitoringReport,
          isAdmin,
          userId: hookWhoAmI.id,
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (error) {
      setErrorMessage(error.message);
      return;
    }

    if (data?.getRuleReportFilters) {
      if (data?.getRuleReportFilters?.rules) {
        setCheckRuleOptions([CHECK_RULES_DEFAULT, ...data.getRuleReportFilters.rules]);
      }
      if (data?.getRuleReportFilters?.ruleGroups) {
        setRuleGroupOptions([...CHECK_RULE_GROUP_DEFAULT, ...data.getRuleReportFilters.ruleGroups]);
      }
      if (data?.getRuleReportFilters?.publishers) {
        setPublisherOptions([...PUBLISHERS_DEFAULT, ...data.getRuleReportFilters.publishers]);
      }
      if (data?.getRuleReportFilters?.merchants) {
        setMerchantOptions([...MERCHANTS_DEFAULT, ...data.getRuleReportFilters.merchants]);
      }
      if (data?.getRuleReportFilters?.productCategory) {
        setProductCategoryOptions([...PRODUCT_CATEGORY_DEFAULT, ...data.getRuleReportFilters.productCategory]);
      }
      if (data?.getRuleReportFilters?.products) {
        setProductOptions([...PRODUCT_DEFAULT, ...data.getRuleReportFilters.products]);
      }
    }
  };

  const generateMonitoringReport = (): void => {
    setDownloadClicked(false);
    setGenerateClicked(true);
    setRefetchTable(true);
    setCurrentPage(1);
  };

  const downloadMonitoringReportCSV = (): void => {
    setDownloadClicked(true);
    setGenerateClicked(false);
    setRefetchTable(true);
    setCurrentPage(1);
  };

  const downloadMonitoringReportHandler = async (): Promise<void> => {
    const limit = 10000000; // FC-979: Limit to a large number to download all records
    const reportData = await getReport(limit, 1);
    setDownloadClicked(false);
    setTableKey((last) => last + 1);
    if (reportData?.fintelCheckReport?.reportResults) {
      const formattedData = reportData.fintelCheckReport?.reportResults.map((item: RuleReportType) => {
        const formattedItem: {
          reviewStatus: string;
          previousReviewStatus: string;
          reviewDate: string | null;
          ruleStatus: string;
          urlSearch: string;
          ruleName: string;
          ruleGroup: string;
          productName: string;
          productCategory: string;
          publisher: string;
          pageChecked: string;
          merchant?: string;
        } = {
          reviewStatus: item.reviewStatus,
          previousReviewStatus: item.previousReviewStatus,
          reviewDate: item.reviewDate ? dateFormatter(new Date(item.reviewDate), '/') : null,
          ruleStatus: item.ruleStatus,
          urlSearch: item.pageUrl,
          ruleName: item.ruleName,
          ruleGroup: item.ruleGroup,
          productName: item.productName,
          productCategory: item.productCategory,
          publisher: item.publisher,
          pageChecked: item.createDatetime ? dateFormatter(new Date(item.createDatetime), '/') : '',
        };

        if (isAdmin) {
          formattedItem.merchant = item.merchant;
        }

        return formattedItem;
      });

      let shouldIncludeProducts = true;
      if (isMonitoringReport) {
        shouldIncludeProducts = false;
        delete formattedData.productName;
        delete formattedData.productCategory;
      }
      const csvString = processDataToCSVString(
        formattedData,
        columns(
          viewHistoryReport,
          reportData.fintelCheckReport.totalCounts,
          undefined,
          undefined,
          undefined,
          shouldIncludeProducts,
          isAdmin
        )
      );

      csvGenerator(csvString, reportName);

      generateMonitoringReport();
    }
  };

  const handleUpdateSelectedCheckboxItem = (): void => {
    setErrorMessage('');
    if (tableData) {
      const updatedData = tableData.map((item) =>
        item.id === selectedCheckboxItem?.selected?.id ? { ...item, checked: selectedCheckboxItem?.checked } : item
      );
      setTableData(updatedData);
    }
  };

  const handleMerchantChanges = (e: SelectOption): void => {
    setMerchant(e);
    fetchFilterOptions(e.value);
  };

  useEffect(() => {
    if (selectedCheckboxItem) handleUpdateSelectedCheckboxItem();
  }, [selectedCheckboxItem]);

  useEffect(() => {
    setErrorMessage('');
  }, [selectedReviewStatus]);

  const clearFormHandler = (): void => {
    setRuleStatus(RULE_STATUSES[0]);
    setRuleType(RULE_STATUSES[0]);
    setReviewStatus(RULE_REVIEW_STATUSES[0]);
    setCheckRule(CHECK_RULES_DEFAULT);
    setRuleGroup(CHECK_RULE_GROUP_DEFAULT[0]);
    setPublisher(PUBLISHERS_DEFAULT[0]);
    setProductCategory(PRODUCT_CATEGORY_DEFAULT[0]);
    setProduct(PRODUCT_DEFAULT[0]);
    setMerchant(MERCHANTS_DEFAULT[0]);
    setUrlSearch('');

    setRefetchTable(false);
    setDataGenerated(false);
    setCurrentPage(1);
    setTotalRecords(0);
    setTotalPages(0);
    setTableData([]);
  };

  const handleSort = (dataField: string): void => {
    if (!dataField) return;

    setSortColumn({ column: dataField, direction: sortColumn.direction === 'asc' ? 'desc' : 'asc' });
    setRefetchTable(true);
  };

  const handlePageChange = (value: number): void => {
    setCurrentPage(value);
    setRefetchTable(true);
  };

  if (generateClicked && refetchTable) {
    setRefetchTable(false);
    getMonitoringReportHandler();
  }

  if (downloadClicked && refetchTable) {
    setRefetchTable(false);
    downloadMonitoringReportHandler();
  }

  useEffect(() => {
    fetchFilterOptions('0');
  }, []);

  useEffect(() => {
    setCurrentPage(1);
    setRefetchTable(true);
  }, [records]);

  /* If the url has changed from Merchant Report to Admin, reset the report */
  useEffect(() => {
    if (
      window.location.pathname.includes(path.monitoringRulesReport.href) ||
      window.location.pathname.includes(path.evaluationRulesReport.href)
    ) {
      clearFormHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return {
    tableData,
    tableColumns,
    evalTableColumns,
    tableKey,
    navigate,

    contextState,
    contextDispatchHandler,
    getFilterOptionsLoading,

    sortColumn,
    handleSort,

    currentPage,
    totalPages,
    totalRecords,
    handlePageChange,

    generateMonitoringReport,
    downloadMonitoringReportCSV,
    dataGenerated,
    records,
    setRecords,

    setSelectedCheckboxItem,
    setRefetchTable,

    // Filters
    ruleStatus,
    setRuleStatus,
    ruleTypeOptions,
    ruleType,
    setRuleType,
    reviewStatus,
    setReviewStatus,

    setMerchant,
    merchant,
    merchantOptions,

    checkRule,
    setCheckRule,
    checkRuleOptions,

    ruleGroup,
    setRuleGroup,
    ruleGroupOptions,

    publisher,
    setPublisher,
    publisherOptions,

    productCategory,
    setProductCategory,
    productCategoryOptions,

    product,
    setProduct,
    productOptions,

    urlSearch,
    setUrlSearch,

    clearFormHandler,
    handleOnSelectCheckbox,
    errorMessage,
    handleMerchantChanges,
    isLoading: getFintelChecksLoading,
  };
};
