import styled from 'styled-components';

import { colors, fonts } from 'styles/theme';
import { Button, Checkbox } from 'components';

export const ModalWrapperStyled = styled.div`
  color: ${colors.color1};
  display: flex;
  flex-direction: column;
  width: 632px;
  padding: 1rem;
`;

export const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 1.5rem;
  align-items: center;
`;

export const TitleText = styled.h2`
  ${fonts.gotham.H24M};
  color: ${colors.color1};
`;

export const SubTitleText = styled.h3`
  ${fonts.gotham.H20M};
  color: ${colors.color1};
  padding-bottom: 1rem;
`;

export const SectionWrapper = styled.div`
  display: flex;
  padding-bottom: 2rem;
  border: 2px solid blue;
`;

export const CustomTextWrapper = styled.div`
  display: flex;
  padding-bottom: 2rem;
  flex-direction: column;
`;

export const FlexRow = styled.div`
  display: flex;
`;

export const RuleStatusWrapper = styled.div`
  display: flex;
`;

export const CategoryRuleStatusWrapper = styled.div`
  width: 100%;
  padding-bottom: 1rem;
`;

export const RowWrapper = styled.div`
  flex: 1 0 0;
`;

export const CategoryWrapper = styled.div`
  width: 96%;
  padding-bottom: 1rem;
`;

export const CategoryHeader = styled.p`
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  color: ${colors.color13};
  ${fonts.gotham.H12M};
  word-wrap: break-word;
  word-break: break-word;
`;

export const CustomTextInfo = styled.div`
  width: 100%;
  margin-bottom: 0.75rem;
  ${fonts.lato.H14};
  color: ${colors.color1};
`;

export const CustomTextListHeader = styled.p`
  width: 100%;
  margin-bottom: 1rem;
  ${fonts.gotham.H24M};
  color: ${colors.color1};
`;

export const CategoryValue = styled.p`
  width: 100%;
  color: ${colors.color1};
  ${fonts.lato.H16}
  padding-bottom: 1rem;
`;

export const CustomTextValuesWrapper = styled.div`
  width: 100%;
  color: ${colors.color1};
  padding-bottom: 1rem;
`;

export const CustomTextValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.75rem;
  align-items: center;
`;

export const AndSection = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 1.5rem;
`;

export const CustomTextIndexLabel = styled.span`
  margin-right: 0.75rem;
  text-align: center;
  ${fonts.lato.H16B};
  color: ${colors.color1};
`;

export const CustomTextValue = styled.span`
  word-wrap: break-word;
  word-break: break-word;
`;

export const AndText = styled.span`
  color: ${colors.color23};
  ${fonts.lato.H16}
`;

export const AndLine = styled.span`
  width: 100%;
  border-bottom: 1px solid ${colors.color15};
  margin-bottom: 7px;
  margin-left: 8px;
`;

export const DetailsButton = styled(Button)`
  border: 1px solid ${colors.color2};
  height: 44px;
  width: 100%;
  :hover {
    border: 1px solid ${colors.color2};
  }
`;

export const ReviewTextStyled = styled.p`
  ${fonts.lato.H14};
  color: ${colors.color1};
  margin-bottom: 20px;
  line-height: 1.5;
`;

export const BackIconStyled = styled.button`
  border: none;
  background: none;
  margin: 0;
  padding: 0 1rem 0 0;
  :hover {
    cursor: pointer;
  }
`;

export const NavButtonsWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  gap: 1rem;
`;

export const CheckboxStyled = styled(Checkbox)`
  span {
    opacity: 1;
  }
`;
