/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { faEdit, faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React from 'react';
import Styled from 'styled-components';

import { RuleReportType } from 'pages/Merchants/FintelCheck/Components/RulesSummaryReport/graphql/queries/getMonitoringReport';
import { Button, Checkbox } from 'components';
import { colors, fonts } from 'styles/theme';

import { PageUrlCell } from './Component';

const CellWrapperStyled = Styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  ${fonts.lato.H14};
  color: ${colors.color1};
  max-height: 3.75rem;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  overflow-wrap: break-word;

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const CellStyled = Styled.span`
  display: flex;
  word-wrap: normal;
  justify-content: flex-start;
  white-space: pre-wrap;
  ${fonts.lato.H16};
  align-items: center;
  gap: 0.35vw;
  min-width: 5vw;
`;

const FAButtonStyled = Styled(Button)`
  display: flex;
  justify-content: flex-start;
  padding: 0;
  font-size: 1rem;
  color: ${colors.color1};

  &:hover {
    color: ${colors.color2};
  }
`;

const CheckBoxWrapper = Styled.div`
  min-width: 36px;
`;

type Totals = {
  pageCount: number | null;
  passCount: number | null;
  failCount: number | null;
  reviewCount: number | null;
  incompleteCount: number | null;
  nfCount: number | null;
};

export const columns = (
  viewHistory: (row: RuleReportType) => void,
  totals?: Totals | undefined,
  handleCheckbox?: any,
  handleOnScreenshotClick?: any,
  handlerSetDetailedViewModalInfo?: any,
  shouldIncludeProducts?: boolean,
  isAdmin?: boolean
): TableColumn[] => {
  const theColumns: TableColumn[] = [
    {
      dataField: 'checked',
      text: '',
      width: '36px',
      isCheckBox: true,
      formatter: (val, row) => (
        <CheckBoxWrapper onClick={(e) => e.stopPropagation()}>
          <Checkbox
            key={val}
            checked={val}
            onChange={(checked) => handleCheckbox && handleCheckbox(checked, row, false)}
          />
        </CheckBoxWrapper>
      ),
    },
    {
      dataField: '',
      text: '',
      formatter: (val: any, row: any) => (
        <CellStyled theme="quaternary">
          <FAButtonStyled theme="text-only" onClick={() => handlerSetDetailedViewModalInfo(row)}>
            <FontAwesomeIcon icon={faEdit} />
          </FAButtonStyled>
          <FAButtonStyled theme="text-only" onClick={() => handleOnScreenshotClick(row.id)}>
            <FontAwesomeIcon icon={faImage} />
          </FAButtonStyled>
          <FAButtonStyled
            theme="text-only"
            onClick={() => {
              viewHistory(row);
            }}
          >
            <FontAwesomeIcon icon={faClockRotateLeft} />
          </FAButtonStyled>
        </CellStyled>
      ),
      width: '96px',
    },
    {
      dataField: 'reviewStatus',
      text: 'Review Status',
      width: '9vw',
      formatter: (value) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      dataField: 'reviewDate',
      text: 'Review Date',
      width: '7vw',
      formatter: (value) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      dataField: 'previousReviewStatus',
      text: 'Previous Review Status',
      width: '9vw',
      formatter: (value) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      dataField: 'ruleStatus',
      text: 'Rule Status',
      width: '6vw',
      formatter: (value) => (
        <CellWrapperStyled>{typeof value === 'string' ? _.startCase(value.toLowerCase()) : value}</CellWrapperStyled>
      ),
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      dataField: 'pageChecked',
      text: 'Page Checked',
      width: '8vw',
      formatter: (value: any) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      dataField: 'urlSearch',
      text: 'Url',
      width: '14vw',
      formatter: (val) => <PageUrlCell val={val} />,
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      dataField: 'ruleName',
      text: 'Rule Name',
      width: '15vw',
      formatter: (value) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      dataField: 'productCategory',
      text: 'Product Category',
      width: '15vw',
      formatter: (value) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      dataField: 'productName',
      text: 'Product',
      width: '15vw',
      formatter: (value) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      dataField: 'ruleGroup',
      text: 'Rule Group',
      width: '9vw',
      formatter: (value) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      dataField: 'publisher',
      text: 'Publishers',
      width: '10vw',
      formatter: (value) => (
        <CellWrapperStyled>
          <span>{value}</span>
        </CellWrapperStyled>
      ),
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    },
  ];

  if (isAdmin) {
    theColumns.splice(2, 0, {
      dataField: 'merchant',
      text: 'Merchant',
      width: '15vw',
      formatter: (value) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    });
  }

  if (!shouldIncludeProducts) {
    if (isAdmin) {
      theColumns.splice(10, 2);
    } else {
      theColumns.splice(9, 2);
    }
  }

  return theColumns;
};
