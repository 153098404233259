import React from 'react';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';

import {
  AppWrapper,
  Button,
  ErrorBanner,
  Modal,
  PageTitle,
  Pagination,
  RecordsDropdown,
  Select,
  Table,
} from '../../../../components';
import { RunDate } from '../Components';
import { dateFormatter, RECORDS_PER_PAGE_OPTIONS_WITH_150 } from '../../../../utils';
import { DefaultPropsType } from '../../../../types';

import { useFintelCheckDetails } from './hooks';
import { columns } from './contracts';
import { FeedbackModal } from './Modal';
import { DETAILS } from './enums';
import { FINTEL_CHECK_DETAILS_REPORT, initialState } from './reducers';
import * as Styled from './styles';

type FintelCheckDetailsProps = DefaultPropsType;

const FintelCheckDetails = ({ permissionsCodeList = [] }: FintelCheckDetailsProps) => {
  const hook = useFintelCheckDetails();
  return (
    <AppWrapper background permissionsCodeList={permissionsCodeList}>
      <ErrorBanner message={hook.hookErrorMessage} isOpen={!!hook.hookErrorMessage} />
      <Styled.HeaderStyled>
        <Styled.HeaderLeftStyled>
          {hook.hookContext.merchantName && <PageTitle>{hook.hookContext.merchantName}</PageTitle>}
        </Styled.HeaderLeftStyled>
      </Styled.HeaderStyled>
      <Styled.HeaderStyled>
        <Styled.HeaderLeftStyled>
          <PageTitle isLoading={hook.hookLoading}>
            {hook.hookContext.publisherId && hook.hookContext.publisherId !== hook.hookContext.merchantId
              ? `${hook.hookContext.publisherId} - ${hook.hookContext.publisherName}`
              : 'Manual URLs'}
          </PageTitle>
          <RunDate date={dateFormatter(new Date(hook.hookContext.checkDate || hook.hookContext.date))} />
        </Styled.HeaderLeftStyled>
        <Button theme="denary" onClick={hook.hookNavigateBack}>
          {DETAILS.BACK}
        </Button>
      </Styled.HeaderStyled>

      <Styled.ReportWrapper>
        <Styled.ReportTitleStyled>{DETAILS.REPORT_TITLE}</Styled.ReportTitleStyled>
        <Styled.FiltersWrapper>
          <Select
            label="Product Category"
            selected={hook.hookSelectState.productCategory}
            options={hook.hookCategoryList}
            onChange={(e: SelectOption) =>
              hook.hookChangeSelect(e, FINTEL_CHECK_DETAILS_REPORT.CHANGE_PRODUCT_CATEGORY)
            }
            isDisabled={hook.hookLoading || hook.hookDropdownsLoading}
            isLoading={hook.hookDropdownsLoading}
          />
          <Select
            label="Product Name - Id"
            selected={hook.hookSelectState.product}
            options={hook.hookProductList}
            onChange={(e: SelectOption) => hook.hookChangeSelect(e, FINTEL_CHECK_DETAILS_REPORT.CHANGE_PRODUCT)}
            isDisabled={hook.hookLoading || hook.hookDropdownsLoading}
            isLoading={hook.hookDropdownsLoading}
          />
          <Select
            label="Status"
            selected={hook.hookSelectState.status}
            options={hook.hookStatusList}
            onChange={(e: SelectOption) => hook.hookChangeSelect(e, FINTEL_CHECK_DETAILS_REPORT.CHANGE_STATUS)}
            isDisabled={hook.hookLoading || hook.hookDropdownsLoading}
            isLoading={hook.hookDropdownsLoading}
          />
          <Button
            theme="text-only"
            onClick={() => hook.hookClearForm(initialState, FINTEL_CHECK_DETAILS_REPORT.CLEAR_FORM)}
            disabled={hook.hookLoading || hook.hookDropdownsLoading}
            loading={hook.hookDropdownsLoading}
          >
            {DETAILS.CLEAR}
          </Button>
        </Styled.FiltersWrapper>
        <Styled.FiltersWrapper>
          <RecordsDropdown
            selected={hook.hookSelectState.records}
            currentPage={hook.hookSelectState.currentPage}
            options={RECORDS_PER_PAGE_OPTIONS_WITH_150}
            totalRecords={hook.hookSelectState.totalCount}
            onChange={(e: SelectOption) => hook.hookChangeSelect(e, FINTEL_CHECK_DETAILS_REPORT.CHANGE_RECORDS)}
          />
          <Button
            theme="tertiary"
            onClick={hook.hookGetSummariesCSV}
            loading={hook.hookCSVLoading}
            disabled={hook.hookCSVLoading}
            width="148px"
          >
            <Styled.IconStyled icon={faFileArrowDown} />
            {DETAILS.DOWNLOAD_CSV}
          </Button>
        </Styled.FiltersWrapper>
        <Styled.TableWrapper>
          <Table
            columns={columns}
            data={hook.hookData}
            setOpenModal={hook.hookSetModalOpen}
            setModalInfo={hook.hookModalSetInfo as any}
            setContext={hook.hookSetContext}
            isLoading={hook.hookLoading}
            sortColumn={hook.hookSortBy}
            onSort={hook.hookSetSortByHandler}
            loadWithData={hook.hookData.length > 0}
          />
        </Styled.TableWrapper>
        <Pagination
          currentPage={hook.hookSelectState.currentPage}
          total={hook.hookSelectState.totalPages}
          onPageChange={hook.hookChangePageHandler}
        />
      </Styled.ReportWrapper>
      <Modal isOpen={hook.hookModalOpen}>
        <FeedbackModal hook={hook.hookModalInfo} />
      </Modal>
    </AppWrapper>
  );
};

export default FintelCheckDetails;
