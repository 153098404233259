import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppWrapper, ErrorBanner, LoadingDots, PageTitle, Pagination, Table } from 'components';
import { MERCHANT_PREFIX, dateFormatter, path } from 'utils';
import { DefaultPropsType } from 'types';

import { ChangeMonitoringType } from '../graphql/queries';
import { useChangeMonitoring } from '../hooks/useChangeMonitoring';
import ReviewStatus from '../components/ReviewStatus';

import { MONITORING_CHANGE_DETAILS, NO_CHANGES } from './enum';
import { useMonitoringDetails } from './hooks/useMonitoringDetails';
import { columns } from './contracts/columns';
import * as Styled from './styles';

type ChangeMonitoringDetailsProps = DefaultPropsType;

const ChangeMonitoringDetails = ({ permissionsCodeList = [] }: ChangeMonitoringDetailsProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const hook = useMonitoringDetails();
  const monitoringHook = useChangeMonitoring();
  const localStateData: ChangeMonitoringType | undefined = location.state.data;

  useEffect(() => {
    if (!localStateData) {
      navigate(`${MERCHANT_PREFIX}${path.fintelCheckChangeMonitoring.href}`);
      return;
    }
    hook.fetchPageData(localStateData.id);
  }, [localStateData]);

  if (!localStateData) {
    return null;
  }
  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <ErrorBanner message={hook.errorMessage} isOpen={hook.errorMessage.length > 0} />

      <Styled.RowStyled>
        <Styled.HeaderStyled>
          <Styled.RowStyled>
            <PageTitle>{path.fintelCheckChangeMonitoringDetails.name}</PageTitle>
            <Styled.RunDateStyled>
              {MONITORING_CHANGE_DETAILS.RUN_DATE} {dateFormatter(new Date(localStateData.runDate))}
            </Styled.RunDateStyled>
          </Styled.RowStyled>

          <Styled.UrlLinkStyled href={localStateData.url} target="_blank" rel="noreferrer">
            <Styled.UrlStyled>{localStateData.url}</Styled.UrlStyled>
          </Styled.UrlLinkStyled>

          <Styled.SubTitleStyled>{localStateData.changeSummary}</Styled.SubTitleStyled>
        </Styled.HeaderStyled>

        <Styled.BackButtonStyled
          type="button"
          theme="secondary"
          onClick={() => navigate(`${MERCHANT_PREFIX}${path.fintelCheckChangeMonitoring.href}`)}
        >
          {MONITORING_CHANGE_DETAILS.BACK}
        </Styled.BackButtonStyled>
      </Styled.RowStyled>

      <Styled.ReviewStatusWrapper>
        {hook.changedData && hook.changedData.statusChanges.length > 0 && (
          <>
            <Styled.SectionTitleStyled>{MONITORING_CHANGE_DETAILS.REVIEW_STATUS}</Styled.SectionTitleStyled>
            <Table
              columns={columns()}
              data={hook.reviewStatusData || []}
              isLoading={hook.isLoading}
              loadWithData={hook.reviewStatusData ? hook.reviewStatusData.length > 0 : false}
            />
            <Pagination
              currentPage={hook.currentPage}
              total={hook.totalPages}
              onPageChange={hook.handleOnChangeCurrentPage}
            />
          </>
        )}
        <Styled.ReviewStatusContent>
          <ReviewStatus
            setSelectedReviewStatus={monitoringHook.setSelectedReviewStatus}
            onClickAction={() => hook.updateReviewStatusData(localStateData.id, monitoringHook)}
            selectedReviewStatus={monitoringHook.selectedReviewStatus}
            setReviewStatusNote={monitoringHook.setReviewStatusNote}
            reviewStatusNote={monitoringHook.reviewStatusNote}
            shouldDisableSetReviewStatusButton={monitoringHook.shouldDisableSetReviewStatusButton}
            setReviewStatusLoading={monitoringHook.setReviewStatusLoading}
            latestStatus={localStateData.statusChanges}
          />
        </Styled.ReviewStatusContent>
      </Styled.ReviewStatusWrapper>

      <Styled.SectionTitleStyled>{MONITORING_CHANGE_DETAILS.PAGE_DIFFERENCES}</Styled.SectionTitleStyled>
      {hook.isLoading ? (
        <LoadingDots />
      ) : (
        <Styled.DataChangesWrapper>
          <Styled.TextChangesWrapperStyled>
            {(hook.changedData?.snippedChanges || []).length > 0 ? (
              <Styled.TextDiffChangesWrapperStyled>
                {hook.changedData?.snippedChanges.map((item, index) => (
                  <React.Fragment key={`${item}+${index}`}>
                    <Styled.TextDiffChangesContentStyled>
                      {item.deleted &&
                        item.deleted.map((deletedItem) => (
                          <pre key={deletedItem.id}>
                            <Styled.DeletedSnippedStyled>
                              {deletedItem.text.replace(/<br\s*\/?>/gm, '\n')}
                            </Styled.DeletedSnippedStyled>
                          </pre>
                        ))}
                      {item.added &&
                        item.added.map((addedItem) => (
                          <pre key={addedItem.id}>
                            <Styled.AddedSnippedStyled>
                              {addedItem.text.replace(/<br\s*\/?>/gm, '\n')}
                            </Styled.AddedSnippedStyled>
                          </pre>
                        ))}
                    </Styled.TextDiffChangesContentStyled>
                    {(hook.changedData?.snippedChanges || []).length > 0 &&
                      index + 2 < (hook.changedData?.snippedChanges || []).length && <Styled.SeparatorStyled />}
                  </React.Fragment>
                ))}
              </Styled.TextDiffChangesWrapperStyled>
            ) : (
              <div>{NO_CHANGES}</div>
            )}
          </Styled.TextChangesWrapperStyled>

          <Styled.ImageWrapperStyled>
            {hook.changedData?.diffImage ? (
              <img
                src={`data:image/jpeg;base64,${hook.changedData.diffImage}`}
                alt="diffImage"
                style={{ height: 'inherit' }}
              />
            ) : hook.changedData?.compareTargetS3Image ? (
              <img
                src={`data:image/jpeg;base64,${hook.changedData.compareTargetS3Image}`}
                alt="compareTargetS3Image"
                style={{ height: 'inherit' }}
              />
            ) : null}
          </Styled.ImageWrapperStyled>
        </Styled.DataChangesWrapper>
      )}
    </AppWrapper>
  );
};

export default ChangeMonitoringDetails;
