import React from 'react';

import { humanizeProductFeed } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/types';

import { FormatedCell } from '../../FormatedCell';
import { formatFeedItem } from '../../RuleDetailsModal/hooks';
import { PRODUCT_FEED_MODAL } from '../enums';

export const buildColumns = (columnName: string): TableColumn[] => [
  {
    dataField: 'productName',
    text: PRODUCT_FEED_MODAL.TABLE_HEADERS.PRODUCT_NAME,
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <FormatedCell value={value} />,
    width: '50%',
  },
  {
    dataField: 'value',
    text: humanizeProductFeed(columnName),
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <FormatedCell value={formatFeedItem(value, columnName)} />,
    width: '50%',
  },
];
