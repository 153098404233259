import React from 'react';
import { reject } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faFile } from '@fortawesome/free-solid-svg-icons';

import { dateFormatter, monthNames, TABLE_FOOTER_USD, TABLE_FOOTER_CAD } from '../../../../utils';
import * as Styled from '../styles';
import { FooterDiv } from '../../../../utils/footerDiv';
import { Button } from '../../../../components';

const paymentMethodMinimums: any = {
  Cheque: {
    AUD: 60,
    CAD: 75,
    USD: 60,
  },
  'Cheque Payment for $1000': {
    USD: 1000,
    CAD: 1000,
    AUD: 1000,
  },
  Paypal: {
    AUD: 20,
    CAD: 25,
    USD: 20,
  },
  'Wire Transfer': {
    USD: 500,
    CAD: 500,
    AUD: 500,
  },
};

const getMinimumPayable = (currency: string, paymentMethod: string): number => {
  const minimums = paymentMethodMinimums[paymentMethod];
  if (minimums && minimums[currency]) {
    return minimums[currency];
  }
  return 0;
};

export const columns: TableColumn[] = [
  {
    dataField: 'id',
    text: 'Payment ID',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '3%',
    footerFormatter: () => FooterDiv(TABLE_FOOTER_CAD, TABLE_FOOTER_USD),
  },
  {
    dataField: 'month',
    text: 'Payment Period',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '3%',
    formatter: (value: any, row: any) => (
      <div>
        {monthNames[value - 1]} / {row.year}
      </div>
    ),
  },
  {
    dataField: 'paymentStatus',
    text: 'Status',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '2%',
  },
  {
    dataField: 'publisher',
    text: 'publisher id / name',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => (
      <div>
        {value.id} - {value.companyName}
      </div>
    ),
    width: '9%',
  },
  {
    dataField: 'paymentMethod',
    text: 'Method',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '3%',
  },
  {
    dataField: 'referenceNumber',
    text: 'Reference',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '8%',
  },
  {
    dataField: 'note',
    text: 'Note',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '8%',
  },
  {
    dataField: 'paymentGeneratedDate',
    text: 'Generated Date',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <div>{value ? dateFormatter(new Date(value)) : ''}</div>,
    width: '4%',
  },
  {
    dataField: 'paymentPaidDate',
    text: 'Paid Date',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <div>{value ? dateFormatter(new Date(value)) : ''}</div>,
    width: '4%',
  },
  {
    dataField: 'currency',
    text: 'Currency',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '2.5%',
  },
  {
    dataField: 'paymentMethod',
    text: 'Min Payment Amount',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (val: any, row: any) => <div>{getMinimumPayable(row.currency, val)}</div>,
    width: '2.5%',
  },
  {
    dataField: 'currentRollover',
    text: 'Rollover Amount',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <div>{value ? value.toFixed(2) : value}</div>,
    width: '3%',
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) =>
      FooterDiv(footerData?.rolloverCad.toFixed(2), footerData?.rolloverUsd.toFixed(2)),
  },
  {
    dataField: 'totalCommissions',
    text: 'Total Commission',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <div>{value ? value.toFixed(2) : value}</div>,
    width: '3%',
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) =>
      FooterDiv(footerData?.totalCommissionCad.toFixed(2), footerData?.totalCommissionUsd.toFixed(2)),
  },
  {
    dataField: 'bonus',
    text: 'Bonus Amount',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <div>{value ? value.toFixed(2) : value}</div>,
    width: '3%',
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) =>
      FooterDiv(footerData?.bonusAmountCad.toFixed(2), footerData?.bonusAmountUsd.toFixed(2)),
  },
  {
    dataField: 'bonusTax',
    text: 'Bonus Tax',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <div>{value ? value.toFixed(2) : value}</div>,
    width: '3%',
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) =>
      FooterDiv(footerData?.bonusTaxCad.toFixed(2), footerData?.bonusTaxUsd.toFixed(2)),
  },
  {
    dataField: 'totalTaxes',
    text: 'Commission Tax',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <div>{value ? value.toFixed(2) : value}</div>,
    width: '3%',
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) =>
      FooterDiv(footerData?.commissionTaxCad.toFixed(2), footerData?.commissionTaxUsd.toFixed(2)),
  },
  {
    dataField: 'totalPayable',
    text: 'Total Payable',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '3%',
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) =>
      FooterDiv(footerData?.totalPayableCad.toFixed(2), footerData?.totalPayableUsd.toFixed(2)),
    formatter: (value: any) => <div>{value ? value.toFixed(2) : value}</div>,
  },
  {
    dataField: 'periodBalance',
    text: 'Period Balance',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <div>{value ? value.toFixed(2) : value}</div>,
    width: '3%',
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) =>
      FooterDiv(footerData?.periodBalanceCad.toFixed(2), footerData?.periodBalanceUsd.toFixed(2)),
  },
  {
    dataField: 'paymentInfo.payableTo',
    text: 'payable to',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.payableTo?.replace(/.(?=.{4,}$)/g, '*')}</div>,
    width: '8%',
  },
  {
    dataField: 'paymentInfo.accountHolder',
    text: 'account holder',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.accountHolder?.replace(/.(?=.{4,}$)/g, '*')}</div>,
    width: '8%',
  },
  {
    dataField: 'paymentInfo.accountEmail',
    text: 'account email',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.accountEmail?.replace(/.(?=.{4,}$)/g, '*')}</div>,
    width: '8%',
  },
  {
    dataField: 'paymentInfo.accountPhoneNumber',
    text: 'account phone number',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.accountPhoneNumber?.replace(/.(?=.{4,}$)/g, '*')}</div>,
    width: '8%',
  },
  {
    dataField: 'paymentInfo.bankName',
    text: 'bank name',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.bankName?.replace(/.(?=.{4,}$)/g, '*')}</div>,
    width: '8%',
  },
  {
    dataField: 'paymentInfo.bankAccountNumber',
    text: 'bank account number',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.bankAccountNumber?.replace(/.(?=.{4,}$)/g, '*')}</div>,
    width: '8%',
  },
  {
    dataField: 'paymentInfo.bankCode',
    text: 'swift code / iban/ routing',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.bankCode?.replace(/.(?=.{4,}$)/g, '*')}</div>,
    width: '8%',
  },
  {
    dataField: 'paymentInfo.transit',
    text: 'transit',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.transit?.replace(/.(?=.{4,}$)/g, '*')}</div>,
    width: '8%',
  },
  {
    dataField: 'paymentInfo.wireTransferName',
    text: 'wire transfer name',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.wireTransferName?.replace(/.(?=.{4,}$)/g, '*')}</div>,
    width: '8%',
  },
  {
    dataField: 'paymentInfo.email',
    text: 'payment contact email',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.email?.replace(/.(?=.{4,}$)/g, '*')}</div>,
    width: '8%',
  },
  {
    dataField: 'paymentInfo.phone',
    text: 'phone number',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.phone?.replace(/.(?=.{4,}$)/g, '*')}</div>,
    width: '8%',
  },
  {
    dataField: 'paymentInfo.phone2',
    text: 'phone number 2',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.phone2?.replace(/.(?=.{4,}$)/g, '*')}</div>,
    width: '8%',
  },
  {
    dataField: 'paymentInfo.address1',
    text: 'address 1',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.address1?.replace(/.(?=.{4,}$)/g, '*')}</div>,
    width: '8%',
  },
  {
    dataField: 'paymentInfo.address2',
    text: 'address 2',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.address2?.replace(/.(?=.{4,}$)/g, '*')}</div>,
    width: '8%',
  },
  {
    dataField: 'paymentInfo.city',
    text: 'city',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.city?.replace(/.(?=.{4,}$)/g, '*')}</div>,
    width: '8%',
  },
  {
    dataField: 'paymentInfo.zip',
    text: 'zip',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.zip?.replace(/.(?=.{4,}$)/g, '*')}</div>,
    width: '8%',
  },
  {
    dataField: 'paymentInfo.country',
    text: 'country',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.country}</div>,
    width: '8%',
  },
  {
    dataField: 'paymentInfo.state',
    text: 'state/province',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.state}</div>,
    width: '8%',
  },
  {
    dataField: 'paymentInfo.gst',
    text: 'gst',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.gst}</div>,
    width: '8%',
  },
  {
    dataField: 'paymentInfo.pst',
    text: 'pst',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.pst}</div>,
    width: '8%',
  },
  {
    dataField: 'paymentInfo.hst',
    text: 'hst',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.hst}</div>,
    width: '8%',
  },
  {
    dataField: 'paymentInfo.tax',
    text: 'tax no.',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.tax}</div>,
    width: '8%',
  },
];
export const merchantReportColumns = (
  handleNavigationPdf: (row: any) => void,
  handleNavigation: (row: any) => void,
  month: any,
  year: any
): TableColumn[] => {
  let merchantColumns = [
    {
      dataField: 'id',
      text: 'Payment ID',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      width: '8%',
    },
    {
      dataField: 'month',
      text: 'Payment Period',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      width: '8%',
      formatter: (value: any, row: any) => (
        <div>
          {monthNames[value - 1]} / {row.year}
        </div>
      ),
    },
    {
      dataField: 'paymentStatus',
      text: 'Status',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      width: '8%',
    },
    {
      dataField: 'publisher',
      text: 'publisher id / name',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      width: '16%',
    },
    {
      dataField: 'paymentMethod',
      text: 'Method',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      width: '8%',
    },
    {
      dataField: 'productCategory',
      text: 'product category',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      width: '8%',
    },
    {
      dataField: 'merchantCurrency',
      text: 'Merchant Currency',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      width: '8%',
    },
    {
      dataField: 'currency',
      text: 'Payment Currency',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      width: '8%',
    },
    {
      dataField: 'rate',
      text: 'Currency Rate',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      width: '8%',
    },
    {
      dataField: 'currentRollover',
      text: 'Rollover Amount',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      formatter: (value: any) => <div>{!value || Number.isNaN(Number(value)) ? '0.00' : Number(value).toFixed(2)}</div>,
      width: '8%',
    },
    {
      dataField: 'periodBalance',
      text: 'Period Balance',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      formatter: (value: any) => <div>{!value || Number.isNaN(Number(value)) ? '0.00' : Number(value).toFixed(2)}</div>,
      width: '8%',
    },
    {
      dataField: 'totalCommissions',
      text: 'Total Commission',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      formatter: (value: any) => <div>{value?.toFixed(2)}</div>,
      width: '8%',
    },
    {
      dataField: 'bonus',
      text: 'Bonus Amount',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      formatter: (value: any) => <div>{value?.toFixed(2)}</div>,
      width: '8%',
    },
    {
      dataField: 'bonusTax',
      text: 'Bonus Tax',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      formatter: (value: any) => <div>{value?.toFixed(2)}</div>,
      width: '8%',
    },
    {
      dataField: 'totalTaxes',
      text: 'Commission Tax',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      formatter: (value: any) => <div>{value?.toFixed(2)}</div>,
      width: '8%',
    },
    {
      dataField: 'totalPayable',
      text: 'Total Payable',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      width: '8%',
      formatter: (value: any) => <div>{value?.toFixed(2)}</div>,
    },
    {
      dataField: '',
      text: 'statement',
      width: '8%',
      formatter: (val: string, row: any) => (
        <Styled.BoxStyled theme="amountCol">
          <Button onClick={() => handleNavigationPdf(row)} theme="quaternary">
            <FontAwesomeIcon icon={faFile} />
          </Button>
          <Button onClick={() => handleNavigation(row)} theme="quaternary">
            <FontAwesomeIcon icon={faAngleRight} />
          </Button>
        </Styled.BoxStyled>
      ),
      notClickable: true,
    },
  ];
  if (Number(month) <= 9 && Number(year) <= 2023) {
    merchantColumns = reject(merchantColumns, { dataField: 'totalPayable', text: 'Total Payable' });
    merchantColumns = reject(merchantColumns, { dataField: 'periodBalance' });
    merchantColumns = reject(merchantColumns, { dataField: 'currentRollover' });
    merchantColumns = reject(merchantColumns, { dataField: 'merchantCurrency' });
    merchantColumns = reject(merchantColumns, { dataField: 'currency' });
    merchantColumns = reject(merchantColumns, { dataField: 'rate' });
  }
  return merchantColumns;
};

export const publisherColumns: TableColumn[] = [
  {
    dataField: 'id',
    text: 'Payment ID',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '8%',
  },
  {
    dataField: 'month',
    text: 'Payment Period',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '16%',
    formatter: (value: any, row: any) => (
      <div>
        {monthNames[value - 1]} / {row.year}
      </div>
    ),
  },
  {
    dataField: 'paymentStatus',
    text: 'Status',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '8%',
  },
  {
    dataField: 'paymentMethod',
    text: 'Method',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '16%',
  },
  {
    dataField: 'referenceNumber',
    text: 'Reference',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '16%',
  },
  {
    dataField: 'paymentGeneratedDate',
    text: 'Generated Date',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <div>{value ? dateFormatter(new Date(value)) : ''}</div>,
    width: '16%',
  },
  {
    dataField: 'paymentPaidDate',
    text: 'Paid Date',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <div>{value ? dateFormatter(new Date(value)) : ''}</div>,
    width: '16%',
  },
  {
    dataField: 'currency',
    text: 'Currency',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '16%',
  },
  {
    dataField: 'paymentMethod',
    text: 'Min Payment Amount',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (val: any, row: any) => <div>{getMinimumPayable(row.currency, val)}</div>,
    width: '16%',
  },
  {
    dataField: 'currentRollover',
    text: 'Rollover Amount',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <div>{value ? value.toFixed(2) : value}</div>,
    width: '16%',
  },
  {
    dataField: 'totalCommissions',
    text: 'Total Commission',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <div>{value ? value.toFixed(2) : value}</div>,
    width: '16%',
  },
  {
    dataField: 'bonus',
    text: 'Bonus Amount',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <div>{value ? value.toFixed(2) : value}</div>,
    width: '16%',
  },
  {
    dataField: 'bonusTax',
    text: 'Bonus Tax',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <div>{value ? value.toFixed(2) : value}</div>,
    width: '16%',
  },
  {
    dataField: 'totalTaxes',
    text: 'Commission Tax',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <div>{value ? value.toFixed(2) : value}</div>,
    width: '16%',
  },
  {
    dataField: 'totalPayable',
    text: 'Total Payable',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '16%',
    formatter: (value: any) => <div>{value ? value.toFixed(2) : value}</div>,
  },
  {
    dataField: 'periodBalance',
    text: 'Period Balance',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <div>{value ? value.toFixed(2) : value}</div>,
    width: '16%',
  },
];
