/* eslint-disable @typescript-eslint/no-unused-vars */ // TODO: Remove this line
import { useLazyQuery, useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

import { paginator, sortString, dateFormatter } from 'utils';
import { useToast, useUserInfo, useModal } from 'hooks';
import {
  TrackingUrlType,
  URLS_LIST,
  URL_STATUS,
  CHECK_TYPES,
  CHECK_TYPES_OPTIONS,
} from 'pages/Merchants/FintelCheck/FintelCheckPublisherSearch/enums';
import {
  GET_TRACKING_URL_LIST,
  UPDATE_TRACKING_URL_CHECK_TYPE,
} from 'pages/Merchants/FintelCheck/FintelCheckPublisherSearch/Tabs/TrackingUrls/graphql';
import { useDebounce } from 'utils/useDebounce';

// Define the return type interface
interface UseTrackingUrlsReturnType {
  // Table data and pagination
  id: number;
  tableData: TrackingUrlType[];
  currentPage: number;
  totalPages: number;
  recordsCount: number;
  recordsPerPage: SelectOption;
  onPageChange: (page: number) => void;
  url: string;
  checkType: SelectOption | undefined;
  setCheckType: (checkType: SelectOption) => void;
  checkTypeError: string;
  resetModal: () => void;
  handleModalRightButton: () => Promise<void>;
  updateTrackingUrlLoading: boolean;

  // Search functionality
  search: string;
  setSearch: (value: string) => void;
  setSearchHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  clearSearch: () => void;

  // Sorting functionality
  sortColumn: TableSortColumn;
  handleSort: (dataField: string, direction: 'desc' | 'asc' | undefined) => void;

  // Records per page
  setRecordsPerPage: (value: SelectOption) => void;
  setRecordsHandler: (value: SelectOption) => void;

  // Data fetching
  urlsList: TrackingUrlType[];
  getTrackingUrlsLoading: boolean;

  // Error handling
  errorMessage: string;

  // Bulk Update
  handleCheckBox: (checked: boolean, row: TrackingUrlType) => void;
  selectedRows: TrackingUrlType | undefined; // [];
  handleUpdateUrls: () => void;
  updateTrackingUrlCheckTypeLoading: boolean;
  selectedCheckType: SelectOption | undefined;
  setSelectedCheckType: (value: SelectOption) => void;

  isOpen: boolean;
  setIsOpen: () => void;
  setCheckTypeEditModalInfo: ({ id, trackingUrl, type, loadedCheckType }: Record<string, string | number>) => void;
}

export const useTrackingUrls = (isReadOnly: boolean): UseTrackingUrlsReturnType => {
  // ===== States =====
  const { hookWhoAmI } = useUserInfo();
  const { hookShowToast } = useToast();
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 800);
  const [isOpen, setIsOpen] = useModal(false);
  const [url, setUrl] = useState<string>('');
  const [checkType, setCheckType] = useState<SelectOption>(CHECK_TYPES_OPTIONS[0]);
  const [checkTypeError, setCheckTypeError] = useState<string>('');
  const [updateTrackingUrl, { loading: updateTrackingUrlLoading }] = useMutation(UPDATE_TRACKING_URL_CHECK_TYPE);

  const [trackingUrlId, setTrackingUrlId] = useState<number>(0);

  const [tableData, setTableData] = useState<TrackingUrlType[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsCount, setRecordsCount] = useState(0);
  const [sortColumn, setSortColumn] = useState<TableSortColumn>({ column: '', direction: 'asc' });
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [urlsList, setUrlsList] = useState<TrackingUrlType[]>([]);
  const [urlsTemp, setUrlsTemp] = useState<TrackingUrlType[]>([]);
  const [recordsPerPage, setRecordsPerPage] = useState<SelectOption>({ label: '10', value: '10' });
  const [selectedRow, setSelectedRow] = useState<TrackingUrlType>();
  const [selectedCheckType, setSelectedCheckType] = useState<SelectOption | undefined>();

  const [getTrackingUrls, { loading: getTrackingUrlsLoading }] = useLazyQuery(GET_TRACKING_URL_LIST);
  const [updateTrackingUrlCheckType, { loading: updateTrackingUrlCheckTypeLoading }] =
    useMutation(UPDATE_TRACKING_URL_CHECK_TYPE);

  // Fetch tracking URLs based on the current user's company ID
  const fetchTrackingUrls = useCallback(async (): Promise<void> => {
    try {
      setErrorMessage('');
      const { data, error } = await getTrackingUrls({
        variables: {
          input: {
            merchantId: Number(hookWhoAmI?.companyId),
          },
        },
        fetchPolicy: 'no-cache',
      });

      if (error) {
        setErrorMessage(URLS_LIST.ERROR.GET_DATA_ERROR);
      }

      if (data?.getAllTrackingUrls?.trackingUrlList) {
        const formattedList = data.getAllTrackingUrls.trackingUrlList.map((item: TrackingUrlType) => ({
          id: item.id,
          url: item.url,
          status:
            URL_STATUS.find((statusObj) => statusObj.value === item.status)?.label ||
            _.startCase(item.status.toLowerCase()),
          category: _.startCase(item.category.toLowerCase()),
          lastActive: item.lastActive ? dateFormatter(new Date(item.lastActive), '/') : null,
          checkType: _.startCase(item.checkType.toLowerCase()),
          checked: false,
          edit: faPenToSquare,
          delete: faTrash,
        }));
        setUrlsList(formattedList);
        setTableData(paginator(formattedList, Number(recordsPerPage.value), 1));
        setRecordsCount(data.getAllTrackingUrls.trackingUrlList.length);
        setTotalPages(Math.ceil(data.getAllTrackingUrls.trackingUrlList.length / Number(recordsPerPage.value)));
        setCurrentPage(1);
      } else {
        setCurrentPage(1);
        setTotalPages(1);
      }
    } catch (error) {
      setErrorMessage(URLS_LIST.ERROR.GET_DATA_ERROR);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTrackingUrls, hookWhoAmI?.companyId]);

  // Clear the search input
  const clearSearch = (): void => {
    setErrorMessage('');
    setSearch('');
  };

  // Handle search input changes
  const setSearchHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setErrorMessage('');
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const handleSearch = useCallback((): void => {
    const urlsListToSet =
      debouncedSearch === ''
        ? urlsList
        : [...urlsList].filter((item) => item.url.toLowerCase().includes(debouncedSearch.toLowerCase()));
    setUrlsTemp(urlsListToSet);
    setTableData(paginator(urlsListToSet, Number(recordsPerPage.value), 1));
    setTotalPages(Math.ceil(urlsListToSet.length / Number(recordsPerPage.value)));
    setRecordsCount(urlsListToSet.length);
    setCurrentPage(1);
  }, [debouncedSearch, urlsList]);

  // Handle page changes for pagination
  const handlePageChange = (page: number): void => {
    setErrorMessage('');
    const paginatedData = paginator(debouncedSearch ? urlsTemp : urlsList, Number(recordsPerPage.value), page);
    setTableData(paginatedData);
    setCurrentPage(page);
  };

  const handleRecordsChange = (): void => {
    setCurrentPage(1);
    const pubList = urlsTemp.length > 0 && urlsTemp.length !== urlsList.length ? [...urlsTemp] : [...urlsList];

    setTableData(paginator(pubList, Number(recordsPerPage.value), 1));
    setTotalPages(Math.ceil(pubList.length / Number(recordsPerPage.value)));
  };

  const setCheckTypeEditModalInfo = ({
    id,
    trackingUrl,
    type = URLS_LIST.MODAL.TYPES.EDIT,
    loadedCheckType,
  }: Record<string, string | number>): void => {
    if (id) setTrackingUrlId(Number(id));
    if (trackingUrl) setUrl(trackingUrl.toString());

    if (loadedCheckType) {
      const check = Object.entries(CHECK_TYPES).find(([_label, value]) => value === loadedCheckType);
      if (check) {
        const [value, label] = check;
        setCheckType({ label, value });
      }
    }

    setIsOpen();
  };

  const isCheckTypeValid = (): boolean => {
    const validCheckTypes = CHECK_TYPES_OPTIONS.map((option) => option.value);

    if (!checkType || checkType.value === '' || !validCheckTypes.includes(checkType.value)) {
      setCheckTypeError(URLS_LIST.ERROR.MISSING_CHECK_TYPE);
      return false;
    }
    setCheckTypeError('');
    return true;
  };

  // Handle sorting of table data
  const handleSort = (dataField: string, direction: 'desc' | 'asc' | undefined): void => {
    setErrorMessage('');
    const directionString: string = direction === 'asc' ? 'asc' : 'desc';
    const copyArrayList = [...urlsList];
    const copyArray = debouncedSearch ? [...urlsTemp] : copyArrayList;
    const sortedArray = sortString(copyArray, dataField, directionString);
    const sortedArrayList = sortString(copyArrayList, dataField, directionString);
    setUrlsTemp(sortedArray);
    setUrlsList(sortedArrayList);
    setTableData(paginator(sortedArray, Number(recordsPerPage.value), 1));
    setSortColumn({ column: dataField, direction: sortColumn.direction === 'desc' ? 'asc' : 'desc' });
    setCurrentPage(1);
  };

  // Handle changes in the number of records per page
  const setRecordsHandler = (value: SelectOption): void => {
    setErrorMessage('');
    setRecordsPerPage(value);
  };

  // Add or remove selected rows to the selectedRows state for bulk update
  const handleCheckBox = (checked: boolean, row: TrackingUrlType): void => {
    setSelectedRow({ ...row, checked });
  };

  const handleUpdateUrls = async (): Promise<void> => {
    try {
      setErrorMessage('');
      const urlsToUpdate = tableData.filter((item) => item.checked).map((item) => item.id);
      const { data, errors } = await updateTrackingUrlCheckType({
        variables: {
          input: {
            merchantId: Number(hookWhoAmI?.companyId),
            checkType: selectedCheckType?.value,
            urls: urlsToUpdate,
          },
        },
      });
      setSelectedCheckType(undefined);
      if (errors) {
        setErrorMessage(URLS_LIST.ERROR.UPDATE_CHECK_TYPE);
        return;
      }

      if (data?.updateTrackingUrlCheckType) {
        hookShowToast(URLS_LIST.SUCCESS);
        fetchTrackingUrls();
      }
    } catch (error) {
      setErrorMessage(URLS_LIST.ERROR.UPDATE_CHECK_TYPE);
    }
  };

  const resetModal = (): void => {
    setCheckType({
      label: CHECK_TYPES.ALL_RULES,
      value: CHECK_TYPES.ALL_RULES,
    });
  };

  const handleUpdateTrackingUrl = async (): Promise<void> => {
    const { data, errors } = await updateTrackingUrl({
      variables: {
        input: {
          merchantId: Number(hookWhoAmI?.companyId),
          checkType: checkType.value,
          urls: [trackingUrlId],
        },
      },
    });
    if (errors) {
      hookShowToast(errors[0].message);
      return;
    }

    if (data?.updateTrackingUrlCheckType) {
      await fetchTrackingUrls();
    }
  };

  const handleEditManualUrl = async (): Promise<void> => {
    if (!isCheckTypeValid()) return;

    await handleUpdateTrackingUrl();

    setIsOpen();
    resetModal();
  };

  const handleModalRightButton = async (): Promise<void> => {
    handleEditManualUrl();
  };

  // ===== useEffects =====

  // Fetch tracking URLs on component mount
  useEffect(() => {
    fetchTrackingUrls();
  }, [fetchTrackingUrls]);

  useEffect(() => {
    handleSearch();
  }, [debouncedSearch, handleSearch]);

  useEffect(() => {
    handleRecordsChange();
  }, [recordsPerPage]);

  useEffect(() => {
    const updateTableData = (): void => {
      if (selectedRow) {
        const updatedTableData = tableData.map((item) => {
          if (item.id === selectedRow.id) {
            return { ...item, checked: selectedRow.checked };
          }
          return item;
        });
        setTableData(updatedTableData);
      }
    };
    updateTableData();
  }, [selectedRow]);

  return {
    // Table data and pagination
    tableData,
    currentPage,
    totalPages,
    recordsCount,
    recordsPerPage,
    onPageChange: handlePageChange,

    // Search functionality
    search,
    setSearch,
    setSearchHandler,
    clearSearch,

    // Sorting functionality
    sortColumn,
    handleSort,

    // Records per page
    setRecordsPerPage,
    setRecordsHandler,

    // Data fetching
    urlsList,
    getTrackingUrlsLoading,

    // Error handling
    errorMessage,

    // Bulk Update
    handleCheckBox,
    selectedRows: selectedRow,
    handleUpdateUrls,
    updateTrackingUrlCheckTypeLoading,
    selectedCheckType,
    setSelectedCheckType,

    id: trackingUrlId,
    isOpen,
    setIsOpen,
    setCheckTypeEditModalInfo,
    url,
    checkType,
    setCheckType,
    checkTypeError,
    resetModal,
    handleModalRightButton,
    updateTrackingUrlLoading,
  };
};
